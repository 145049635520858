
#modal-login {
  overflow: auto !important;
}

.modal-dialog {
  display: flex;
  justify-content: center;

  .modal-content {
    display: flex;
    height: 855px;
    width: 495px;
    border-radius: 0px;
    border: none;
    background: #b7c3bd;
    padding: 0 60px;
    margin: 0;

    @media (max-width: 813px) {
      height: fit-content;
      width: 100%;
      padding: 0 18px;
    }

    .modal-body {
      margin-top: 60px;
      @media (max-width: 813px) {
        margin-top: 20px;
      }
      .logo {
        margin-bottom: 1em;
        img {
          height: 70px;
          width: auto;
        }
      }
      h4 {
        font-family: Utile Display Book;
        font-style: normal;
        font-weight: normal;
        font-size: 23px;
        line-height: 26px;
        color: #000000;
        width: 200px;
        padding-bottom: 6.25px;
        border-bottom: 1px solid #707070;
        margin: 0 auto;
      }
    }
  }
  .modal-image {
    background: url("~images/app/login/modal-login-background.webp");
    min-width: 368px;
    height: 855px;

    @media (max-width: 880px) {
      min-width: 300px;
    }
    @media (max-width: 813px) {
      display: none;
    }
  }

  ::v-deep .fb-ico,
  .google-ico {
    width: 24px;
    height: 22px;
    background: url("~images/app/login/fb-icon.svg");
    background-position: center;
    background-size: cover;
    margin-right: 0.5em;
    @media (max-width: 813px) {
      margin-right: 8px;
      height: 20px;
      width: 22px;
    }
  }

  .google-ico {
    background: url("~images/app/login/google-icon.svg");
    background-position: center;
    background-size: cover;
  }

  .google-btn,
  .fb-btn {
    width: 230px;
    height: 34px;
    background: #d3d3d3;
    text-align: center;
    font-family: Utile;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    margin: 0 auto 15.5px;

    @media (max-width: 813px) {
      width: 179px;
      height: 30px;
      font-size: 11px;
    }
  }

  .fb-btn {
    margin-top: 60px;
    @media (max-width: 813px) {
      margin-top: 24px;
    }
  }
  .google-btn {
    margin-bottom: 38px;

    @media (max-width: 813px) {
      margin-bottom: 20px;
    }
  }

  .login-field {
    margin-bottom: 8px;
    label {
      font-family: Utile;
      font-style: normal;
      font-weight: normal;
      font-size: 12.5px;
      line-height: 14px;
    }
    input {
      height: 35px;
    }
  }

  .submenu {
    display: flex;
    justify-content: space-between;
    font-family: Utile;
    font-style: normal;
    font-weight: normal;
    font-size: 12.5px;
    line-height: 14px;
    margin-bottom: 52px;

    @media (max-width: 813px) {
      margin-bottom: 20px;
    }
  }

  .recaptcha-wrapper {
    margin-left: 20px;
    padding-right: 50px;
    @media (max-width: 813px) {
      margin-left: 0;
    }
    @media (max-width: 390px) {
      margin-left: -20px;
    }
  }

  .login-cta-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    @media (max-width: 813px) {
      margin-top: 20px;
    }
    .login-button {
      width: 187px;
      height: 37px;
      background: #343333;
      color: white;
      border: none;
      font-family: Utile;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 17px;
      margin-bottom: 30px;
      &:hover {
        background: #7e7d7d;
        color: black;
      }
      @media (max-width: 813px) {
        margin-bottom: 10px;
      }
    }

    .create-acc-button {
      background: none;
      border: none;
      border-bottom: solid 1px #7e7d7d;
      padding-bottom: 4px;
      font-family: Utile;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.submenu {
  a:hover {
    color: black;
    opacity: 0.7;
  }
}

.login-modal-success {
  h5 {
    font-family: Utile Display Book;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 26px;
    color: #000000;
  }

  p {
    font-family: Utile Display Book;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }
}

.close-cta {
  position: absolute;
  right: 20px;
  top: 20px;
}
