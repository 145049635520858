
.btn-primary {
  background: #000;
  color: #fff;
  border-color: #000;
}

.button-wrapper {
  text-align: right;
}
