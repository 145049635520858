.cart-order-wrapper {
  border: 1px solid $color-pink;
  margin-bottom: 30px;

  .cart-order-header {
    height: 40px;
    line-height: 40px;
    background: $color-pink;
    padding: 0 20px;
    @include font-size(16px);
    text-transform: uppercase;
    font-weight: bold;

    span {
      font-weight: 300;
    }

    .col-number {
      text-align: right;
    }

    @media (max-width: 767px) {
      height: auto;
      text-align: center;
      line-height: 1.6em;
      padding: 10px;

      .col-number {
        text-align: center;
      }
    }
  }

  .cart-order-body, .cart-order-footer {
    padding: 20px;
  }

  .cart-order-body {
    padding-bottom: 0;

    .remove-item {
      a {
        color: rgba(0, 0, 0, .5);

        &:hover {
          color: $color-black;
          text-decoration: underline;
        }
      }
    }
  }

  .cart-order-footer {
    text-align: center;
    font-weight: 300;

    .col-status {
      text-transform: uppercase;
      @include font-size(16px);
      span {
        color: $color-pink;
      }
    }

    .col-dates {
      @include font-size(14px);
      span {
        font-weight: bold;
      }
    }

    .col-price {
      text-transform: uppercase;
      .value {
        @include font-size(16px);
        font-weight: bold;
      }

      .applied-coupon {
        font-size: 12px;
        text-transform: none;
        font-family: $font-roboto;
        a {
          font-family: $font-roboto;
        }
      }
    }
  }
}
