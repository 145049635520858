
.blog-page-container {
    padding-top: 55px;
    p,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6, div {
      color: #000000;
      font-family: Utile Display Book;
    }

    .details {
      h3, a {
        color: #000000;
        font-family: Utile Display Book !important;
      }

    }
    .blog-title {
      width: fit-content;
      margin: 0 auto;
      padding-bottom: 10px;
      border-bottom: 1px solid #707070;
    }
}

.blog-btn {
  background: #343333;
  padding: 7px 20px;
  font-family: Utile Regular !important;
  font-size: 15px;
  color: white !important;
  &:hover {
    background: #7e7d7d 
  }
}

.blog-subscribe-btn {
  background: #343333;
  color: white;
  width: 100%;
  height: 50px;
  border: none;
  margin-top: 5px;
  font-family: Utile Regular !important;
  font-size: 15px;
  &:hover {
    background: #7e7d7d 
  }
}

.sidebar-title {
  color: #000000;
  font-family: Utile Display Book;
}