@import 'bootstrap_extensions/badges';

.btn {
  border-radius: 0;
  font-family: $font-roboto-slab;
  font-weight: 300;
  @include font-size(13px);
  line-height: 2em;
  @include transition(all $ani-speed ease);
  text-transform: uppercase;

  &.btn-primary {
    color: $color-white;
  }

  &.btn-lg {
    @include font-size(20px);
  }

  &.btn-outline-primary:hover,
  &.btn-primary:hover {
    color: #c04f79; //$color-white;
  }
  &.btn-outline-primary:hover {
    // background-color: transparent !important;
  }

  &.btn-white {
    background: $color-white;
    border: 2px solid $color-black;
    color: $color-black;

    &:hover {
      // background: #000;
      // color: $color-white;
      color: #c04f79;
    }
  }

  &.btn-pink {
    background: $color-pink;
    border-color: $color-pink;
    color: $color-white;

    &:hover {
      background: #dd9883;
      border-color: #dd9883;
    }
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: $color-white;
}

.form-group label.btn-outline-primary {
  color: $color-yellow;
}

.btn-facebook {
  background: #3b5998;
  border-radius: 0;
  color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #263961;

  &:link,
  &:visited {
    color: #fff;
  }

  &:active,
  &:hover {
    background: #263961;
    color: #fff;
  }
}

@mixin input-from-control {
  background: #fff;
  border: 1px solid $color-grey-light;
  border-radius: 0;
  @include transition(all $ani-speed ease);
  outline: none;
  font-family: $font-roboto;
  font-weight: 300;
  @include font-size(13px);

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  @include placeholder {
    color: $color-grey;
  }

  &:focus,
  &:hover {
    border-color: $color-yellow;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  &.was-validated &.form-control:invalid,
  &.form-control.is-invalid,
  &.was-validated &.custom-select:invalid,
  &.custom-select.is-invalid {
    border-color: #dc3545;
  }
}

$input_types: "text", "email", "password";

input.form-control {
  @include input-from-control;
  height: 38px;
  // @each $type in $input_types {
  //   &[type=#{$type}], textarea.form-control {
  //     @include input-from-control;
  //     height: 38px;
  //   }
  // }
}

select.form-control {
  @include input-from-control;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 16px) calc(1em + 2px),
    calc(100% - 11px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

textarea.form-control {
  @include input-from-control;
}

.card {
  border-radius: 0;

  &.card-style-1 {
    .card-title {
      background: #f9f9fa;
      padding: 10px 20px;
    }
  }
}

.list-group .list-group-item {
  border-radius: 0;
}

.form-group {
  label {
    font-family: $font-roboto-slab;
    color: #595959;
  }

  &.radio_buttons label {
    margin-left: 5px;
  }

  &.disabled {
    input.form-control {
      background: rgba(238, 68, 85, 0.1);
      cursor: not-allowed;

      &:hover {
        border-color: #ee4455;
      }
    }
  }
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 0;
}

hr {
  border-top: 1px solid $color-grey-light;
}

.nav-tabs .nav-link {
  border-radius: 0;
  padding: 0.5rem 2rem;
}

.tab-content {
  border: 1px solid #dee2e6;
  border-top: 0;
  padding: 2rem;
}

button:focus {
  outline: 0;
}