
.error {
	color: red;
	input {
		border: solid 1px red;
	}
}

span.phone-prefix{
  position: absolute;
  color: black;
  font-family: 'Utile Regular';
  font-weight: 300;
  font-size: 15px;
  padding-left: 8px;
  pointer-events: none;
  line-height: 35px;
  height: 35px;
}

input#phone {
  padding-left: 25px;
}
