.post-thumb {
  margin-bottom: 30px;

  .image {
    margin-bottom: 10px;
    a {
      display: block;
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .details {
    position: relative;
    padding-left: 100px;

    .sidebar {
      position: absolute;
      top: 0;
      left: 0;
      width: 75px;

      .date {
        width: 67px;
        height: 67px;
        background: $color-pink;
        color: $color-white;
        text-transform: uppercase;
        font-family: $font-roboto-slab;
        text-align: center;

        .day {
          @include font-size(26px);
          line-height: 1;
        }
        .month {
          @include font-size(14px);
          font-weight: 300;
          line-height: 1;
        }
      }
    }

    h3 {
      margin-bottom: 0px;
      a {
        font-family: $font-abril;
      }
    }

    .sub {
      color: #8c8c8c;
      margin-bottom: 15px;
      @include font-size(12px);

      a {
        font-family: $font-roboto;
        color: $color-black
      }

      .rating {
        color: $color-yellow;
      }
    }

    p {
      color: $color-grey-dark;
    }

    .btn-read-more {
      text-transform: uppercase;
      font-family: $font-roboto-slab;
      font-weight: 400;
      padding: 6px 24px;
    }
  }
}

.post-mini {
  position: relative;
  padding-left: 110px;
  min-height: 100px;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;

    a {
      display: block;
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .details {
    .links a {
      font-family: $font-roboto;
      @include font-size(12px);
      color: $color-grey-dark;
      // text-decoration: underline;

      &:hover {
        color: $color-yellow;
        text-decoration: none;
      }
    }

    a.btn-read-more {
      padding: 0px 20px;
      text-transform: none;
    }
  }
}
