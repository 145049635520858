
a.track-link{
  text-decoration: underline;
}

.aligned-item{
  display: flex;
  justify-content: right;
  column-gap: 15px;
}

.send-order[disabled] {
  cursor: not-allowed;
}

.form-item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.price-warning {
  color: red;
  font-weight: bold;
}

.fedex-section {
  padding: 15px 0;
  margin: 15px 0;
  border-width: 3px !important;;
  border-color: rebeccapurple !important;
//  important cause i dont want to mess with the whole bootstrap theme for one border, and because they are using it themselves so need to overwrite their importants...
}
