.divContainer {
  margin-bottom: 20px;
  .col-sm-4:nth-child(2) {
    img {
      transform: translateY(20px);
    }
    h5 {
      padding: 20px 0px 8px 0px;
      }
  }
  @media (max-width: 1050px){
    margin-bottom: 0px !important;
  }
}

.about-person {
  margin-top: 100px;
  @media (max-width: 1050px) {
    margin-top: 0px;
  }
  .mb-5 {
    @media (max-width: 1050px) {
      margin-bottom:  0px;
    }
  }

  div {
    display: flex;
    justify-content: center;
    flex: 0 1 40%;
    max-width: 387px;
    max-height: 265px;
    margin: 20px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      border: solid black 1px;
      width: calc(100% + 40px);
      background-color: white;
      height: 100%;
      z-index: -1;
    }
  }
  img {
    transform: translateY(-20px);
    min-width: 100px;
    width: auto;
    height: auto;
    max-width: 356px;
    max-height: 265px;
  }
  .img-converted {
    img {
      transform: translateY(20px);
      min-width: 100px;
      width: auto;
      height: auto;
      max-width: 356px;
      max-height: 265px;
    }
  }
 

  h5 {
    font: normal normal normal 23px/26px "Utile Display Book";
    text-align: left;
    border-bottom: 0.5px solid #080808;
    padding: 20px 0px 8px 0px;
    @media (max-width: 1050px) {
      padding: 5px 0px 8px 0px;
     
    }
  }

  h6 {
    font: normal normal normal 23px/26px "Utile Display Book";
    text-align: left;
    padding-top: 10px;
  }
  p {
    font: normal normal normal 15px/23px "Utile Book";
    text-align: left;
  }
}

.about-paragraph {
  padding-left: 5%;
  padding-right: 5%;
  @media (max-width: 1050px) {
  padding-left: 0%;
  padding-right: 0%;
  }

  h6 {
    font: normal normal normal 23px/26px "Utile Display Book";
    text-decoration: underline;
    text-underline-offset: 10px;
    padding-bottom: 30px;
    @media (max-width: 1050px) {
      text-decoration: none;
      border-bottom: 0.5px solid #080808;
      padding: 20px 0px 8px 0px;
      margin-bottom: 30px;
    }

  }
  p {
    text-align: center;
    font: normal normal normal 17px/19px "Utile Book";
    @media (max-width: 1050px) {
      font: normal normal normal 16px/18px "Utile Book";
    }
  }
}

.header-aboutus-cover {
  min-height: 532px;
  background-image: url("~images/app/about/slider.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  margin-bottom: 74px;
  width: 100%;
  @media (max-width: 1050px) {
    min-height: 350px;
    background-image: url("~images/app/about/slider_mobile.webp");
    margin-bottom: 30px;
  }

  .details-container {
    position: absolute;
    top: 43%;
    @media (max-width: 1050px) {
      top: 60%;
    }
  }

  .details {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 450px;
    height: 139px;
    background: #fcf0f2 0% 0% no-repeat padding-box;
    opacity: 0.86;
    color: black;
    @media (max-width: 1050px) {
      width: 250px;
      height: 80px;
    }

    h5 {
      margin-bottom: 0;
      font: normal normal normal 34px/39px "Utile Display Book";
    }
  }
}
