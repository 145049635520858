.wrapper-testimonials {

  p,
  h1,
  h2,
  h3,
  h4,
  a {
    font-family: Utile Book;
  }

  .wrapper-testimonial-cover {
    min-height: 532px;
    background-image: url('~images/app/testimonials/header.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    position: relative;
    margin-top: 10px;

    @media (max-width: 650px) {
      background-image: url('~images/app/testimonials/header-mobile.webp');
      margin-bottom: 145px;
      min-height: 470px;
    }

    .opaque-medium {
      display: none;

      @media (max-width: 650px) {
        display: block;
        height: 56px;
        width: 100%;
        background: rgba(255, 255, 255, 0.7);
        position: absolute;
        bottom: 0;
      }
    }

    .opaque-gray {
      display: none;

      @media (max-width: 650px) {
        display: block;
        height: 56px;
        width: 100%;
        background: rgba(239, 236, 236, 0.25);
        background: #f6f5f2;
        position: absolute;
        bottom: -56px;
      }
    }

    .details-container {
      position: absolute;
      top: 42%;

      @media (max-width: 650px) {
        top: 91%;
        z-index: 1;
        background-color: #F2E6C8;
        opacity: 1;
        width: 95%;
        right: 0;
      }

      a {
        font-family: 'Utile Regular';
        display: flex;
        align-items: center;
        justify-content: center;
        height: 39px;
        width: 43%;
        margin: 27px auto 0;
        line-height: 18px;
        font-size: 15px;
        background-color: white;

        @media (max-width: 650px) {
          margin: 0px auto 30px;
          height: 32px;
          width: 200px;
        }
      }
    }

    .details {
      padding-top: 17px;
      padding-bottom: 17px;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 509px;
      height: 139px;
      background: #FCF5E4 0% 0% no-repeat padding-box;
      opacity: 0.86;
      color: black;

      @media (max-width: 650px) {
        padding-top: 30px;
        opacity: 1;
        background: none;
        width: auto;
        height: auto;
      }

      h1 {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 14px;
        font-family: Utile Display Book;

        @media (max-width: 650px) {
          font-size: 27px;
          line-height: 30px;
          margin-bottom: 4px;
        }
      }

      p {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 0;
        font-family: Utile Display Book;

        @media (max-width: 650px) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &--mobile {
        display: none;

        @media (max-width: 650px) {
          display: flex;
        }
      }

      &--desktop {
        display: flex;

        @media (max-width: 650px) {
          display: none;
        }
      }
    }
  }

  .description {
    text-align: center;
    margin: 0px auto;
    padding-top: 60px;

    @media (max-width: 650px) {
      padding-top: 56px;
      width: 90%;
      max-width: 320px;
    }

    &--second {
      @media (max-width: 650px) {
        padding-top: 96px;
      }
    }
  }

  .description-header {
    font-size: 23px;
    line-height: 26px;
    font-family: 'Utile Display Book';

    @media (max-width: 650px) {
      font-size: 21px;
      line-height: 24px;
    }

    br {
      display: none;

      @media (max-width: 650px) {
        display: inline;
      }
    }
  }

  .description-header-underscore {
    height: 1px;
    margin: 6px auto;
    background-color: black;
    width: 394px;

    @media (max-width: 650px) {
      margin-top: 0px;
      width: 308px;
    }

    &--second {
      width: 432px;

      @media (max-width: 650px) {
        width: 286px;
      }
    }
  }

  .description-text {
    font-size: 17px;
    line-height: 19px;
    margin-top: 12px;
    margin-bottom: 0px;

    @media (max-width: 650px) {
      font-size: 15px;
      line-height: 18px;
      margin-top: 8px;
    }
  }

  .testimonial-carousel {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 98px auto 0px;
    position: relative;
    overflow-y: visible;

    @media (max-width: 1050px) {
      margin-top: 35px;
    }

    .slick-next,
    .slick-prev {
      width: 25px;
      height: 25px;
      position: absolute;
      bottom: 0px;
      font-size: 0px;
      border: none;
      background-size: cover;
      background-image: url('~images/app/testimonials/arrow.webp');
      z-index: 10;
    }

    .slick-prev {
      left: 14vw;
      transform: rotate(-180deg);

      @media (max-width: 900px) {
        left: 0px;
      }
    }

    .slick-next {
      right: 14vw;

      @media (max-width: 900px) {
        right: 0px;
      }
    }

    .slick-dots {
      justify-content: flex-start;
      margin-left: 18.2vw;

      @media (min-width: 1500px) {
        max-width: 623px;
        justify-content: center;
        margin-left: 0;
      }

      @media (max-width: 1300px) {
        margin-left: 17.7vw;
      }
    }

    .carousel-item {
      display: flex !important;
      padding-top: 40px;
      height: 100%;

      a {
        font-family: 'Utile Regular';
        display: none;
        text-decoration: none;
        border-bottom: 1px solid black;
        font-size: 13px;
        line-height: 19px;
        padding-top: 16px;
        transform: translateY(-3px);

        @media (max-width: 1050px) {
          display: block;
        }
      }

      @media (max-width: 1050px) {
        flex-direction: column;
        align-items: center;
      }

      .photo-div {
        flex: 0 1 45%;
        position: relative;
        max-width: 623px;

        @media (max-width: 1050px) {
          margin: 0px 3px 15px;
          max-width: 570px;
        }

        @media (max-width: 500px) {
          margin: 0px 3px 5px;
        }

        @media (max-height: 500px) {
          max-width: 400px;
        }

        @media (max-height: 400px) {
          max-width: 370px;
        }

        .photo {
          width: 100%;
          height: auto;
          padding: 0px 30px;
          position: relative;

          @media (max-width: 500px) {
            padding: 0px 15px;
          }

          .photo-frame {
            position: absolute;
            left: 3px;
            top: 0;
            width: calc(100% - 3px);
            height: 100%;
            z-index: -1;
            border: 1px solid #000000;
          }

          img {
            position: relative;
            left: 50%;
            transform: translate(-49.5%, -36px);

            @media (max-width: 1050px) {
              transform: translate(-49.5%, -16px);
            }
          }
        }
      }

      .content-div {
        flex: 1 1;

        @media (max-width: 1050px) {
          max-width: 570px;
        }

        .review-container {
          min-height: 70%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media (max-width: 1050px) {
            height: 200px;
          }

          @media (max-width: 600px) {
            height: 240px;
          }

          @media (max-width: 450px) {
            height: 260px;
          }

          @media (max-width: 400px) {
            height: 300px;
          }

          @media (max-width: 350px) {
            height: 340px;
          }

          .review-text {
            padding: 20px 125px 0px;
            text-align: left;
            position: relative;

            @media (max-width: 1200px) {
              padding: 20px 75px 0px;
            }

            @media (max-width: 1050px) {
              padding: 20px 50px 0px;
            }

            p {
              font-size: 16px;
              line-height: 27px;
            }

            @media (max-width: 1200px) {
              p {
                font-size: 15px;
              }

              .other-reviews {
                line-height: 23px;
              }

              .first-review {
                line-height: 20px;
              }
            }

            p:after,
            p:before {
              position: absolute;
              font-family: 'Didot Regular';
              font-size: 160px;
              content: '”';
              color: black;
              height: 70px;
            }

            p:before {
              top: -70px;
              left: 45px;
              transform: rotate(-180deg);

              @media (max-width: 1200px) {
                left: 0px;
                top: -80px;
              }

              @media (max-width: 1050px) {
                top: -60px;
                font-size: 100px;
              }

              @media (max-width: 500px) {
                top: -63px;
              }
            }

            p:after {
              bottom: -76px;
              right: 45px;

              @media (max-width: 1200px) {
                right: 0px;
                bottom: -90px;
              }

              @media (max-width: 1050px) {
                font-size: 100px;
                bottom: -66px;
              }

              @media (max-width: 500px) {
                bottom: -67px;
              }
            }
          }

          .review-rating {
            padding: 20px 0px 0px 125px;
            position: relative;
            border-bottom: 1px solid black;

            p {
              font-family: 'Utile Medium';
              line-height: 16px;
            }

            @media (max-width: 1200px) {
              padding: 20px 75px 0px;
            }

            @media (max-width: 1050px) {
              padding: 20px 50px 0px;
            }

            @media (max-width: 600px) {
              padding-top: 7px;
            }

            a {
              font-family: 'Utile Regular';
              display: block;
              position: absolute;
              bottom: -32px;
              border-bottom: 1px solid black;
              text-decoration: none;
              right: 0;
              font-size: 13px;
              line-height: 18px;

              @media (max-width: 1050px) {
                display: none;
              }
            }

            .stars {
              display: flex;
              height: 16px;
              width: 100%;
              margin-bottom: 5px;
              transform: translateX(-1px);

              svg {
                width: 16px;
                height: auto;
              }

              @media (max-width: 1050px) {
                height: 20px;

                svg {
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .trending-wrapper {
    height: auto;
    width: 100%;
    display: flex;
    background-color: #f5f4f0;
    position: relative;
    margin-top: 140px;
    margin-bottom: 70px;

    @media (max-width: 1050px) {
      flex-direction: column;
      padding-bottom: 330px;
      margin-top: 48px;
      margin-bottom: 120px;
    }

    .content-container {
      text-align: center;
      flex-basis: 33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 40px;

      @media (max-width: 1050px) {
        padding-top: 32px;
      }

      h1 {
        font-family: 'Utile Display Book';
        margin: 0 auto 20px;
        font-size: 31px;
        line-height: 43px;
        border-bottom: 1px solid #080808;
        padding: 0px 18px 10px;
        color: black;

        @media (max-width: 1050px) {
          padding-bottom: 0px;
          margin-bottom: 10px;
        }

        @media (max-width: 600px) {
          font-size: 22px;
          line-height: 24px;
        }
      }

      p {
        font-family: 'Utile Display Book';
        font-size: 22px;
        line-height: 27px;
        color: black;
        margin: 0px auto 47px;
        max-width: 310px;

        @media (max-width: 1050px) {
          font-size: 17px;
          line-height: 19px;
          max-width: 290px;
          margin-bottom: 25px;
        }
      }

      a {
        font-family: 'Utile Regular';
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        width: 222px;
        margin: 0 auto;
        line-height: 18px;
        font-size: 15px;
        color: white;
        background-color: #343333;

        @media (max-width: 1050px) {
          height: 29px;
        }

        &:hover {
          color: #ffc000;
          background-color: #242424;
          box-shadow: 0 0 2px black;
        }
      }
    }

    .photos-container {
      display: flex;
      flex-basis: 67%;
      justify-content: flex-end;

      @media (max-width: 1050px) {
        display: none;
      }
    }

    .trending-photo {
      flex-basis: 33%;
      max-height: 455px;
      width: 100%;
      margin-right: 30px;
      transform: translateY(-40px);
    }

    .photos-container-mobile {
      display: none;

      @media (max-width: 1050px) {
        display: block;
        position: absolute;
        top: 220px;
        left: 0;
        width: 100%;
      }

      .trending-carousel {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .slick-dots {
          transform: translateY(-45px);

          button {
            width: 8px;
            height: 8px;
          }
        }

        img {
          @media (max-width: 1050px) {
            margin: 0 auto;
            max-height: 400px;
          }
        }
      }
    }
  }

  .review-comments {
    text-align: center;
    width: 68%;
    margin: 0 auto;
    color: black;

    @media (max-width: 1050px) {
      width: 90%;
    }

    @media (max-width: 600px) {
      width: 96%;
    }

    h1 {
      font-family: 'Utile Display Book';
      font-size: 23px;
      line-height: 26px;
      color: black;
      margin-bottom: 4px;
    }

    a {
      font-family: 'Utile Regular';
      display: flex;
      align-items: center;
      justify-content: center;
      height: 37px;
      width: 227px;
      margin: 0 auto;
      line-height: 18px;
      font-size: 15px;
      color: white;
      background-color: #343333;

      &:hover {
        color: #ffc000;
        background-color: #242424;
        box-shadow: 0 0 2px black;
      }
    }

    .review-carousel {
      border-top: 1px solid #7070708f;

      .slick-dots {
        margin-bottom: 33px;
      }

      .review-item {
        margin-top: 40px;

        p {
          font-family: 'Utile Display Book';
          font-size: 22px;
          line-height: 27px;
          padding: 0 80px;
          margin-bottom: 45px;
          position: relative;

          @media (max-width: 1050px) {
            padding: 0 49px;
            font-family: 'Utile Display Light';
            font-size: 21px;
          }

          @media (max-width: 400px) {
            font-size: 20px;
          }
        }

        .long-text {
          @media (max-width: 1050px) {
            line-height: 24px;
            font-size: 19px;
          }
        }

        p:after,
        p:before {
          position: absolute;
          font-family: 'Didot Regular';
          font-size: 160px;
          content: '”';
          color: black;
          height: 70px;

          @media (max-width: 1050px) {
            font-size: 100px;
          }
        }

        p:before {
          top: -95px;
          left: 0px;
          transform: rotate(-180deg);

          @media (max-width: 1050px) {
            top: -78px;
          }
        }

        p:after {
          bottom: -95px;
          right: 0px;

          @media (max-width: 1050px) {
            bottom: -80px;
          }
        }

        .stars {
          display: flex;
          justify-content: center;
          height: 16px;
          width: 100%;
          margin-bottom: 7px;
          transform: translateX(-1px);

          svg {
            width: 16px;
            height: auto;
          }

          @media (max-width: 1050px) {
            height: 20px;

            svg {
              width: 20px;
            }
          }
        }

        h5 {
          font-family: 'Utile Medium';
          font-size: 14px;
          margin-bottom: 17px;
        }
      }
    }
  }

  .summary-text {
    width: 80%;
    max-width: 955px;
    margin: 60px auto 80px;
    text-align: center;
    font-size: 17px;
    line-height: 19px;

    @media (max-width: 1050px) {
      font-size: 15px;
      line-height: 18px;
      margin: 52px auto;
    }

    a {
      text-decoration: underline;
    }
  }
}
