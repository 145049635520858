#cover {
  height: 650px;
  background-image: url("~images/app/cover.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1800px) {
    height: 550px;
  }
  @media (max-width: 1390px) {
    height: 425px;
  }
  @media (max-width: 1024px) {
    height: 325px;
  }
  @media (max-width: 835px) {
    height: 265px;
  }
  @media (max-width: 835px) {
    height: 265px;
  }
  @media (max-width: 670px) {
    height: 195px;
  }
  @media (max-width: 490px) {
    height: 160px;
  }
  @media (max-width: 490px) {
    height: 160px;
  }
  @media (max-width: 400px) {
    height: 130px;
  }

  &.small {
    height: 340px;
  }
}

#hero-baner {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;

  .hero {
    height: 567px;
    width: 100%;
    display: block;
    background-image: url("../../../images/app/homepage/header_woman_mobile.webp");
    background-position: center;
    background-size: cover;
  }

  .stripes {
    width: 100%;
    height: 100px;
    margin-top: -50px;
    background: #CC9987C7;
  }

  .description {
    text-align: center;
    background: #E9D9F0;
    width: 95%;
    padding: 2em;
    margin-top: -6.5em;
    margin-left: 5%;

    .title {
      margin-bottom: 5px;
      text-align: center;
      font: 34px/39px Utile Display Book;
      font-size: 34px;
      line-height: 39px;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }

    .pdesc {
      font: 24px/29px Utile Display Book;
    }

    a {
      padding: 2px 25px 7px;
      background: #ffffff;
      text-align: center;
      font: 15px/18px Utile;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      border: none;

      &:hover {
        background: #7e7d7d;
        color: white;
      }
    }
  }

  .discover {
    text-align: center;
    margin: 88px auto 34px;

    @media (max-width: 835px) {
      margin: 4em auto 4em;
    }

    .selection {
      max-width: 850px;
      margin: auto;
      font: 15.5px/18px Utile Display Book;

      @media (max-width: 835px) {
        padding: 0 5vw;
      }
    }

    .discoverTitle {
      width: 50%;
      margin: 0 auto 1em;

      @media (max-width: 835px) {
        width: auto;
        .discoverTitle-second-block {
          display: block;
        }
      }

      p.selection {
        font-weight: 300;
      }
    }

    h1 {
      font: normal normal normal 23px/26px Utile Display Book;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin: 0 auto;
      position: relative;
      width: fit-content;
      padding-bottom: 5px;
    }

    h1:after {
      font: normal normal normal 23px/26px Utile Display Book;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin: 0 auto;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #000;
      content: "";
    }

    p.refer {
      margin-top: 1em;
      font: normal normal normal 17px/19px Utile;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
  }

  @media (max-width: 835px) {
    .description {
      .title {
        font-size: 27px;
        line-height: 30px;
      }

      .pdesc {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  @media (min-width: 835px) {
    .hero {
      height: 532px;
      background-size: cover;
      background-image: url("../../../images/app/homepage/header_woman.webp");
    }
    .stripes {
      display: none;
    }
    .description {
      background: none;
      position: absolute;
      top: 139px;
      left: 0px;
      width: 510px;
      margin: 0;

      .desc {
        left: 0px;
        width: 509px;
        height: 139px;
        background: #E9D9F0;
        margin-left: -2em;
        margin-bottom: 2em;
        opacity: 1;
      }

      .title {
        padding-top: 0.5em;
      }

      .pdesc {
        width: 378px;
        margin: 0 auto;
      }

      a {
        padding: 7px 30px 10px;
        margin-top: 2em;
      }
    }
    section.referFriend {
      margin-top: 10em;
      height: 464px;
      .ReferFriendImage {
        width: 990px;
        height: 528px;
        background-image: url("../../../images/app/homepage/diamonds-desktop.webp");
        margin-top: -6.5em;
      }
      .referFriendCta {
        width: 30%;
        position: absolute;
        right: 0px;
        h1 {
          padding-top: 150px;
          font: normal normal normal 31px/43px Utile Display Book;
        }
        p {
          font: normal normal normal 22px/26px Utile Display Book;
          width: 321px;
          margin: 0 auto 2em;
        }
      }
    }
  }
}

#how-it-works-wrapper {
  padding: 60px 0;
  background: #f9f9f9;
  margin: 60px 0;
  .h1 {
    font-size: 46px;
    margin: 0;
    font-family: $font-abril;
    text-transform: lowercase;
  }

  .h2 {
    font-family: $font-freightneo-bold;
    font-size: 26px;
    line-height: 44px;
    color: $color-black;
  }

  .btn {
    padding: 0.2rem 1.9rem;
    font-weight: 500;
    border-width: 2px;
    font-size: 16px;
  }
}

.sections-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  margin: auto;

  &__column {
    width: 50%;
  }

  &__row {
    margin: 20px auto;
    text-align: center;
    min-width: 300px;

    &--colorful {
      width: 70%;
      padding: 5px;
      color: white;
      font: normal normal normal 26px/35px Utile Display Book;
    }

    &--first {
      width: 63%;
      font: normal normal normal 22px/25px Utile Display Book;
    }

    &--second, &--link {
      font-size: 17px;
      line-height: 19px;
      font-family: Utile Display Book;
    }

    &--second {
      width: 50%;
    }

    &--link {
      margin-bottom: 0;
      text-decoration: underline;
    }
  }

  &__rbtd {
    margin: auto;

    .sections-wrapper__row--colorful {
      background: #9F1353;
    }
  }
}

@media (max-width: 800px) {
  .sections-wrapper {
    flex-direction: column;

    &__row {
      &--colorful {
        width: 80%;
      }
    }

    &__column {
      width: 100%;
    }

    &__rbtd {
      .sections-wrapper__image {
        padding-left: 10%;
      }
    }
  }
}

@media (max-width: 600px) {
  .sections-wrapper__row {
    &--colorful {
      font: normal normal normal 21px/24px Utile Display Book;
    }

    &--first {
      font: normal normal normal 16px/18px Utile Display Book;
    }

    &--second, &--link {
      font: normal normal normal 15px/18px Utile Display Book;
    }
  }
}

.diamonds-wrapper {
  background: #F4E6CD;
  margin: 8vw 0 85px;
  position: relative;
  display: flex;
  width: 100%;
  height: 24vw;
  min-height: 464px;

  .cta-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 100%;
    padding: 0 50px;

    .title {
      font: normal normal normal 31px/43px Utile Display Book;
      letter-spacing: 0px;
      color: #000000;
      padding-bottom: 3px;
      border-bottom: solid 1px black;
      width: fit-content;
      margin: 0 auto;
    }

    .desc {
      font: normal normal normal 22px/27px Utile Display Book;
      letter-spacing: 0px;
      color: #000000;
      max-width: 293px;
      margin: 1em auto 1em;
    }

    .diamonds-button {
      display: flex;
      height: 42px;
      align-items: center;
      background: #343333;
      color: white;
      font: normal normal normal 15px/18px Utile;
      text-align: center;
      border: none;
    }

    .diamonds-button:hover {
      background: #7e7d7d;
      color: white;
    }
  }

  @media (min-width: 1515px) {
    .cta-wrapper {
      width: calc(100% - 985px);
    }
  }

  .img-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 65%;
    max-width: 985px;
    min-height: 586px;
    background-image: url("../../../images/app/homepage/diamonds-desktop.webp");
    background-repeat: no-repeat;
    background-size: auto 586px;
    background-position: left bottom;
  }

  .img-wrapper-mobile {
    display: none;
  }
}

@media (max-width: 750px) {
  .diamonds-wrapper {
    height: 391px;
    flex-direction: column-reverse;
    margin-top: 100px;
    min-height: initial;

    .img-wrapper-mobile {
      display: block;
      position: relative;
      left: calc(100vw - 360px);
      bottom: 50px;
    }

    .img-wrapper {
      background: none;
      position: relative;
      width: 360px;
      height: 314px;
      min-height: auto;
    }

    .cta-wrapper {
      position: relative;
      bottom: 35px;
      width: 100%;

      .title {
        font: normal normal normal 21.5px/24px Utile Display Book;
      }

      .desc {
        font: normal normal normal 16.5px/19px Utile Display Book;
        letter-spacing: 0px;
      }

      .diamonds-button {
        height: 29px;
        padding-top: 3px;
      }
    }
  }
}

.head-title {
  width: fit-content;
  border-bottom: solid 1px black;
  padding: 10px;
  margin: 35px auto 0;
  font: normal normal normal 23px/26px Utile Display Book;
}

.world-wrapper {
  .world-carousel {
    width: 100%;
    padding: 0 3em;

    .wrapper-item {
      margin: 0 1em;

      .title {
        height: 42px;
        background: #047B63;
        text-align: center;
        font: normal normal normal 15px/18px Utile;
        letter-spacing: 0px;
        color: #ffffff;
        padding-top: 0.5em;
        margin-bottom: 0.3em;
      }

      .description {
        text-align: center;
        font: normal normal normal 16px/18px Utile;
        letter-spacing: 0px;
        color: #000000;
        height: 55px;
        background: #ffffff 0% 0% no-repeat padding-box;
        opacity: 0.86;
        padding-top: 1em;
      }
    }

    @media (max-width: 1390px) {
      padding: 0em 0;
    }
  }

  &__read-more-button {
    text-decoration: underline;
    cursor: pointer;
  }

  &__read-more-content {
    display: none;
  }

  @media (min-width: 900px) {
    &__description {
      width: 90%;

      &__read-more-button {
        display: none;
      }

      &__read-more-content {
        display: inline;
      }
    }
  }

  &__description {
    width: 67%;
    max-width: 950px;
    margin: 20px auto 34px;
    text-align: center;
    font: normal normal normal 17px/19px Utile Book;

    &--expanded {
      .world-wrapper__read-more-button {
        display: none;
      }

      .world-wrapper__read-more-content {
        display: inline;
      }
    }
  }
}

.image-featured {
  height: 248px;
  margin-top: 0.5em;
}

.photo-featured {
  background-image: url("../../../images/app/homepage/wot1.webp");
  height: 248px;
  background-position: center;
  background-size: cover;
  padding-top: 194px;
}

.photo-hot {
  background-image: url("../../../images//app//homepage/wot2.webp");
  height: 248px;
  background-position: center;
  background-size: cover;
  padding-top: 194px;
}

.photo-knowladge {
  background-image: url("../../../images//app//homepage/wot3.webp");
  height: 248px;
  background-position: center;
  background-size: cover;
  padding-top: 194px;
}

.subscription-wrapper,
.vip-wrapper {
  .product-img {
    width: 260px;
    height: auto;
    margin: 0 auto;
  }

  @media (max-width: 450px) {
    .product-description {
      font-size: 12px;
    }
  }
}

@media (max-width: 835px) {
  .product-img {
    width: 154px;
    height: auto;
  }
}

.subscription-carousel-container,
.vip-carousel-container {
  margin: 0 2em;
  text-align: center;

  .title {
    width: fit-content;
    text-align: center;
    font: normal normal normal 14px/18px Utile;
    letter-spacing: 0px;
    color: #ffffff;
    width: 181px;
    padding: 0.2em 0 0.2em;
  }

  .subscription-carousel,
  .vip-carousel {
    display: flex;
    &:hover {
      .slick-prev, .slick-next {
        opacity: 1;
      }
    }

    .slick-prev, .slick-next {
      background: url("../../../images/app/left-arrow.webp");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-size: 100%;
      font-size: 0;
      border: none;
      padding-left: 11px;
      opacity: 0;
      transition: all 0.5s;
    }

    .slick-next {
      transform: rotate(0.5turn);
    }

    @media (max-width: 700px) {
      .slick-prev {
        margin-left: -10px;
      }

      .slick-next {
        margin-right: -10px;
      }
    }
  }
}

.subscription-carousel-container {
  margin-bottom: 10em;
  padding-bottom: 80px;
  border-bottom: 1px solid gray;

  @media (max-width: 835px) {
    margin-bottom: 140px;
  }

  .title {
    background: #c36300;
  }

  .underline {
    border-top: solid 1px #c36300;
  }
}

.vip-carousel-container {
  margin-bottom: 4em;

  .title {
    background: #9f1353;
  }

  .underline {
    border-top: solid 1px #9f1353;
  }
}

.brand,
.product-title {
  font: normal normal medium 13px/15px Utile;
  letter-spacing: 0px;
  color: #000000;
  margin: 0;
}

.rent,
.market {
  font: normal normal normal 13px/15px Utile;
  letter-spacing: 0px;
  color: #000000;
  margin: 0;
}

.product-image {
  position: relative;
  margin-bottom: 27px;

  .favorite-action {
    position: absolute;
    top: auto;
    right: 100px;
    bottom: 10px;
    line-height: 16px;
  }

  img {
    width: 260px;
    height: auto;
    margin: 0 auto;
  }

  @media (max-width: 670px) {
    .favorite-action {
      right: 50px;
    }
  }

  @media (max-width: 480px) {
    .favorite-action {
      right: 20px;
    }
  }
}

#how-it-works-wrapper {
  .image {
    display: block;

    img {
      width: 100%;
    }
  }

  .section-title {
    color: $color-red;
    font-weight: 300;
    min-height: 38px;
  }

  h2 {
    font-family: $font-abril;
    @include font-size(18px);
  }
}

.box {
  &.box-spring-look {
    height: 100%;
    text-align: center;
    h3 {
      @include font-size(56px);
    }

    @media (max-width: 991px) {
      h3 {
        @include font-size(30px);
      }
    }

    @media (max-width: 767px) {
      padding: 45px 0;
    }
  }

  &.box-wedding {
    height: 100%;
    text-align: center;

    h3 {
      @include font-size(56px);
    }

    @media (max-width: 991px) {
      h3 {
        @include font-size(30px);
      }
    }

    @media (max-width: 767px) {
      padding: 45px 0;
    }
  }
}

#instagram-wrapper {
  font-family: $font-roboto-slab;
  @include font-size(24px);
  text-align: center;

  .instagram-feed {
    overflow: hidden;
    .instagram-item {
      width: 20%;
      margin-bottom: 1px;

      &:not(:last-child) {
        padding-right: 1px;
      }
    }
  }
}

#testimonials-wrapper {
  .title-wrapper {
    position: relative;
    .carousel-controls {
      position: absolute;
      top: 0;
      right: 0;

      a {
        width: 30px;
        height: 30px;
        display: inline-block;
        text-align: center;
        line-height: 30px;
        border: 1px solid $color-grey-light;
        background: $color-white;

        &:hover {
          border-color: $color-yellow;
          svg {
            color: $color-yellow;
          }
        }

        &:first-child {
          margin-right: 1px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  #newsletter {
    margin-top: 15px !important;
    padding-top: 20px;
    padding-bottom: 40px;
  }
  div#newsletter .container {
    .input-group {
      display: block !important;
    }

    display: block !important;
  }

  #newsletter .container {
    input#newsletter-email {
      width: 100%;
      width: 90%;
      margin: 0 auto;
      display: block !important;
      font-size: 18px;
      line-height: 23px;
      padding: 0px 0px;
      height: 28px;
    }

    .input-group-append button.btn.btn-primary {
      margin: 0 auto;
      margin-top: 5px;
      width: 150px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: #000000;
      font-family: "freight-neo-pro";
      height: 25px;
      padding: 0px;
      &:hover {
        color: #c04f79 !important;
      }
    }

    input#newsletter-email::placeholder {
      text-align: center;
      letter-spacing: 0px;
      color: #5f5f5f;
      opacity: 1;
      font-weight: 500;
      font-size: 18px;
      font-family: "freight-neo-pro";
    }
  }
  #newsletter {
    margin-top: 0 !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

//hover

.item-wrapper {
  padding: 20px 8px 0;

  @media (max-width: 500px) {
    padding: 0;
  }

  &:hover {
    cursor: pointer;
    .brand,
    .product-title,
    .rent,
    .market {
      color: #7e7d7d;
    }
  }
}

.refer-wrapper {
  margin-top: 2em;

  .head-title {
    width: fit-content;
    border-bottom: solid 1px black;
    margin: 4em auto 2em;
    font: normal normal normal 23px/26px Utile Display Book;

    @media (max-width: 835px) {
      margin: 2em auto 2em;
    }
  }

  .box {
    position: relative;
    width: 100%;
    height: 464px;
    background: transparent linear-gradient(180deg, #FFF2D9 0%, #E5CFA6 100%) 0% 0% no-repeat padding-box;
    display: flex;

    .image {
      width: 70%;
      max-width: 982px;
      height: 100%;
      background-image: url("~images/app/homepage/refer-friend.webp");
      background-repeat: no-repeat;
      background-position: left;
      background-size: cover;
    }

    .container {
      position: absolute;
      right: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 30%;
      max-width: calc(100% - 982px);
      height: 100%;

      & > * {
        margin: 0 auto;
      }

      .header {
        max-width: 180px;
        font: 45px/50px Utile Display Book;
        text-align: center;
      }

      .description {
        padding: 20px 50px;
        max-width: 400px;
        text-align: center;
        font: 22px/27px Utile Display Book;
      }

      .button {
        width: 267px;
        padding: 9px 10px 11px;
        background: #333;
        text-align: center;
        font: 15px/18px Utile;
        cursor: pointer;
        color: white;
      }
    }
  }

  @media (max-width: 1230px) {
    .head-title {
      margin-bottom: 2.8em;
    }

    .box {
      flex-direction: column;

      & > * {
        width: auto;
      }

      .image {
        width: 320px;
        height: 257px;
        position: relative;
        margin: 0 auto;
        top: -29px;
        background-image: url("~images/app/homepage/refer-friend-mobile.webp");
        background-size: contain;
      }

      .container {
        height: auto;
        width: 90%;
        max-width: none;
        margin-top: 250px;
        right: 5%;

        .header {
          font: 21.5px/24px Utile Display Book;
        }

        .description {
          width: auto;
          padding: 20px 0;
          font: 16.5px/19px Utile Display Book;
        }

        .button {
          padding: 5px 0 7px;
          width: 180px;
        }
      }
    }
  }
}

.item-wrapper {
  &:hover {
    cursor: pointer;
    .brand,
    .product-title,
    .rent,
    .market {
      color: #7e7d7d;
    }
  }
}

.wrapper {
  .bottom-text {
    padding: 92px 0;
    width: 66%;
    margin: auto;
    text-align: center;
    font: normal normal normal 17px/19px Utile Display Book;

    a {
      font: inherit;
      text-decoration: underline;
    }

    .read-more-button {
      display: none;
      text-decoration: underline;
    }

    @media (max-width: 900px) {
      padding: 50px 0;

      .read-more-button {
        display: inline;
      }

      .read-more-content {
        display: none;
      }
    }

    &--expanded {
      .read-more-button {
        display: none;
      }

      .read-more-content {
        display: inline;
      }
    }
  }
}

.reviews-carousel {
  margin-top: 30px;
  padding: 0px 16.5%;

  @media (max-width: 900px) {
    padding: 0px;
  }

  p {
    font-family: Utile Display Light;
    font-size: 34px;
    line-height: 44px;
    position: relative;
    padding: 10px 80px;

    @media (max-width: 900px) {
      font-size: 21px;
      line-height: 27px;
      padding: 10px 60px;
    }

    @media (max-width: 600px) {
      padding: 10px 50px;
    }
  }

  .slick-track {
    height: fit-content;

    @media (max-width: 600px) {
      #slick-slide01 {
        p {
          font-size: 19px;
          line-height: 22px;
        }
      }
    }
  }

  p:before,
  p:after {
    position: absolute;
    font-family: "Didot Regular";
    font-size: 160px;
    content: "”";
    color: black;

    @media (max-width: 900px) {
      font-size: 100px;
    }
  }

  p:before {
    transform: rotate(-180deg);
    top: -42px;
    left: 0px;

    @media (max-width: 900px) {
      top: -25px;
      left: 5px;
    }
  }

  p:after {
    bottom: -10px;
    right: 0px;

    @media (max-width: 900px) {
      bottom: -20px;
      right: 5px;
    }
  }

  .slick-dots {
    button {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 1px solid #8b8787;
      background-color: transparent;
      margin-right: 10px;

      @media (max-width: 900px) {
        width: 6px;
        height: 6px;
        margin-right: 5px;
      }
    }

    .slick-active {
      button {
        background-color: #8b8787;
      }
    }
  }
}

.reviews-container {
  padding: 0 6% !important;
}

