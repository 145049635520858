body {
  color: black;
}

#checkout-app {
  border-top: 1px solid #050505;
}

.checkout-container {
  width: 100%;
  padding: 42px 3.5vw 0px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #343333;
    width: 185px;
    height: 37px;
    font: 15px/18px "Utile Regular";
    color: #fff;
    margin-bottom: 6px;
    border: none;
    transition: color 0.2s, background-color 0.2s, box-shadow 0.2s;

    &:hover {
      background-color: #7e7d7d;
      color: white;
      box-shadow: 0 0 2px #000;
    }

    &:active {
      transform: translate(1px, 1px);
    }
  }

  label {
    font: normal normal normal 12px/14px "Utile Book";
  }

  hr {
    border-top: 1px solid #9f1353;
    margin-bottom: 26px;
    margin-top: 0px;
  }

  .need-help {
    font: normal normal normal 15px/18px "Utile Regular";
    padding-left: 2vw;

    a {
      text-decoration: underline;
      text-underline-offset: 3px;
      font: inherit;
    }
  }

  .main-box {
    display: flex;
    margin-bottom: 86px;
  }

  .nav {
    margin-bottom: 103px;
    display: flex;
    align-items: center;

    .before-chevron {
      display: inline-block;
      content: "";
      background-image: url("~images/app/chevron-right");
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      height: 8px;
      width: 8px;
      margin-right: 5px;
    }

    .back-to-cart {
      font: normal normal normal 12px/13px "Utile Book";
    }
  }

  #nav-mobile {
    display: none;
    margin-bottom: 29px;
  }

  @media (max-width: 900px) {
    padding: 23px 3vw 0px;

    hr {
      margin-bottom: 13px;
    }

    .main-box {
      flex-direction: column;
    }

    .checkout-form {
      order: 2;
    }

    #nav-mobile {
      display: flex;
    }

    #nav-desktop {
      display: none;
    }

    .need-help {
      padding-left: 0;
      margin-bottom: 24px;
      font: normal normal normal 13px/14px "Utile Book";
    }
  }

  @media (max-width: 800px) {
    button {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 600px) {
    padding: 23px 5.5vw 0px;
  }
  input:hover {
    cursor: pointer;
  }
}

.checkout-form {
  flex-basis: 60%;
  flex-shrink: 1;
  margin-right: 7.2vw;
  transition: all 1s 0.5s ease-in-out;
  position: relative;

  .checkout-title {
    font: normal normal normal 23px/26px "Utile Display Book";
    margin-bottom: 31px;
    position: absolute;
    top: -60px;
  }

  .ac-title {
    font: normal normal normal 21px/18px "Utile Display Book";
    border-bottom: 1px solid #9f1353;
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 35px;

    .checkout-number {
      border: 1px solid #9f1353;
      border-radius: 100%;
      background-color: #9f1353;
      color: white;
      display: flex;
      justify-content: center;
      width: 18px;
      height: 18px;
      align-items: center;
      font-size: 11px;
      margin-right: 10px;
      margin-bottom: 0;
      font-family: "Roboto", serif;
    }
  }

  .mr-input {
    margin-right: 2vw;
  }

  #first-inp {
    flex: 0 1 45%;
  }

  .form-item {
    flex: 1;
    margin-bottom: 24px;

    label {
      display: block;
      font: normal normal normal 12px/14px "Utile Book";
    }

    input {
      width: 100%;
      height: 35px;
      font: normal normal normal 15px/16px "Utile Regular";
      padding-left: 7px;
    }
  }

  p {
    font: normal normal normal 15px/16px "Utile Regular";
    margin-bottom: 27px;
  }

  #mobile-phone {
    padding-bottom: 30px;

    p, label {
      font: 12px/14px "Utile Book";
    }

    p {
      margin-bottom: 11px;
    }

    label {
      display: block;
      margin-top: 11px;
      margin-bottom: 5px;
    }

    #sms-code-wrapper {
      display: flex;

      #sms-code-sent {
        max-width: 300px;
        padding-left: 50px;
        font: 12px/14px "Utile Display Book";
      }
    }
  }

  #persona-verification {
    margin-bottom: 20px;

    #skip-persona-modal {
      background: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 999;

      .container {
        border: 6px solid #212121;
        background: white;
        max-width: 684px;
        width: 90%;
        min-height: 293px;
        margin: auto;
        margin-top: 50vh;
        transform: translateY(-147px);

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        h2 {
          font: 22px/25px Utile Display Book;
          margin-bottom: 20px;
        }

        p {
          font: 16px/18px Utile Display Book;
        }

        a {
          font: 12px/13px Utile Book;
          text-decoration: underline;
          margin: 0 17px;
          cursor: pointer;
        }
      }
    }
  }
    
  #send-sms, #persona {
    font: 15px/18px "Utile Regular";
    cursor: pointer;
    color: #9f1353;
    text-decoration: underline;
  }

  #sms-code, #birth {
    width: 200px;
    height: 35px;
    cursor: text;
  }

  .name {
    display: flex;
  }

  .rest-address-info {
    display: flex;
    flex-wrap: wrap;

    .form-item {
      flex-grow: 1;
    }

    div:last-of-type {
      margin-right: 0;
    }

    #city-box {
      flex-basis: 24.3%;
      min-width: 130px;
    }

    #state-box {
      flex: 1;
      min-width: 140px;
      flex-basis: 25%;

      select {
        border: 1px solid #707070c7;
        height: 35px;
        border-radius: 2px;
        font: normal normal normal 15px/18px "Utile Regular";
        color: black;
        cursor: pointer;
        width: 100%;
        padding-left: 3px;
        background: #fff url("~images/app/checkout/chevron-down") no-repeat
          right 8px center/18px 8px;
        appearance: none;
        &::-ms-expand {
          display: none;
        }
      }
    }

    #zipcode-box {
      min-width: 130px;
      flex-basis: 15.6%;
    }

    #number-box {
      min-width: 130px;
      max-width: 161px;

      p {
        font: normal normal normal 11px/13px "Utile Regular";
        padding-top: 2px;
        position: relative;
        padding-left: 8px;
        &:before {
          content: "*";
          position: absolute;
          left: 0;
        }
      }
    }

    #verification-box {
      flex-basis: 43%;
    }
  }

  .save-container {
    display: flex;
    justify-content: flex-end;
  }

  [type="checkbox"] {
    margin-right: 5px;
  }
  .form-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 43px;
    position: relative;

    .email-dates {
      display: flex;
      justify-content: space-evenly;
      flex: 1;
    }

    .shipping-address,
    .email {
      padding-right: 13px;
    }

    .city {
      margin-bottom: 15px;
    }

    h5 {
      font: normal normal normal 15px/19px "Utile Regular";
    }

    p {
      font: normal normal normal 14px/19px "Utile Book";
      margin-bottom: 0;
    }

    a {
      font: normal normal normal 13px/15px "Utile Regular";
      text-decoration: underline;
      text-underline-offset: 6px;
      cursor: pointer;
      position: relative;
      bottom: 21px;

      &:hover {
        color: #ffc000;
      }
    }
  }

  #checkout-verification {
    transition: all 1s 0.5s ease-in-out;

    #disclaimer {
      font-size: 12px;
      line-height: 14px;
    }

    .header {
      font: 16px Utile Display Book;
      border-bottom: 1px solid gray;
      padding-bottom: 7px;
      margin-bottom: 12px;

      &::before {
        display: inline-block;
        content: "";
        border: 7px solid transparent;
        border-left: 9px solid black;
        transform: translateY(2px);
      }
    }

    .verification-pending {
      h5 {
        margin-bottom: 0;
      }

      p {
        margin-left: 21px;
      }
    }

    .pending::before,
    .verified p::before {
      background: url("~images/app/checkout/green-tick.svg");
      content: "";
      background-size: auto;
      background-size: cover;
      width: 15px;
      height: 10px;
      display: inline-block;
      margin-right: 6px;
    }
  }

  #checkout-billing {
    transition: all 1s 0.5s ease-in-out;
    .billing-title {
      display: flex;
      margin-bottom: 16px;

      .fake-radio {
        margin-right: 7px;
      }
      p {
        margin-bottom: 0;
        font-size: 13px;
        font-family: "Utile Book";
      }
    }

    #billing-edit {
      display: none;
    }

    #billing-display {
      display: none;
    }

    img {
      margin-bottom: 0px;
    }

    .form-item {
      margin-bottom: 17px;
    }

    .card-details {
      display: flex;
      width: 50%;

      .form-item:last-of-type {
        margin-right: 15px;
      }
    }

    .display-billing {
      flex: 1;
    }
  }

  #checkout-review {
    transition: all 1s 0.5s ease-in-out;
    p {
      font-size: 12px;
    }

    .tos-link {
      font: normal normal normal 13px/14px "Utile Regular";
      text-decoration: underline;
    }
  }

  .checkboxes {
    margin-bottom: 31px;

    label {
      display: inline;
    }
  }

  .collapsed {
    height: 0;
    overflow: hidden;
  }

  @media (max-width: 1175px) {
    margin-right: 5vw;
    .mr-input {
      margin-right: 1vw;
    }
  }

  @media (max-width: 1100px) {
    margin-right: 4vw;
    .rest-address-info {
      #city-box {
        flex-basis: 48%;
      }
      #state-box {
        flex-basis: 48%;
        margin-right: 0;
      }

      #zipcode-box {
        flex-basis: 48%;
      }
    }
  }

  @media (max-width: 900px) {
    margin-right: 0px;

    .form-details {
      h5 {
        margin-bottom: 0px;
        font: normal normal normal 13px/19px "Utile Medium";
      }

      a {
        position: absolute;
        top: -46px;
        right: 0px;
        text-underline-offset: 3px;
      }

      p {
        font: normal normal normal 12px/16px "Utile Regular";
      }
    }

    .rest-address-info {
      #state-box {
        select {
          height: 40px;
        }
      }
    }

    p {
      font-size: 13px;
      margin-bottom: 10px;
    }

    .mr-input {
      margin-right: 8px;
    }

    .form-item {
      position: relative;
      margin-bottom: 14px;
      label {
        position: absolute;
        top: -4px;
        z-index: 1;
        background-color: white;
        font: normal normal normal 11px/11px "Utile Regular";
        left: 13px;
      }

      input {
        height: 40px;
        font-size: 13px;
      }
    }

    .checkout-title {
      font: normal normal normal 17px/19px "Utile Display Book";
      position: static;
      margin-bottom: 21px;
    }

    .ac-title {
      align-items: center;
      padding-bottom: 5px;
      margin-bottom: 23px;
      .checkout-number {
        margin-right: 7px;
        width: 15px;
        height: 16px;
        font-size: 9px;
      }

      span {
        font: normal normal normal 15px/18px "Utile Regular";
      }
    }

    .save-container {
      justify-content: center;

      button {
        width: 71vw;
        max-width: 300px;
        margin-bottom: 37px;
        height: 42px;
      }
    }

    .verified {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 750px) {
    #checkout-shipping .form-details {
      justify-content: space-evenly;

      .email-dates {
        flex-direction: column;
        flex: 0 1 auto;
        .email {
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (max-width: 460px) {
    .form-details,
    #checkout-shipping .form-details {
      justify-content: space-between;
    }
  }
}

.right-column {
  flex: 1;
  min-width: 345px;
  .toggle-details {
    display: none;
    justify-content: flex-end;
    align-items: baseline;
    margin-bottom: 20px;
    p {
      font: normal normal normal 13px/14px "Utile Medium";
      cursor: pointer;
      margin-bottom: 0px;
    }

    #toggle-chevron {
      display: inline-block;
      content: "";
      height: 8px;
      width: 12px;
      background-image: url("~images/app/checkout/chevron-down");
      background-size: contain;
      margin-left: 7px;
    }
  }

  .hr-mobile {
    display: none;
    position: relative;
    right: 5.5vw;
    width: 120vw;
    margin-bottom: 18px;
  }

  .toggle-div {
    overflow: hidden;
    transition: all 1s ease-in-out;
  }

  .checkout-details {
    background-color: rgba(126, 123, 124, 0.123);
    padding: 32px 27px 13px 29px;
    margin-bottom: 10px;

    .plan-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 127px;
      height: 22px;
      color: #fff;
      font: normal normal normal 13px/14px "Utile Regular";
      background-color: #9f1353;
      margin-bottom: 26px;
    }

    .details-h5 {
      font: normal normal normal 16px/18px "Utile Display Book";
      border-bottom: 1px solid #9f1353;
      display: flex;
      padding-bottom: 8px;
      padding-left: 5px;
      margin-bottom: 43px;
    }

    .items-container {
      margin-bottom: 17px;
      .price {
        padding-left: 5px;
      }
    }

    .order-item {
      margin-bottom: 13px;
      display: flex;
      img {
        width: 100px;
        margin-right: 21px;
      }

      p {
        font: normal normal normal 13px/15px "Utile Regular";
        margin-bottom: 10px;
      }
      .brand {
        margin-bottom: 3px;
      }

      .order-item-details {
        flex: 1;
        .description-item,
        .description-title {
          margin-bottom: 0;
          font-size: smaller;
        }
        .variants-size,
        .variants-color {
          margin-bottom: 0;
        }
      }
    }

    .prices {
      .prices-line {
        font: normal normal normal 14px/16px "Utile Regular";
        display: flex;
        justify-content: space-between;

        p {
          margin-bottom: 21px;
        }
      }
    }

    .after-prices {
      margin-bottom: 17px;
    }

    .dates {
      font: normal normal normal 15px/28px "Utile Regular";
      p {
        margin-bottom: 16px;
      }
    }

    .promo {
      font: normal normal normal 13px/22px "Utile Regular";
      margin-bottom: 9px;
    }

    #promo-input-btn {
      display: block;

      input {
        width: 81.7%;
        height: 52px;
        font: normal normal normal 25px/22px "Utile Regular";
        border-color: #70707054;
        margin-bottom: 18px;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #343333;
        width: 185px;
        height: 37px;
        font: 15px/18px "Utile Regular";
        color: #fff;
        margin-bottom: 6px;
        border: none;
        transition: color 0.2s, background-color 0.2s, box-shadow 0.2s;

        &:hover {
          color: white;
          background-color: #7e7d7d;
          box-shadow: 0 0 2px #000;
        }
      }
    }
  }

  @media (max-width: 900px) {
    min-width: auto;
    .toggle-details {
      display: flex;
    }

    .checkout-details {
      padding: 24px 12px 1px;

      .details-h5 {
        display: none;
      }

      hr {
        border-top-color: #000000;
      }

      .plan-title {
        display: none;
      }

      .items-container {
        margin-bottom: 21px;
      }

      .order-item {
        margin-bottom: 8px;

        p {
          font-size: 12px;
        }

        img {
          width: 15vw;
          max-width: 100px;
          margin-right: 14px;
        }
      }

      .prices {
        margin-bottom: 12px;
        .prices-line {
          p {
            margin-bottom: 8px;
            font-size: 13px;
          }
        }
      }

      .after-prices {
        margin-bottom: 14px;
      }

      .dates {
        p {
          font-size: 12px;
          line-height: 12px;
          margin-bottom: 6px;
        }

        p:last-of-type {
          margin-bottom: 13px;
        }
      }

      #promo-input-btn {
        input {
          height: 34px;
          width: 100%;
          font-size: 15px;
        }
        button {
          height: 34px;
          width: 135px;
          font-size: 14px;
        }
      }
    }

    .hr-mobile {
      display: block;
      border-top-color: #050505;
    }

    .promo {
      font-size: 12px;
    }
  }

  @media (max-width: 500px) {
  }
}

#dropin-container {
  .braintree-heading {
    display: none;
  }

  .braintree-sheet__content--form {
    padding: 10px 0;

    .braintree-form__field-group {
      padding-left: 0;
    }
  }

  .braintree-card {
    border: none;
  }

  .braintree-sheet__header {
    display: none;
  }

  .braintree-form__field {
    margin-right: 8px;
  }

  .braintree-form-number,
  .braintree-form__hosted-field {
    height: 35px;
    font: normal normal normal 15px/16px "Utile Regular";
    border: 1px solid #707070c7;
  }
}

#sub-checkout {
  .checkout-details {
    display: flex;
    flex-direction: column;
  }

  hr {
    border-top-color: #707070;
    width: 100%;
  }

  .hr-mobile {
    right: 5.5vw;
    width: 100vw;
  }

  h5 {
    border-bottom-color: #707070;
  }

  .ac-title {
    border-bottom-color: #c36300;
  }

  .checkout-number,
  .plan-title {
    background-color: #c36300;
  }

  .checkout-number {
    border: 1px solid #c36300;
  }

  .plan-title {
    width: 172px;
    height: 25px;
    font: normal normal normal 15px/18px "Utile Regular";
  }

  .membership-details {
    font: normal normal normal 14px/18px "Utile Regular";

    p:first-of-type {
      margin-bottom: 0;
    }

    p {
      line-height: initial;
    }

    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 30px;

      li:before {
        content: "·";
        margin-right: 2px;
        font-size: 28px;
        vertical-align: bottom;
        line-height: 19px;
      }
    }
  }

  .details-spacer-mobile {
    display: none;
  }

  .details-spacer {
    content: "";
    height: 16px;
    background-color: white;
    margin: 0 -27px 23px -29px;
  }

  .membership {
    margin-bottom: 20px;
  }

  .price {
    display: none;
  }

  @media (max-width: 900px) {
    .braintree-form__field-group label {
      width: 100%;
    }

    .membership {
      display: flex;
    }

    .membership-details {
      order: 2;
      font-size: 12px;

      .membership {
        font: normal normal normal 16px/18px "Utile Display Book";
      }

      .details-spacer {
        display: none;
      }

      .details-spacer-mobile {
        display: block;
        height: 11px;
        content: "";
        background-color: white;
        margin: 0 -12px 16px;
      }

      ul {
        margin-bottom: 18px;
      }
    }

    .brand {
      margin-bottom: -2px;
    }

    #promo-input-btn {
      input {
        height: 26px;
      }
      button {
        margin-bottom: 0px;
        height: 26px;
        font-size: 13px;
      }
    }
  }
}
