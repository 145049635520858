.owl-wrapper {
  position: relative;

  .owl-nav {
    position: absolute;
    top: -67px;
    right: 0;

    button.owl-prev, button.owl-next {
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 1px solid $color-grey;
      right: 0;
      color: $color-grey;
      @include transition(all $ani-speed ease);

      span {
        font-size: 24px;
        line-height: 1em;
      }

      &:hover {
        border-color: $color-yellow;
        color: $color-yellow;
      }
    }

    button.owl-prev {
      margin-right: 5px;
    }
  }
}

.owl-carousel {     
  .owl-stage-outer {
    padding: 2px;
  }
}