.review-thumb-pic {
  .review-image {
    margin-bottom: 15px;
    img {
      max-width: 100%;
    }
  }

  .review-details {
    .avatar {
      flex: 0 0 80px;
      margin-right: 10px;
      // width: 80px;
      height: 80px;
      overflow: hidden;
      display: inline-block;

      img {
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .username {
      font-family: $font-roboto-slab;
      @include font-size(14px);
      color: $color-black;
      margin-bottom: 5px;
    }

    p {
      color: $color-grey-dark;
      margin-bottom: 5px;
      @include font-size(12px);

      // text truncate 2 lines
      display: inline;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-wrap: break-word; /* Important for long words! */
    }
  }
}

.review-thumb {
  .review-header {
    margin-bottom: 5px;
    position: relative;

    .username {
      font-family: $font-roboto-slab;
      @include font-size(14px);
      color: $color-black;
      margin-bottom: 5px;
    }

    .date {
      color: $color-grey;
    }

    .rating {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .review-content {
    color: $color-grey-dark;

  }
}
