.passwords-wrapper {
    .title {
        margin: 50px 0;
    }
    h2 {
        font-family: 'Utile';
    }
    #error_explanation {
        h2 {
            font-family: 'Utile';
        }
        font-family: 'Utile';
    }
    button {
        font-family: 'Utile';
        font-size: 14px;
        border: none;
        &:hover {
            background: #7e7d7d;
            color: white !important;
        }
    }
    a:hover {
        color: #c36300 !important;
    }
}