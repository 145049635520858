nav.nav-profile, nav.nav-horizontal-tabs {
  padding: 10px 0;
  border-bottom: 1px solid $color-grey-light;
  margin-bottom: 20px;

  a {
    height: 100%;
    display: block;
    text-align: center;
    color: $color-black;
    position: relative;
    text-transform: uppercase;
    font-family: $font-roboto;
    font-weight: 300;
    @include font-size(13px);
    @include transition(none);

    @media (max-width: 991px) {
      @include font-size(12px);
    }

    &:hover {
      font-weight: 400;
    }

    &.active {
      font-weight: 400;

      @media (min-width: 768px) {
        &:after {
          content: '';
          height: 1px;
          width: 100%;
          background: $color-black;
          bottom: -11px;
          position: absolute;
          left: 0;
        }
      }
    }
  }
}

nav#profile-form .nav.nav-tabs a.nav-item{
  width: 25%;
  text-align: center;

  @media (max-width: 550px) {
    width: 100%;
    border-color: #dee2e6 #dee2e6 #fff;
  }
}

.store-credit {
  font-family: "Roboto Slab", serif;
  color: #595959;
}
