.select2 {
  outline: 0;

  .select2-selection {
    border-radius: 0;
    border-color: $color-grey-light;
    outline: 0;
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-weight: 300;
  font-family: $font-roboto;
  @include font-size(13px);
  color: $color-grey;
  text-align: center;
}

.select2-dropdown {
  border-color: $color-grey-light;
  border-radius: 0;
}

.select2-search__field {
  outline: 0;
}

.select2-container--default {
  .select2-results__option, .select2-results__option--highlighted[aria-selected] {
    font-weight: 300;
    font-family: $font-roboto;
    @include font-size(13px);
    color: $color-grey;
    text-align: center;
  }

  .select2-results__option--highlighted[aria-selected] {
    background: $color-pink;
    @include transition(all $ani-speed ease);
    color: $color-grey-dark;
  }
}
