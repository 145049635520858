#toast-container {
    .toast-error {
        background-image: url("~images/app/toast/error.svg") !important;
        background-color: white;
        border-left: 5px solid #9F1313;
        opacity: 1 !important; 
    }
    .toast-warning {
        background-image: url("~images/app/toast/warning.svg") !important;
        background-color: white;
        border-left: 5px solid #D18D1B;
        opacity: 1 !important; 
    }
    .toast-info {
        background-image: url("~images/app/toast/info.svg") !important;
        background-color: white;
        border-left: 5px solid #1D1B1C;
        opacity: 1 !important; 
    }
    .toast-success {
        background-image: url("~images/app/toast/success.svg") !important;
        background-color: white;
        border-left: 5px solid #166F64;
        opacity: 1 !important; 
    }
    .toast-title, .toast-message {
        color: black;
    }
    .toast-close-button {
        opacity: 1;
        color: #C7C6C6;
        top: 0;
    }

    .modal-backdrop.show{
        opacity: 1;
    }
}