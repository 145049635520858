

.fieldset-label {
  background: #EDEDED;
  color: #000;
  font-size: 16px;
  text-align: center;
  padding: 10px;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.edit-btn {
  text-decoration: underline;
  margin-bottom: 10px;
}
