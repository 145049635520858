
.wrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 900;
}
.modal-dialog {
  border: 5px solid black;
  border-radius: 10px;
  z-index: 1000;
  @media (max-width: 560px) {
    width: fit-content;
    margin: 0 auto;
  }
}
.modal-header {
  button:hover {
    color: black;
    opacity: 0.7;
  }
}
.modal-content {
  h4 {
    font-family: Utile Display Book;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 26px;
    color: #000000;
    margin: 0 auto;
    @media (max-width: 813px) {
      font-size: 24px;
    }
  }
  h5 {
    font-family: Utile Display Book;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }
  hr {
    height: 1px;
    border-bottom: 1px solid black;
    width: 55%;
    margin-bottom: 50px;
  }
  input,
  .invalid-feedback {
    width: 80%;
    margin: 0 auto;
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    .send-password-button {
      width: 230px;
      height: 34px;
      background: #343233;
      color: white;
      font-family: Utile;
      font-size: 14px;
      border: none;
      &:hover {
        background: #7e7d7d;
      }
      @media (max-width: 813px) {
        width: 179px;
        height: 30px;
        font-size: 11px;
      }
    }
  }
}
::v-deep .fb-ico,
.google-ico {
  width: 24px;
  height: 22px;
  background: url("~images/app/register/fb-icon-white.svg");
  background-position: center;
  background-size: cover;
  margin-right: 0.5em;
  @media (max-width: 813px) {
    margin-right: 8px;
    height: 20px;
    width: 22px;
  }
}
.google-ico {
  background: url("~images/app/register/google-icon-white.svg");
  background-position: center;
  background-size: cover;
}
.google-btn,
.fb-btn {
  width: 230px;
  height: 34px;
  background: #817f7f;
  color: white;
  text-align: center;
  font-family: Utile;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  margin: 0 auto 15.5px;
  @media (max-width: 813px) {
    width: 179px;
    height: 30px;
    font-size: 11px;
  }
}
.google-btn {
  margin-bottom: 38px;
  @media (max-width: 813px) {
    margin-bottom: 20px;
  }
}
