.occasions {
  text-align: center;

  &__hero {
    height: 454px;
    width: 100%;
    background-image: url("~images/app/occasions/header-mobile.webp");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    position: relative;
    margin-bottom: 200px;

    @media (min-width: 630px) {
      height: 532px;
      background-image: url("~images/app/occasions/header.webp");
      background-position: right;
      margin-bottom: 60px;
    }
  }

  &__hero-box {
    position: absolute;
    left: 23px;
    right: 0px;
    bottom: -150px;
    background: #FFD9D1;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 40px;
    z-index: 5;

    @media (min-width: 630px) {
      background: #f5f4f0;
      opacity: 0.86;
      width: 509px;
      left: 0px;
      top: 224px;
      bottom: initial;
      padding-top: 24px;
    }
  }

  &__hero-box-header {
    font-family: Utile Display Book;
    font-size: 27px;
    margin-bottom: 9px;

    @media (min-width: 630px) {
      font-size: 28px;
      margin-bottom: 16px;
    }

    br {
      display: inline;

      @media (min-width: 630px) {
        display: none;
      }
    }
  }

  &__hero-box-text {
    font-family: Utile Display Book;
    margin-bottom: 0px;
    font-size: 18px;

    @media (min-width: 630px) {
      font-size: 24px;
    }

    br {
      display: inline;

      @media (min-width: 630px) {
        display: none;
      }
    }
  }

  &__header-layer {
    display: block;
    height: 56px;
    width: 100%;
    background: #FFC697C7;
    position: absolute;

    @media (min-width: 630px) {
      display: none;
    }

    &--one {
      bottom: 0px;
      opacity: 0.9;
    }

    &--two {
      bottom: -56px;
      position: absolute;
    }
  }

  &__description {
    width: 80%;
    min-width: 322px;
    margin: 0 auto;
  }

  &__description-header {
    font-family: Utile Display Book;
    font-size: 21px;
    line-height: 24px;

    @media (min-width: 630px) {
      font-size: 23px;
      line-height: 26px;
    }
  }

  &__description-header-underscore {
    height: 1px;
    width: 92%;
    min-width: 288px;
    max-width: 630px;
    background-color: black;
    margin: 4px auto 8px;

    @media (min-width: 630px) {
      margin: 6px auto 12px;
      width: 96%;
    }
  }

  &__description-text {
    font-family: Utile Book;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 0px;

    @media (min-width: 630px) {
      font-size: 17px;
      line-height: 19px;
    }

    br {
      display: none;

      @media (min-width: 1020px) {
        display: inline;
      }
    }
  }

  &__item {
    position: relative;
    padding-top: 90vw;
    padding-bottom: 40px;

    @media (min-width: 450px) {
      padding-top: 396px;
    }

    @media (min-width: 900px) {
      display: flex;
      padding-top: initial;
      padding-bottom: initial;
    }

    &--1 {
      margin-top: 74px;
      background: transparent linear-gradient(180deg, #FFF4F4 0%, #F4CBBE 100%) 0% 0% no-repeat padding-box;

      @media (min-width: 900px) {
        margin-top: 138px;
        justify-content: left;
      }
    }

    &--2 {
      margin-top: 67px;
      background: transparent linear-gradient(180deg, #F7EEEA 0%, #B7B7B7 100%) 0% 0% no-repeat padding-box;

      @media (min-width: 900px) {
        margin-top: 148px;
        justify-content: right;
      }
    }

    &--3 {
      margin-top: initial;
      background: transparent linear-gradient(180deg, #FFEDDD 0%, #FFAD81 100%) 0% 0% no-repeat padding-box;

      @media (min-width: 900px) {
        justify-content: left;
      }
    }

    &--4 {
      margin-top: 95px;
      background: transparent linear-gradient(180deg, #FFF4F4 0%, #F8BAC6 100%) 0% 0% no-repeat padding-box;

      @media (min-width: 900px) {
        margin-top: 148px;
        justify-content: right;
      }
    }

    &--5 {
      margin-top: 77px;
      margin-bottom: 60px;
      background: transparent linear-gradient(180deg, #FFF2E0 0%, #DDBD99 100%) 0% 0% no-repeat padding-box;

      @media (min-width: 900px) {
        margin-top: 173px;
        margin-bottom: 100px;
        justify-content: left;
      }
    }
  }

  &__item-image-wrapper {
    @media (min-width: 900px) {
      width: 57%;
      position: relative;
      top: -50px;
    }

    &--1,
    &--3,
    &--5 {
      @media (min-width: 900px) {
        order: 1;
      }
    }

    img {
      display: block;
      position: absolute;
      width: 88%;
      top: -6vw;
      left: 50%;
      transform: translateX(-50%);

      @media (min-width: 450px) {
        top: -32px;
        width: 400px;
      }

      @media (min-width: 900px) {
        position: initial;
        left: initial;
        transform: initial;
        top: initial;
        margin: 0 auto;
        width: 78%;
        max-width: 540px;
      }
    }
  }

  &__item-details {
    padding: 0 6%;

    @media (min-width: 630px) {
      padding: initial;
      width: 84%;
      max-width: 700px;
      margin: 0 auto;
    }

    @media (min-width: 900px) {
      width: 30%;
      max-width: 395px;
      margin: initial;
      padding: 6vw 0;
    }

    @media (min-width: 1200px) {
      padding: 96px 0;
    }

    &--1,
    &--3,
    &--5 {
      @media (min-width: 900px) {
        order: 2;
      }
    }
  }

  &__item-header {
    font-family: Utile Display Book;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 34px;

    @media (min-width: 900px) {
      font-size: 35px;
      line-height: 40px;
    }
  }

  &__item-header-underscore {
    height: 1px;
    background-color: black;
    margin: 5px 0 initial;
  }

  &__item-title {
    font-family: Utile Book;
    font-size: 17px;
    line-height: 19px;
    margin-top: 30px;

    @media (min-width: 900px) {
      font-size: 20px;
      line-height: 23px;
      margin-top: 39px;
    }
  }

  &__item-description {
    font-family: Utile Regular;
    font-size: 13px;
    line-height: 20px;
    margin-top: 17px;

    @media (min-width: 900px) {
      font-size: 15px;
      line-height: 23px;
      margin-top: 13px;
    }
  }

  &__item-cta {
    display: flex;
    width: 185px;
    height: 38px;
    background-color: #343333;
    color: white;
    font-family: Utile Regular;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    margin: 30px auto 0px;

    @media (min-width: 900px) {
      margin-top: 38px;
    }
  }

  .reviews-carousel {
    margin: 86px 0 130px;
    padding: 0px 16.5%;

    @media (max-width: 900px) {
      padding: 0px;
      margin-bottom: 80px;
    }

    p {
      font-family: Utile Display Light;
      font-size: 34px;
      line-height: 44px;
      position: relative;
      padding: 10px 80px;

      @media (max-width: 900px) {
        font-size: 21px;
        line-height: 27px;
        padding: 10px 60px;
      }

      @media (max-width: 600px) {
        padding: 10px 50px;
      }
    }

    .slick-track {
      height: fit-content;

      @media (max-width: 600px) {
        #slick-slide01 {
          p {
            font-size: 19px;
            line-height: 22px;
          }
        }
      }
    }

    p:before,
    p:after {
      position: absolute;
      font-family: "Didot Regular";
      font-size: 160px;
      content: "”";
      color: black;

      @media (max-width: 900px) {
        font-size: 100px;
      }
    }

    p:before {
      transform: rotate(-180deg);
      top: -42px;
      left: 0px;

      @media (max-width: 900px) {
        top: -25px;
        left: 5px;
      }
    }

    p:after {
      bottom: -10px;
      right: 0px;

      @media (max-width: 900px) {
        bottom: -20px;
        right: 5px;
      }
    }

    .slick-dots {
      button {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 1px solid #8b8787;
        background-color: transparent;
        margin-right: 10px;

        @media (max-width: 900px) {
          width: 6px;
          height: 6px;
          margin-right: 5px;
        }
      }

      .slick-active {
        button {
          background-color: #8b8787;
        }
      }
    }
  }
}
