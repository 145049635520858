
@import '~stylesheets/app/vars';

.subscriptions-wizard {

  .nav.nav-tabs {
    margin-bottom: 6px;
    li.nav-item {
      width: 25%;
      a {
        border: none;
        background: #EDEDED;
        color: #000;
        text-transform: uppercase;
        text-align: center;

        &.active {
          color: #fff;
          background: #000;
        }

        @media (max-width: 440px) {
          font-size: 12px;
          padding: 8px 16px;
        }

        @media (max-width: 440px) {
          font-size: 10px;
        }
      }
    }
  }

  .tab-content {
    border: none;
    padding: 0;

    .step-header {
      padding: 10px;
      font-size: 20px;
      font-family: $font-abril;
      letter-spacing: 0.4px;
      border: 1px solid #000;
      text-align: center;
      margin-bottom: 6px;
      line-height: 1.2em;

      @media (max-width: 767px) {
        font-size: 16px;
      }

      @media (max-width: 450px) {
        font-size: 12px;
      }
    }

    .step-info {
      img {
        width: 100%;
      }
    }
  }
}
