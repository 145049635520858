.faq-title {
  font-family: 'Utile Display Book';
}

#pills-users-tab, #pills-vendors-tab {
  font-family: 'Utile Regular' !important;
  font-size: 14px;
  &:hover {
    color: #c36300;
  }
}

.mail-link {
  font-family: 'Utile';
}

.tab-content-faq {
  border: 0;
  padding: 0;

  .question-wrapper {
    margin-bottom: 30px;

    p {
      margin-bottom: 0;
      font-family: 'Utile';
    }

    a.question {
      position: relative;
      display: block;
      font-family: 'Utile Display Book';
      font-size: 25px;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-grey-light;
      margin-bottom: 10px;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100px;
        height: 1px;
        background-color: $color-grey;
      }
      &:hover {
        color: #c36300;
      }
    }
  }
}
