@font-face {
  font-family: "FreightNeo Pro Medium";
  src: url(../../fonts/FreightNeoProMedium-Regular.otf) format("truetype");
}

@font-face {
  font-family: "FreightNeo Pro Bold";
  src: url(../../fonts/FreightNeoProBold-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Arsilon";
  src: url(../../fonts/Arsilon.otf) format("truetype");
}

@font-face {
  font-family: "Lust";
  src: url(../../fonts/Lust-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Didot Regular";
  src: url(../../fonts/Didot-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Utile Book";
  src: url(../../fonts/Utile-Book.otf) format("truetype");
}

@font-face {
  font-family: "Utile Medium";
  src: url(../../fonts/Utile-Medium.otf) format("truetype");
}

@font-face {
  font-family: "Utile Regular";
  src: url(../../fonts/Utile-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Utile Display Book";
  src: url(../../fonts/UtileDisplay-Book.otf) format("truetype");
}

@font-face {
  font-family: "Utile Display Light";
  src: url(../../fonts/UtileDisplay-Light.otf) format("truetype");
}
