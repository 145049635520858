body {
  padding-top: 118px;
  font-size: 14px;
  font-family: $font-roboto;

  @media (max-width: 991px) {
    padding-top: 94px;
  }
}

@import "layout/header";
@import "layout/footer";

#breadcrumbs {
  background: #f9f9fa;
  padding: 12px 0;
  margin-bottom: 30px;
  font-family: $font-roboto;
  font-weight: 300;
  @include font-size(12px);

  a {
    font-family: $font-roboto;
    font-weight: 300;
    @include font-size(12px);
  }

  .current {
    color: #8c8c8c;
  }

  .btn-switch-mode {
    position: absolute;
    right: 15px;
    top: -4px;
    font-size: 10px;
    padding: 2px 20px;
  }
}

main {
  margin-top: 10px;
  min-height: calc(100vh - 128px);
  position: relative;
  padding-bottom: 335px;

  @media (max-width: 991px) {
    min-height: calc(100vh - 104px);
  }

  @media (max-width: 600px) {
    padding-bottom: 492px;
  }
}

body.colored {
  // background-color: $color-pink-transparent;
  // nav.navbar {
  //   background-color: $color-pink-transparent;
  // }
  //
  // #newsletter {
  //   background-color: $color-pink-transparent;
  // }
}

@media (max-width: 575px) {
  main {
    margin-top: 0px;
  }
}
