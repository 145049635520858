
.subscription-wrapper {
  p {
    font-family: "Roboto Slab", serif;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    color: #000000;
    padding-bottom: 6.25px;
  }

  .cancel-subscription {
    border: solid 2px black;
    padding: 0.5em;
    &:hover {
      color: white;
      background: black;
    }
  }
  .click-here-link {
    &:hover {
      color: black;
      opacity: 0.7;
    }
  }
}
span.phone-prefix{
  position: absolute;
  color: #495057;
  font-family: Roboto,serif;
  font-weight: 300;
  font-size: .8125rem;
  height: 38px;
  line-height: 38px;
  padding-left: 15px;
  pointer-events: none;
}
input#user-phone{
  padding-left: 45px;
}
