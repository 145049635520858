a {
  color: $color-black;
  font-family: $font-roboto-slab;
  @include transition(color $ani-speed ease);

  svg {
    // @include transition(color $ani-speed ease);
  }

  &:hover {
    color: $color-yellow;
    text-decoration: none;
  }
}
.is-flex {
  display: flex;
}

.font-roboto {
  font-family: $font-roboto;
}

.font-roboto-slab {
  font-family: $font-roboto-slab;
}

.font-abril {
  font-family: $font-abril;
}

.font-300 {
  font-weight: 300;
}

@each $h in h1, h2, h3, h4, h5, h6 {
  #{$h} {
    font-family: $font-abril;
  }
}

p {
  line-height: 1.4;
}

.box {
  &.box-primary {
    border: 2px solid $color-yellow;
    padding: 10px;
  }

  .box-title {
    font-family: $font-abril;
  }

  &.box-secondary {
    border: 1px solid rgba(255, 255, 255, 0);
    padding: 10px;
    @include transition(all $ani-speed-slower ease);

    .box-body {
      position: relative;
      height: 100%;
    }

    a.image-wrapper {
      display: block;
      img {
        width: 100%;
        height: auto;
      }
    }

    .wrapper-links {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.65);
      opacity: 0;
      @include transition(all $ani-speed-slower ease);

      a {
        display: block;
        width: 100%;
        height: 100%;
        color: $color-white;

        span {
          display: block;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 36px;
          border: 1px solid $color-white;
          margin: 0 5px;
        }

        &:hover {
          svg {
            color: $color-yellow;
          }
          border-color: $color-yellow;
        }
      }
    }

    &:hover {
      border-color: $color-grey-light;

      .wrapper-links {
        opacity: 1;
      }
    }
  }
}

.bg-pink {
  background: $color-pink;
}
.bg-yellow {
  background: $color-yellow;
}

.bg-grey {
  background: #f9f9fa;
}

.title-underline {
  text-transform: uppercase;
  font-family: $font-roboto-slab;
  font-weight: 600;
  margin: 0;
  margin-bottom: 15px;
  @include font-size(18px);
  padding-bottom: 15px;
  border-bottom: 1px solid $color-grey-light;
  position: relative;

  &:after {
    position: absolute;
    // width: 68px;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    content: '';
    background: $color-black;
  }
}

.blockquote-item {
  position: relative;
  padding: 10px 30px;

  p {
    position: relative;
    font-family: $font-roboto;
    font-weight: 300;
    font-style: italic;
  }

  span.quote-left,
  span.quote-right {
    position: absolute;
    font-family: $font-roboto;
    font-weight: 400;
    font-style: normal;
    @include font-size(60px);
    color: $color-yellow;
    line-height: 1;
    height: 22px;
  }
  span.quote-left {
    top: 0px;
    left: -30px;
  }

  span.quote-right {
    bottom: 0px;
    right: -30px;
  }

  .author {
    font-family: $font-roboto-slab;
    font-weight: 300;

    span {
      color: $color-grey;
    }
  }
}

.testimonial-item {
  position: relative;
  padding: 10px 30px 10px 140px;
  min-height: 140px;

  .image {
    position: absolute;
    width: 120px;
    top: 10px;
    left: 0;
    padding: 10px;
    background: $color-white;
    @include transition(all 0.1s ease-in-out);
    cursor: zoom-in;

    img {
      width: 100%;
    }

    &:hover {
      padding: 0;
    }
  }

  .details {
    p {
      font-family: $font-roboto;
      font-weight: 300;
      font-style: italic;
    }

    .author {
      font-family: $font-roboto-slab;
      font-weight: 300;

      span {
        color: $color-grey;
      }
    }

    .rating {
      height: 21px;
      color: $color-yellow;
    }
  }
}

.cta {
  border: 1px solid $color-black;
  padding: 10px;
  @include transition(all $ani-speed-slower ease);

  .cta-body {
    padding: 20px 40px;
    background: $color-black;
    color: $color-white;
    font-weight: 300;

    @each $h in h1, h2, h3, h4, h5, h6 {
      #{$h} {
        font-family: $font-roboto-slab;
        font-weight: 300;
      }
    }
  }

  &.cta-pink {
    border-color: $color-pink;

    .cta-body {
      background: $color-pink;
    }
  }
}

.featured {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  .details {
    position: absolute;
    bottom: 0;
    left: 10px;
    width: calc(100% - 20px);
    @include transform(translateY(10px));
    @include transition(all $ani-speed ease);

    .label {
      background: $color-pink;
      color: $color-white;
      text-transform: uppercase;
      padding: 5px 10px;
      font-family: $font-roboto;
      font-weight: 300;
      @include font-size(12px);
      display: inline-block;
    }

    .text {
      padding: 20px 10px;
      background: rgba(34, 34, 38, 0.7); // $color-black
      color: $color-white;
      font-family: $font-roboto-slab;
      font-weight: 300;
      @include font-size(18px);
      line-height: 1.2;

      a {
        color: $color-yellow;
      }
    }
  }

  &:hover {
    .details {
      @include transform(translateY(0px));
    }
  }
}

.with-border {
  border: 1px solid $color-yellow;
}

.pagination {
  justify-content: flex-end;

  .page-item {
    margin: 0 2px;
    font-weight: bold;

    .page-link {
      padding: 0.5rem 0.5rem;
      font-family: $font-roboto;
      @include font-size(16px);
      line-height: 1em;
      border: 0;

      &:hover {
        color: $color-white;
        background: $color-black;
        border-color: $color-black;
      }
    }

    .accelerator-link {
      text-decoration: underline;
    }
  }
  .page-item.active .page-link {
    border-color: $color-black;
    background: $color-white;

    &:hover {
      color: $color-white;
      background: $color-black;
    }
  }
}

#error_explanation {
  h2 {
    @include font-size(18px);
    font-family: $font-roboto;
  }
}

.container.mode-switch {
  display: flow-root;

  .btn-group-mode-switch {
    float: right;
    margin-top: 20px;
    margin-bottom: 20px;

    a.btn {
      font-size: 9px;
      padding: 1px 12px;
    }
  }
}

.sharethis-inline-share-buttons {
  z-index: 100 !important;
}

img {
  max-width: 100%;
}

.slick-slider {
  .slider-item a {
    display: block;
    width: 100%;
    height: 100%;
    outline: 0;

    img {
      max-width: 100%;
      width: 100%;
    }
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;

    margin: 0;
    padding: 1rem 0;

    list-style-type: none;

    li {
      margin: 0 0.25rem;
      cursor: pointer;

      &:hover button {
        background-color: #8b8787;
      }
    }

    button {
      display: block;
      width: 10px;
      height: 10px;
      padding: 0;
      cursor: pointer;

      border: solid 0.75px;
      border-color: #8b8787;
      border-radius: 100%;
      background-color: #ffffff;

      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: #8b8787;
    }
  }
}

.text-yellow {
  color: $color-yellow;
}

.modern-bold {
  font-family: 'FreightNeo Pro Medium';
  font-size: 20px;
  font-weight: bold;
}

.small-top-offset {
  margin-top: 30px !important;
}

.medium-top-offset {
  margin-top: 50px !important;
}

.tiny-top-offset {
  margin-top: 10px !important;
}

.link.bold {
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
}

.dropdown-toggle::after {
  vertical-align: 0;
  border-top: $caret-width * 2 solid;
  color: black !important;
}

.dropdown {
  .separator {
    position: absolute;
    right: 0;
    top: 14px;
    height: 17px;
    width: 1px;
    background: #4f4f4f;
  }
}

.text-wrapper,
.title-wrapper {
  .header.title {
    line-height: 1em;
    height: 1.5em;
    font-weight: normal;
  }
  .box {
    padding-bottom: 30px !important;
  }
}
