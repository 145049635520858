.shadow-line {
  width: 100%;
  height: 140px;
  padding-top: 5px;
  position: fixed;
  z-index: 0;
  top: 0;
  background: none;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);

  @media (max-width: 813px) {
    top: -30px;
  }

  @media (max-width: 600px) {
    top: -58px;
  }
}

.hiw-wrapper {
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000000;
    font-family: Utile Display Book;
  }

  p {
    color: #000000;
    font-family: Utile Display Light;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #343333;
    width: 250px;
    height: 37px;
    font: 15px/18px Utile Regular;
    color: white;
    margin: 0 auto 6px;

    &:hover {
      color: #ffc000;
      background-color: #242424;
      box-shadow: 0 0 2px black;
    }

    &--shorter {
      @extend .button;
      width: 185px;
      margin-bottom: 80px;
    }
  }

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 28px;
    h1 {
      font-size: 23px;
      position: relative;
      margin-bottom: 13px;
      font-family: Utile Display Book;
    }

    h1:after {
      position: absolute;
      left: 0;
      bottom: -5px;
      content: "";
      border-bottom: 1px solid #707070;
      width: 108%;
      margin: 0 auto;
      transform: translateX(-4%);
    }

    p {
      font-family: Utile Book;
      font-size: 17px;
    }

    p:nth-of-type(2) {
      display: none;
    }

    @media (max-width: 700px) {
      margin-bottom: 19px;
      p:nth-of-type(2) {
        display: block;
      }

      p:nth-of-type(1) {
        display: none;
      }

      h1 {
        font-size: 22px;
        margin-bottom: 8px;
        &:after {
          bottom: -3px;
        }
      }
      p {
        font-size: 15px;
      }
    }
  }

  .steps-carousel {
    margin-bottom: 60px;
    padding: 0 10%;

    .slick-dots {
      margin-top: 20px;

      button {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: white;
        border: 1px solid #8b8787;
        margin-right: 6px;
      }

      .slick-active {
        button {
          background-color: #8b8787;
        }
      }
    }

    .slick-track {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: space-evenly;
    }

    .box {
      flex-basis: 33.3%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0px 15px;

      br {
        line-height: 0;
      }

      h3 {
        font-size: 25px;
        font-family: Utile Display Book;
        margin-bottom: 10px;
      }

      .line {
        border-left: 1px solid black;
        height: 46px;
        margin-bottom: 9px;
        transform: translateX(50%);
      }

      svg {
        width: auto;
        height: 115px;
        margin-bottom: 28px;
      }

      h2 {
        font-size: 23px;
        font-family: Utile Display Book;
      }

      p {
        font-family: Utile Display Light;
        font-size: 16px;
        margin-bottom: 0px;
        line-height: 18px;

        strong {
          font-family: Utile Regular;
          font-weight: 500;
        }
      }
    }

    @media (max-width: 1100px) {
      padding: 0;
    }

    @media (max-width: 750px) {
      margin-bottom: 25px;

      .slick-dots {
        button {
          width: 8px;
          height: 8px;
        }
      }
    }

    @media (max-width: 575px) {
      .slick-dots {
        display: flex !important;
      }
    }

    @media (max-width: 500px) {
      .box {
        h3 {
          font: 20px/23px Utile Display Book;
        }

        .line {
          height: 36px;
          margin-bottom: 1px;
        }

        svg {
          height: 95px;
          margin-bottom: 23px;
        }
        h2 {
          font-size: 20px;
        }
      }

      .slick-dots {
        button {
          width: 6px;
          height: 6px;
        }
      }
    }
  }

  .two-ways-to-rent {
    width: 90%;
    margin: 0 auto 50px;

    &__head, &__body {
      width: fit-content;
      margin: auto;
    }

    &__head {
      font: 21.5px/24px Utile Display Book;
    }

    &__divider {
      width: 140px;
      border-bottom: 1px solid black;
      margin: 5px auto;
      transform: scaleY(0.5);
    }

    &__body {
      font: 15.5px/18px Utile Display Book;
    }

    @media (min-width: 835px) {
      &__head {
        font: 23px/26px Utile Display Book;
      }

      &__body {
        font: 17px/19px Utile Display Book;
      }
    }
  }

  #how-it-works-banner {
    padding-bottom: 4.6em;
    .container {
      max-width: 100%;
      margin: 0 auto;
      padding: 0;
    }
    .hiw-video-player{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000000e3;
      z-index: 9999;
      color: white;
      video {
        width: 100%    !important;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      span{
        position:absolute;
        top:1.5em;
        right:1.5em;
        background: none;
        padding: 0.5em;
        svg{
          color: white;
          font-size: 3em;
          cursor: pointer;
          transition: color .5s ease;
          &:hover{
            color: #6a6a6a;
          }
        }
      }
    }
    .hero {
      height: 454px;
      width: 100%;
      display: block;
      background-image: url("../../../images/app/how-it-works/hero-hiw-mobile.webp");
      background-size: cover;
      background-position: center;
    }
    .stripes {
      margin-top: -50px;
      .stripe-first {
        width: 100%;
        height: 50px;
        background: #FFA28DC7;
      }
      .stripe-second {
        width: 100%;
        height: 50px;
        background: #FFB6A6;
      }
    }

    .description {
      text-align: center;
      background: #FFECD8 0% 0% no-repeat padding-box;
      width: 95%;
      padding: 2em;
      margin-top: -6.5em;
      margin-left: 5%;
      .title {
        text-align: center;
        font: normal normal normal 34px/39px Utile Display Book;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        font-size: 27px;
        margin-bottom:0;
      }
      p {
        text-align: center;
        font: normal normal 300 18px/29px Utile Display Book;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        @media (max-width: 850px){
          .pdesc__second-line--mobile{
            display:block;
            margin-top: -0.3em;
          }
        }
      }
      #hiw-player-button {
        padding: 10px 30px;
        background: #ffffff 0% 0% no-repeat padding-box;
        text-align: center;
        font: normal normal normal 15px/18px Utile;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        border: none;
        &:hover {
          background: #7e7d7d;
          color: white;
        }
      }
    }

    .discover {
      text-align: center;
      margin: 4em auto 0;

      @media (max-width: 835px) {
        margin: 4em auto 4em;
      }

      .discoverTitle {
        width: 50%;
        margin: 0 auto 1em;

        @media (max-width: 835px) {
          width: 60%;
        }
      }

      h1 {
        font: normal normal normal 23px/26px Utile Display Book;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin: 0 auto;
        position: relative;
        width: fit-content;
        padding-bottom: 5px;
      }

      h1:after {
        font: normal normal normal 23px/26px Utile Display Book;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin: 0 auto;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #000;
        content: "";
      }

      p.refer {
        margin-top: 1em;
        font: normal normal normal 17px/19px Utile;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }

    @media (min-width: 835px) {
      #how-it-works-banner{
        padding-bottom: 3.6em;
      }
      .hiw-video-player{
        video{
          height: inherit;
        }
      }
      .hero {
        height: 570px;
        background-size: cover;
        background-image: url("../../../images/app/how-it-works/hero-hiw.webp");
        background-position: inherit;
      }
      .stripes {
        display: none;
      }
      .description {
        background: none;
        position: absolute;
        top: 204px;
        left: 0px;
        width: 510px;
        margin: 0;
        display: flex;
        flex-direction: column;
        .desc {
          left: 0px;
          width: 509px;
          height: 139px;
          background: #fdfbfa 0% 0% no-repeat padding-box;
          margin-left: -2em;
          opacity: 0.86;
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .title {
          padding-top: 0.5em;
          font-size: 34px;
          margin-bottom:1rem;
        }
        .pdesc {
          width: 378px;
          padding-bottom: 1em;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        p{
          font: normal normal 300 24px/29px Utile Display Book;
          .pdesc__second-line--desktop{
            display:block;
          }
        }
        #hiw-player-button {
          margin-top: 2em;
        }
      }
    }
  }

  hr {
    border-top: 1px solid black;
    width: 91%;
    transform: scaleY(0.5);
    margin-bottom: 30px;
  }

  .how-it-works {
    display: flex;
    background-color: #fcf7f2;
    margin-bottom: 85px;
    padding-top: 37px;
    justify-content: space-evenly;

    .images {
      flex-basis: 60%;
      position: relative;
      display: flex;
      align-items: flex-end;

      .hiw-img {
        background-image: url("../../../images/app/how-it-works/mid.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;

        @media (max-width: 1000px) {
          display: none;
        }
      }

      .hiw-img-mobile {
        background-image: url("../../../images/app/how-it-works/mid-mobile.webp");
        background-size: contain;
        background-repeat: no-repeat;
        display: none;
        min-height: 50vh;
        width: 100%;
        margin-right: 2rem;

        @media (max-width: 1000px) {
          display: block;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      flex-basis: 40%;
      flex-shrink: 1;
      overflow-x: hidden;
      justify-content: space-evenly;
      text-align: center;
      padding: 50px 0;

      .option-icon {
        width: 100%;
        height: 150px;
        margin-bottom: 15px;
        background-position: center;
        background-repeat: no-repeat;

        &--rbtd {
          @extend .option-icon;
          background-image: url("../../../images/app/how-it-works/rbtd-icon-mobile.webp");
        }

        &--sub {
          @extend .option-icon;
          background-image: url("../../../images/app/how-it-works/sub-icon-mobile.webp");
        }

        @media (min-width: 835px) {
          &--rbtd {
            background-image: url("../../../images/app/how-it-works/rbtd-icon.webp");
          }

          &--sub {
            background-image: url("../../../images/app/how-it-works/sub-icon.webp");
          }
        }
      }

      h6 {
        display: none;
      }

      h3 {
        font: 21px/25px Utile Display Book;
        margin-bottom: 16px;
      }

      ul {
        padding-left: 0;
        font: normal normal normal 16px/27px Utile Book;
        margin-bottom: 14px;

        li {
          list-style: none;

          &:before {
            content: "·";
            margin-right: 5px;
            font-size: 32px;
            vertical-align: bottom;
            line-height: 27px;
          }
        }
      }

      p {
        font: 14px/16px Utile Display Light;
        margin-bottom: 32px;

        a {
          text-decoration: underline;
        }
      }


      @media (max-width: 1250px) {
        h6 {
          margin-bottom: 0px;
        }
        p {
          margin-bottom: 16px;
        }
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column;
      padding-top: 18px;
      margin-bottom: 0;

      .content {
        padding: 30px 5% 37px;

        h3 {
          text-decoration: none;
        }

        h6 {
          display: block;
          font: 12px/18px Utile Display Light;
        }
      }
    }

    @media (max-width: 500px) {
      .content {
        h1 {
          height: 22px;
          font-size: 24px;
          margin: 0 auto 30px;

          &:after {
            height: 22px;
          }
        }

        h3 {
          font-size: 16px;
          line-height: 20px;
          padding-right: 8%;
          margin-bottom: 18px;
        }

        ul {
          font-size: 15px;
          line-height: 21px;

          li {
            &:before {
              line-height: 21px;
            }
          }
        }

        p {
          font-size: 17px;
          line-height: 19px;
          margin-bottom: 32px;
        }
      }
    }
  }

  .easy-rent {
    margin: auto;
    width: 90%;
    max-width: 960px;

    &__header {
      width: fit-content;
      margin: 0 auto 13px;
      padding-bottom: 7px;
      border-bottom: 1px solid gray;
      font: 23px/26px Utile Display Book;
      text-align: center;
    }

    &__content {
      margin: 0 auto 26px;
      text-align: center;
      max-width: 90vw;
      font: 17px Utile Display Book;
    }
  }

  .customer-testimonials {
    text-align: center;
    margin: 100px 0 -30px;
  }

  .bottom-text {
    width: 90%;
    max-width: 950px;
    margin-bottom: 100px;
    padding: 0;
  }
}
