@import '../admin/remark/mixins_extend/_px2rem';

$btn-animate-side-padding: pxToRem(8px) pxToRem(28px) !default;
$btn-animate-side-xs-padding: pxToRem(3px) pxToRem(14px) !default;
$btn-animate-side-sm-padding: pxToRem(6px) pxToRem(22px) !default;
$btn-animate-side-lg-padding: pxToRem(10px) pxToRem(33px) !default;
$btn-animate-vertical-icon-font-size: pxToRem(24px) !default;
$btn-animate-vertical-xs-icon-font-size: pxToRem(18px) !default;
$btn-animate-vertical-sm-icon-font-size: pxToRem(21px) !default;
$btn-animate-vertical-lg-icon-font-size: pxToRem(37px) !default;

.btn-animate {
  position: relative;
  overflow: hidden;

  span {
    display: block;
    width: 100%;
    height: 100%;
    transform: translate(0, 0);
  }

  &-side {
    padding: $btn-animate-side-padding;

    span {
      transition: transform .2s ease-out 0s;

      > .icon {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        opacity: 0;
        transition: opacity .2s ease-out 0s;
        transform: translate(-20px, -50%);
      }
    }

    &:hover {
      span {
        transform: translate(10px, 0);

        > .icon {
          opacity: 1;
        }
      }
    }

    &.btn-xs {
      padding: $btn-animate-side-xs-padding;

      span {
        > .icon {
          left: 5px;
        }
      }

      &:hover {
        span {
          transform: translate(8px, 0);
        }
      }
    }

    &.btn-sm {
      padding: $btn-animate-side-sm-padding;

      span {
        > .icon {
          left: 3px;
        }
      }

      &:hover {
        span {
          transform: translate(8px, 0);
        }
      }
    }

    &.btn-lg {
      padding: $btn-animate-side-lg-padding;

      span {
        > .icon {
          left: -6px;
        }
      }

      &:hover {
        span {
          transform: translate(14px, 0);
        }
      }
    }
  }

  &-vertical {
    span {
      transition: all .2s ease-out 0s;

      > .icon {
        position: absolute;
        top: -2px;
        left: 50%;
        display: block;
        font-size: $btn-animate-vertical-icon-font-size;
        transform: translate(-50%, -100%);
      }
    }

    &:hover {
      span {
        transform: translate(0, 150%);
      }
    }

    &.btn-xs {
      span {
        > .icon {
          top: -5px;
          font-size: $btn-animate-vertical-xs-icon-font-size;
        }
      }
    }

    &.btn-sm {
      span {
        > .icon {
          top: -3px;
          font-size: $btn-animate-vertical-sm-icon-font-size;
        }
      }
    }

    &.btn-lg {
      span {
        > .icon {
          font-size: $btn-animate-vertical-lg-icon-font-size;
        }
      }
    }
  }
}

.btn {
  &.bold.btn-outline-primary {
    font-weight: bold !important;
    border-width: 2px;
  }

  &.bold {
    font-weight: bold !important;
  }

  &.is-block {
    display: block;
  }

  &.compact {
    display: inline-block;
    padding: 0.2em 1rem;
    height: 2em;
    font-size: 16px;
    line-height: 1.3em;
  }
}
