.become-vendor-hero-wrapper {
  width: 100%;

  @media (max-width: 835px) {
    margin-bottom: 90px;
  }

  & .hero {
    height: 532px;
    width: 100%;
    display: flex;
    background-image: url("../../../images/app/become-vendor/hero-thumbnail.webp");
    background-position: center;
    background-size: cover;

    @media (max-width: 835px) {
      background-image: url("../../../images/app/become-vendor/hero-mobile.webp");
    }

    & .play-video-icon {
      display: block;
      margin: auto;
      cursor: pointer;

      @media (max-width: 835px) {
        width: 0;
        height: 0;
        padding: 61px;
        background: url("../../../images/app/become-vendor/PlayIcon.webp");
      }
    }
  }

  & .stripes {
    margin-top: -50px;
    width: 100%;
    height: 100px;
    background: #54D094C7;

    @media (min-width: 835px) {
      display: none;
    }
  }

  & .description-container {
    position: absolute;
    text-align: center;
    top: 196px;
    left: 0;
    width: 510px;
    margin: 0;

    @media (max-width: 835px) {
      top: 492px;
      left: initial;
      right: 0;
      width: calc(100vw - 5%);
      background: #DBFACD;
      padding-bottom: 45px;
    }

    & .description {
      width: 100%;
      height: 140px;
      margin-bottom: 15px;
      background: #e7e7e7 0% 0% no-repeat padding-box;
      opacity: 0.86;

      @media (max-width: 835px) {
        height: 110px;
        background: none;
        opacity: 1;
      }

      & .text-top {
        text-align: center;
        padding-top: 0.5em;
        font: normal normal normal 34px/39px Utile Display Book;
        margin-bottom: 1rem;

        @media (max-width: 835px) {
          font-size: 27px;
          line-height: 30px;
        }
      }

      & .text-bottom {
        text-align: center;
        margin: 0 auto;
        width: 378px;
        font: normal normal 300 24px/29px Utile Display Book;

        @media (max-width: 835px) {
          width: 250px;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

    & .open-player {
      padding: 10px 30px;
      background: #fff 0% 0% no-repeat padding-box;
      text-align: center;
      font: normal normal normal 15px/18px Utile;
      letter-spacing: 0;
      display: flex;
      justify-content: center;

      @media (max-width: 835px) {
        padding-top: 2px;
        padding-bottom: 0;
        height: 30px;
      }

      & .open-player-text {
        margin-top: 2px;

        @media (max-width: 835px) {
          min-width: 220px;
        }
      }

      & .open-player-icon {
        margin-left: 20px;

        @media (max-width: 835px) {
          width: 18px;
          margin-left: 0;
        }
      }
    }

    & .link-wrapper {
      display: grid;
      width: 75%;
      margin: auto;

      @media (max-width: 835px) {
        min-width: 280px;
      }
    }
  }
}

.become-vendor-player-wrapper {
  background: rgba($color: #000000, $alpha: 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  & .close-video-player {
    position: absolute;
    right: 2em;
    top: 2em;
    color: white;
    cursor: pointer;
    transform: scale(2);
  }

  & .become-vendor-video {
    position: absolute;
    left: 50%;
    top: 50%;
    height: inherit;
    width: 100%;
    transform: translate(-50%,-50%);
  }
}

.probs-wrapper {
  padding: 60px 0;

  .col-content {
    text-align: center;
    font-family: Utile Regular;
    font-size: 20px;
    img {
      height: 160px;
    }

    .text {
      margin-top: 15px;
    }
  }
}

.proposal-wrapper {
  background: $color-cream;
  padding: 60px 0;
  .col-white {
    background: $color-white;
    padding: 30px;
    font-family: Utile Regular;
    font-size: 26px;
    text-align: right;
    p {
      margin-bottom: 0;
    }
  }

  .col-text {
    .text-wrapper {
      margin: 10px 30px;

      .title {
        font-family: Utile Regular;
        font-size: 46px;
        color: $color-black;
        line-height: 1.2em;
        margin-bottom: 15px;
        span {
          color: $color-pink-light;
        }
      }
    }
  }
}

.vendor-btn {
  background: #343333;
  padding: 7px 20px;
  font-family: Utile Regular !important;
  font-size: 15px;
  color: white !important;
  &:hover {
    background: #7e7d7d 
  }
}

.reality-check-wrapper {
  padding: 30px 0;

  .col-image {
    padding: 0;
  }

  .col-text {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      background: #f4f4f4;
      padding: 60px;

      p {
        font-size: 26px;
      }

      .title {
        font-family: Utile Regular;
        font-size: 36px;
        color: #a96e33;
        line-height: 1.2em;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;
        font-weight: normal;

        span {
          color: $color-black;
        }

        &:before, &:after {
          display: none !important;
          font-family: Utile Regular;
          color: #F9C7D0;
          position: absolute;
          font-size: 150px;
          line-height: 60px;
          z-index: -1
        }

        &:before {
          content: '“';
          top: 20px;
          left: -30px;
        }

        &:after {
          content: '”';
          bottom: -60px;
          right: 100px;
        }
      }
    }
  }
}

.become-vendor-overview-wrapper {
  margin: 60px 0;

  .box {
    width: 100%;
    border: 1px solid $color-black;
    padding: 40px;
    position: relative;
    font-family: Utile Regular;
    font-size: 26px;
    text-align: center;

    p {
      margin-bottom: 0;
    }
  }


  .title {
    position: absolute;
    top: -36px;
    left: 0;
    right: 0;
    width: 250px;
    margin: auto;
    padding-top: 10px;
    text-align: center;
    font-family: Utile Regular;
    font-size: 46px;
    background: $color-black;
    color: $color-white;
  }

  .text-wrapper {
    .container {
      max-width: 90%;
    }

    margin-top: -30px;
    background-image: url('~images/app/become-vendor/Vendor-WhiteBG.webp');
    background-position: center;
    background-size: cover;
    min-height: 400px;
    padding: 80px 0;

    .col-image {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 140%;
        max-width: initial;
      }
    }

    .col-text {
      font-family: Utile Regular;
      font-size: 18px;
      color: #5F5F5F;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        padding: 0 15px;
      }

      strong {
        font-family: Utile Regular;
        color: $color-black;
      }

      p {
        margin-bottom: 30px;

        &.go-right {
          margin-left: 60px;
        }
      }
    }
  }
}

.become-vendor-btn {
  border: solid 1px black;
  padding: 5px 10px;
  font-family: Utile Regular;
  &:hover {
    color: white !important;
    background: black;
  }
}

.vendor-how-it-works-wrapper {
  margin: 60px 0;
  .title {
    font-family: Utile Regular;
    font-size: 46px;
    color: $color-black;
    text-align: center;
  }
  .col-content {
    text-align: center;
  }

  img {
    width: 80%;
    margin-bottom: 15px;
  }
  p {
    font-family: Utile Regular;
    font-size: 18px;
  }
}

.vendors-testimonials-wrapper {
  .title {
    font-family: Utile Regular;
    font-size: 23px;
    color: $color-black;
    text-align: center;
    background: $color-cream;
    padding: 30px 0 100px 0;
  }

  .wrapper-content {
    margin-top: -60px;
    margin-bottom: 30px;

    .item {
      background: #FCFCFC;
      padding: 60px 45px;
      font-family: Utile Regular;
      font-size: 16px;

      .text {
        color: #5F5F5F;
        margin-bottom: 30px;
        position: relative;
        &:before {
          font-family: Utile Regular;
          color: #DFDFDF;
          position: absolute;
          font-size: 80px;
          content: '”';
          top: -90px;
          left: -30px;
        }
      }

      .author, .area {
        text-align: right;
        color: $color-pink-light;
        font-family: Utile Regular;
        font-weight: bold;
      }
      .area {
        color: $color-black;
      }
    }
  }
}


.vendor-form-container {
  padding-top: 55px;
  font-family: Utile Regular !important;

  h2 {
    font-family: Utile Regular !important;
  }
}


















//

img.first-pic {
  display: none;
}

.reality-check-wrapper .col-image img.desktop-girl-computer-img {
  display: block;
}
.vendor-intro-wrapper, .probs-wrapper, .vendor-how-it-works-wrapper, .vendors-testimonials-wrapper  {
  .mobile-view-element{
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .probs-wrapper, .vendor-how-it-works-wrapper, .vendors-testimonials-wrapper {
    .mobile-view-element {
      display: block;
    }
    .desktop-view-element {
      display: none;
    }
  }
  .vendor-intro-wrapper {
    background-image: none;
    display: block;
    height: auto;
    .mobile-top-image {
      display: block;
    }
    .desktop-top-image {
      display: none;
    }
  }

  img.first-pic {
    display: block;
    width: 100%;
  }

  .vendor-how-it-works-wrapper{
    margin-top: 30px;
    margin-bottom: 30px;
    .title {
      font-family: "Lust", regular;
      font-size: 33px;
      line-height: 41px;
      color: #000000;
    }
    .mobile-owl-carousel{
      background: none;
    }
    a.btn.btn-outline-primary.bold.compact {
      font: Bold 15px/21px FreightNeo Pro;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      background: #FFFFFF;
      margin-top: 15px;
      padding: 2px 20px;
      border: 2px solid #000000;
    }
  }

  .probs-wrapper .col-content .text {
    margin-top: 20px;
    font-weight: 600;
    line-height: 23px;
    color: #000000;
    font-size: 20px;
    font-family: "freight-neo-pro";
  }

  .vendor-intro-wrapper .text-wrapper {
    .box {
      width: 80%;
      padding: 43px 15px;
      font-size: 20px;
      text-align: center;
      margin: 0 auto;
      text-align: center;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin-top: 65px;
    }

    .title {
      background: #000000;
      line-height: 31px;
      width: 206px;
      font-size: 33px;
      height: 50px;
    }
  }

  .proposal-wrapper {
    .row {
      margin: 0 auto;
    }

    .col-white {
      background: #fff;
      padding: 30px 30px;
      font-family: FreightNeo Pro Medium,serif;
      font-size: 26px;
      text-align: right;
      margin: 0 auto;
      width: 90%;
      margin-left: 0;
      margin-top: 15px;

      p {
        text-align: right;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        font-family: "freight-neo-pro";
        font-size: 20px;
        line-height: 23px;
        font-weight: 600;
      }
    }

    .col-text .text-wrapper {
      .title {
        font-family: Lust,cursive;
        font-size: 33px;
        color: #c04f79;
        line-height: 1.2em;
        margin-bottom: 15px;
        letter-spacing: 0;
        text-align: left;
        letter-spacing: 0px;
        text-transform: lowercase;
      }

      a.btn.btn-primary.bold.compact {
        text-align: center;
        font: Bold 15px/21px FreightNeo Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        background: #000000;
        margin-top: 15px;
        padding: 2px 10px;
      }
    }

    padding-bottom: 40px;
    padding-top: 30px;
  }

  .reality-check-wrapper {
    .col-image {
      img.desktop-girl-computer-img {
        display: none;
      }

      margin: 0 auto;
      width: 80%;
    }

    .col-text {
      .wrapper {
        p {
          font-size: 20px;
          letter-spacing: 0px;
          color: #000000;
          font-family: "freight-neo-pro";
          width: 205px;
          line-height: 28px;
          font-weight: 600;
        }

        .title {
          font-family: "Lust", regular;
          font-size: 33px;
          color: #B77737;
          position: relative;
          z-index: 1;
          line-height: 41px;
          margin-bottom: 30px;
          &:after, &:before{
            display: none;
          }
        }

        .reality-check-text {
          letter-spacing: 1px;
        }

        padding: 25px 40px;
        width: 80%;
        margin: 0 auto;
        padding-left: 0;
        padding-top: 190px;
        background-image: none;
      }

      background: #f4f4f4;
      margin-top: -159px;
      z-index: -9;
    }
  }

  .become-vendor-overview-wrapper {
    margin-bottom: 30px;
    margin-top: 40px;
    .title {
      background: #000000;
      font-size: 40px;
      line-height: 25px;
      height: 50px;
      top: -25px;
    }

    .box p {
      margin-bottom: 0;
      text-align: center;
      letter-spacing: 0px;
      font-family: "freight-neo-pro";
      font-size: 20px;
      line-height: 25px;
      color: #000000;
      font-weight: 500;
    }

    .text-wrapper {
      .col-image {
        align-items: start;
      }
      .col-text p {
        margin-bottom: 23px;
        font-size: 15px;
      }
      .col-text p.go-right {
        margin-left: 0;
      }

      .container {
        max-width: 100%;
      }

      .col-image img {
        width: 100%;
        max-width: none;
      }

      .col-text > div {
        padding: 0;
      }
    }
  }

  .vendor-intro-wrapper .text-wrapper .box p {
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-family: "freight-neo-pro";
    font-weight: 600;
  }

  .become-vendor-overview-wrapper .text-wrapper {
    .col-text {
      strong {
        color: #000000;
      }

      a.btn.btn-outline-primary.bold.compact {
        margin-top: -20px;
        font-size: 15px;
        line-height: 21px;
        color: #000000;
        background-color: transparent;
        padding: 0px 20px;
        font-weight: 600;
        font-family: "freight-neo-pro";
        border: 2px solid #000000;
      }
    }

    padding-bottom: 60px;
  }
  .vendors-testimonials-wrapper .title {
    font-size: 33px;
    line-height: 41px;
    color: #000000;
  }
  .vendors-testimonials-wrapper .wrapper-content .item {
    width: 100%;
    margin: 0 auto;
  }
  .vendors-testimonials-wrapper .mobile-owl-carousel {
    margin-top: -65px;
    background: transparent;
    padding-bottom: 0px;
  }
  .vendor-how-it-works-wrapper p {
    font-family: "FreightNeo Pro Medium", serif;
    font-size: 18px;
    width: 85%;
    margin: 0 auto;
    color: #000;
    line-height: 23px;
  }
  .vendors-testimonials-wrapper .wrapper-content .item .text:before {
    font-size: 100px;
    top: -96px;
    left: -30px;
    line-height: 124px;
  }
  .vendor-how-it-works-wrapper .mobile-owl-carousel, .vendors-testimonials-wrapper .mobile-owl-carousel{
    padding-bottom: 0;
    padding-top: 5px;
    margin: 0;
  }

  .vendor-how-it-works-wrapper, .vendors-testimonials-wrapper {
    button.owl-prev.disabled, button.owl-next.disabled {
      display: none;
    }
    .slideDown img {
      width: 60%;
      margin: 0 auto;
    }
    button.owl-prev {
      position: absolute;
      top: 30%;
      bottom: 58%;
      left: 1%;
      span{
        background-image: url("~images/app/left-arrow.webp");
        color: transparent !important;
        background-repeat: no-repeat;
        background-size: 100%;
        font-size: 66px;
      }
    }
    button.owl-next {
      position: absolute;
      top: 30%;
      bottom: 58%;
      right: 1%;
      span{
        background-image: url("~images/app/right-arrow.webp");
        color: transparent !important;
        background-repeat: no-repeat;
        background-size: 100%;
        font-size: 66px;
      }
    }
  }
  .proposal-wrapper .text-wrapper, .become-vendor-overview-wrapper .col-text,
  .vendor-how-it-works-wrapper .text-center {
    a.btn {
      height: auto;
      &:hover {
        color: #c04f79 !important;
      }
    }
  }
}
.hiw-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 28px;
  h1 {
    font-size: 23px;
    position: relative;
    margin-bottom: 13px;
    font-family: Utile Display Book;
  }

  h1:after {
    position: absolute;
    left: 0;
    bottom: -5px;
    content: "";
    border-bottom: 1px solid #707070;
    width: 108%;
    margin: 0 auto;
    transform: translateX(-4%);
  }
}

@media only screen and (max-width: 400px){
.proposal-wrapper .col-text .text-wrapper .title {
    font-size: 24px;
    line-height: 34px;
  }
}

@media only screen and (max-width: 405px) {
  .proposal-wrapper {
    .col-white {
      padding: 30px 18px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .vendor-intro-wrapper .text-wrapper .box {
    width: 85%;
  }
  .proposal-wrapper {
    .col-white {
      padding: 30px 6px;
      width: 93%;
      p {
        font-size: 18px;
      }
    }
  }
}
