.collections-container {
  padding-top: 10px;

  .featured-collections {
    .title {
      width: 100%;
      height: 213px;
      position: absolute;
      background: #FFEBDE;
      padding-top: 40px;

      h1 {
        text-align: center;
        font: normal normal normal 24px/28px Utile Display Book;
        letter-spacing: 0px;
        color: #000000;
      }
      
      .underline {
        width: 95%;
        border-bottom: solid 1px #c36300;
        margin: auto;
      }
    }

    .collections-carousel {
      padding: 100px 60px 0;
      margin-bottom: 50px;

      &:hover {
        .slick-next,
        .slick-prev {
          opacity: 1;
        }
      }

      .collection {
        padding: 0 10px;

        a {
          .collections-carousel__item {
            width: 100%;
            min-height: 437px;
            border: 1px solid black;
            background-color: white;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @media(max-width: 820px) {
              min-height: 318px;
            }
          }

          .name {
            margin-top: -40px;
            padding-bottom: 30px;
            font: normal normal normal 16px/18px Utile;
          }

          &:hover {
            color: #707070;
          }
        }
      }

      .slick-next,
      .slick-prev {
        position: absolute;
        background-image: url("~images/app/subscriptions/chevron");
        background-size: cover;
        background-color: transparent;
        border: none;
        font-size: 0px;
        width: 49px;
        height: 49px;
        top: calc(50% + 25px);
        opacity: 0;
        transition: all 0.5s;
      }

      .slick-prev {
        transform: rotate(-180deg);
        left: 10px;
      }

      .slick-next {
        right: 10px;
      }
    }
  }

  .collections-text {
    text-align: center;
    font-family: Utile Display Book;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 104px;
    padding: 0px 16.5%;

    @media (max-width: 900px) {
      padding: 0px 34px;
      margin-bottom: 70px;
      font-size: 21px;
      line-height: 24px;
    }
  }

  .collections-banner {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    height: 719px;
    background: #f5f4f0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 185px;

    @media (max-width: 820px) {
      flex-direction: column;
      height: 939px;
      position: relative;
      margin-bottom: 100px;
    }

    .text-container {
      width: 300px;

      @media (max-width: 820px) {
        width: 195px;
      }

      h1 {
        margin-bottom: 20px;
        font-size: 25px;
        line-height: 29px;
        font-family: Utile Display Book;
        color: black;

        @media (max-width: 820px) {
          font-size: 20px;
          line-height: 23px;
          margin-bottom: 12px;
        }
      }

      p {
        text-align: left;
        font: normal normal 300 16px/18px Utile;
        letter-spacing: 0px;
        color: black;

        @media (max-width: 820px) {
          font: normal normal 300 13px/15px Utile;
          margin-bottom: 10px;
        }
      }

      a {
        text-align: left;
        font: normal normal normal 14px/16px Utile;
        letter-spacing: 0px;
        color: #000000;
        border-bottom: 1px solid #7a5a3a;
        padding-bottom: 4px;

        @media (max-width: 820px) {
          font: normal normal normal 12px/14px Utile;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .left-col {
      width: 551px;
      height: 774px;
      background-image: url("~images/app/collections/collection-designer.webp");
      background-position: center;
      background-size: cover;
      margin-left: 64px;
      margin-right: 48px;
      transform: translateY(-55px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      @media (max-width: 820px) {
        width: 320px;
        height: 421px;
        margin: 0 auto;
        transform: translateY(-25px);
        background-image: url("~images/app/collections/collection-designer-mobile.webp");
      }

      @media (max-width: 350px) {
        width: 90%;
      }

      .text-container {
        width: 270px;
        margin-bottom: 72px;
        margin-right: 30px;

        h1, p, a {
          color: white;
        }

        a {
          border-color: #917188;
        }

        @media (max-width: 820px) {
          width: 180px;
          margin-bottom: 38px;
          margin-right: 0;

          h1 {
            font: 20px/23px Utile Display Book;
          }
          
          p {
            font: 13px/15px Utile Display Book;
          }

          a {
            font: 12px/14px Utile Display Book;
          }
        }
      }
    }

    .right-col {
      width: 718px;
      height: 774px;
      margin-right: 59px;
      display: flex;
      flex-direction: column;

      @media (max-width: 820px) {
        height: 490px;
        width: 100%;
        margin-right: 0;
        position: absolute;
        right: 0;
        bottom: -20px;
        background: #EDDFD3;
      }

      .top-box,
      .bottom-box {
        width: 100%;
        height: 384px;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        transform: translateY(-35px);
      }

      .top-box {
        background-image: url("~images/app/collections/collection-couture.webp");

        @media (max-width: 820px) {
          background-image: url("~images/app/collections/collection-couture-mobile.webp");
          width: 347px;
          height: 245px;
        }
      }

      .bottom-box {
        background-image: url("~images/app/collections/collection-diamond.webp");
        margin: auto;

        @media (max-width: 820px) {
          background-image: url("~images/app/collections/collection-diamond-mobile.webp");
          width: 321px;
          height: 227px;
          display: block;

          .text-container {
            margin-top: 30px;
          }
        }
      }
    }

    .layered-necklaces,
    .stackable-rings {
      margin-left: 49px;

      @media (max-width: 820px) {
        margin-left: 23px;
      }
    }

    .stackable-rings {
      h1, p, a {
        color: white;
      }

      a {
        border-bottom: 1px solid #cf9da5;
      }
    }
  }

  .ring-container {
    width: 100%;
    height: 428px;
    background: #eddfd3;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 820px) {
      flex-direction: column;
      height: 475px;
      position: relative;
    }

    .hoop-earrings {
      width: 551px;
      height: 473px;
      margin-left: 64px;
      margin-right: 48px;
      background-image: url("~images/app/collections/collection-hoop.webp");
      background-position: center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      .description {
        width: 213px;
        color: white;
        margin-right: 32px;
        
        a {
          color: white;
          border-bottom: 1px solid white;
        }
      }

      @media (max-width: 820px) {
        background-image: url("~images/app/collections/collection-hoop-mobile.webp");
        width: 347px;
        height: 302px;
        position: absolute;
        margin: 0;
        right: 0;
        top: -40px;
        display: block;

        .description {
          position: absolute;
          width: 180px;
          margin: 0;
          right: 0;
          bottom: 40px;
        }
      }
    }

    .cocktail-rings {
      width: 718px;
      height: 385px;
      background: pink;
      margin-right: 59px;
      background-image: url("~images/app/collections/collection-rings.webp");
      background-position: center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .description {
        width: 300px;
        color: black;
        margin-left: 49px;
        margin-top: 132px;
      }

      @media (max-width: 820px) {
        background-image: url("~images/app/collections/collection-rings-mobile.webp");
        width: 321px;
        height: 238px;
        position: absolute;
        margin: 0;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        display: block;

        .description {
          width: 156px;
          position: absolute;
          bottom: 30px;
          margin-left: 20px;
          margin-top: auto;
        }
      }

      @media (max-width: 321px) {
        width: 100%;
      }
    }

    h1 {
      font: normal normal normal 23px/26px Utile Display Book;

      @media(max-width: 820px) {
        font: 21.5px/24px Utile Display Book;
      }
    }

    p {
      font: normal normal 300 16px/18px Utile;

      @media(max-width: 820px) {
        font: 14px/16px Utile Display Book;
      }
    }

    a {
      width: fit-content;
      font: normal normal normal 14px/16px Utile;
      border-bottom: 1px solid;
      padding-bottom: 7px;

      @media(max-width: 820px) {
        font: 12px/14px Utile Display Book;
      }

      &:hover {
        opacity: 0.7;
        color: black;
      }
    }
  }
}
