body {
  overflow-x: hidden;
}

.navbar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 140px;
  padding-top: 5px;
  // padding-bottom: ?;
  position: fixed;
  z-index: 998;
  top: 0;
  background-color: white;
  color: black;

  &.sps--blw {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  }

  .search-box {
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s, visibility 1s 1s;
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    top: 140px;
    left: 0px;
    width: 100%;
    height: 452px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.05);

    #search-close {
      position: absolute;
      top: 45px;
      right: 44px;
      height: 26px;
      width: 26px;
      cursor: pointer;
    }

    form {
      width: 70%;
      display: flex;
      justify-content: center;
      height: 78px;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);

      input {
        height: 100%;
        flex: 1;
        font-family: "Utile Book";
        font-size: 30px;
        padding-left: 35px;
        border: none;

        &::placeholder {
          opacity: 1;
          color: #575555;
          content: "blablabla";
        }

        &:focus {
          box-shadow: none;
        }
      }

      button {
        height: 100%;
        background-color: white;
        border: none;

        &:hover {
          fill: #ffc000;
        }

        svg {
          width: 32px;
          height: auto;
          margin-right: 21px;
        }
      }
    }

    @media (max-width: 813px) {
      top: 110px;
      height: 58vh;

      #search-close {
        width: 20px;
        height: 20px;
        top: 16px;
        right: 16px;
      }
    }

    @media (max-width: 600px) {
      top: 81px;

      #search-close {
        width: 18px;
        height: 18px;
      }

      form {
        height: 49px;
        margin-bottom: 6%;
        input {
          font-family: "Utile Display Light";
          font-size: 17px;
        }

        button svg {
          width: 19px;
          margin-right: 10px;
        }
      }
    }
  }

  .logo-hamb-box {
    width: 327px;
    height: auto;
    margin-left: 40px;
    display: flex;

    #hamburger {
      display: none;
      margin-right: 24px;
      cursor: pointer;
      width: auto;
      height: 30px;
      transform: translateY(-2px);

      @media (max-width: 813px) {
        display: block;
      }

      @media (max-width: 600px) {
        height: 23px;
        transform: translateY(-1px);
      }
    }

    svg {
      width: 60%;
      height: 100%;
    }

    @media (max-width: 1100px) {
      width: 180px;
      margin-left: 30px;

      svg {
        width: 100%;
        padding-top: 8px;
      }
    }

    @media (max-width: 815px) {
      svg {
        padding-top: 0px;
      }
    }
  }

  .contents {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 57px;

    .row-one {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 35px;

      a {
        font-family: "Utile Regular";
        line-height: 16px;
        font-size: 14px;
        margin-right: 27px;
      }

      #register-logout a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 141px;
        margin: 0 auto;
        color: white;
        background-color: #343333;
        margin-right: 27px;

        &:hover {
          color: white;
          background-color: #7e7d7d;
          box-shadow: 0 0 2px black;
        }
      }

      #my-acc,
      #register-logout,
      #acc-name {
        @media (max-width: 813px) {
          display: none;
        }
      }

      #acc-name {
        display: block;
        text-transform: capitalize;

        @media (max-width: 813px) {
          visibility: hidden;
          width: 0px;
          margin-right: 0px;
          height: 0px;
        }
      }

      .search,
      .fav,
      #shop,
      #shop-register {
        cursor: pointer;
        width: 18px;
        height: 18px;
        margin-right: 32px;

        svg {
          width: 100%;
          height: 100%;
          &:hover {
            fill: #ffc000;

            .heartsvg {
              stroke: #ffc000;
            }
          }

          .cls-99:hover {
            fill: #ffc000;
          }
        }
      }

      #shop,
      #shop-register {
        width: 24px;
        height: 20px;
        transform: translateY(-1px);
      }

      #shop-register {
        transform: translateY(-3px);
      }

      .search {
        transform: translateY(-2px);
      }
    }

    .row-two {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 36px;

      @media (max-width: 813px) {
        display: none;
      }

      .hiw,
      .testimonials-link,
      .collections {
        position: relative;
        text-align: center;
        a {
          margin-bottom: 10px;
        }

        #hiw-link {
          padding-bottom: 15px;
        }

        .hiw-menu {
          display: block;
          opacity: 0;
          position: absolute;
          z-index: 1;
          bottom: -69px;
          background-color: white;
          border-radius: 3px;
          min-width: 120px;
          left: -17px;
          visibility: hidden;
          transition: all 0.5s ease-in-out;
          a {
            display: block;
            margin-right: 0px;
          }
          a:hover {
            color: #7e7d7d;
          }
          @media (max-width: 900px) {
            bottom: -59px;
          }
        }

        &:hover {
          .hiw-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      a {
        margin-right: 35px;
        font-family: "Utile Regular";
        font-size: 13px;
      }
    }
  }

  #mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    min-height: 100vh;
    max-height: 100vh;
    overflow: scroll;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid black;
    z-index: 999;
    padding: 60px 0px 20px 30px;
    transition: all 1s;
    top: -120vh;

    .mobile-links {
      display: flex;
      flex-direction: column;
      width: 100%;

      a {
        font-family: "Utile Medium";
        font-size: 14px;
        margin-bottom: 26px;
      }

      .chevron a:after {
        display: inline-block;
        content: "";
        background-image: url("~images/app/header/chevron-right");
        background-size: contain;
        background-repeat: no-repeat;
        height: 16px;
        width: 16px;
        vertical-align: middle;
        margin-left: 10px;
        transform: translateY(-1px);
      }

      #acc-name-mobile {
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      #register-logout-mobile a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 37px;
        width: 151px;
        color: white;
        background-color: #343333;
      }

      #my-acc-mobile {
        margin-bottom: 30px;
      }
    }

    #close-nav {
      position: absolute;
      top: 18px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-bottom: 40px;
    }

    @media (max-height: 400px) {
      margin-bottom: 0px;
    }
  }

  @media (max-width: 1100px) {
    .contents {
      margin-right: 16px;
      .row-two a {
        margin-right: 25px;
      }
    }
  }

  @media (max-width: 900px) {
    .contents .row-two a {
      font-size: 12px;
      margin-right: 16px;
      .contents .row-two {
        .hiw .hiw-menu {
          left: -22px;
        }
        a {
          font-size: 12px;
          margin-right: 16px;
        }
      }
    }

    .occasion .occasion-menu {
      bottom: -149px;
      left: -20px;
    }

    .jewelry .jewelry-menu {
      bottom: -146px;
      left: -24px;
    }
  }

  @media (max-width: 813px) {
    align-items: flex-end;
    height: 110px;
    padding-top: 10px;
    padding-bottom: 10px;

    .contents .row-one {
      padding-top: 0px;
      padding-bottom: 6px;

      #shop,
      #shop-register {
        margin-right: 0px;
      }
    }

    .logo-hamb-box {
      align-items: flex-end;
      width: auto;

      .logo svg {
        width: auto;
        height: 90px;
      }
    }
  }

  @media (max-width: 600px) {
    height: 81px;
    padding-top: 9px;
    padding-bottom: 9px;

    .logo-hamb-box {
      margin-left: 15px;
      .logo svg {
        width: auto;
        height: 63px;
      }
    }

    .contents .row-one {
      padding-bottom: 3px;
    }
  }

  @media (max-width: 500px) {
    .contents .row-one .search,
    .contents .row-one .fav {
      margin-right: 25px;
    }
  }

  @media (max-width: 325px) {
    .contents .row-one .search,
    .contents .row-one .fav {
      margin-right: 15px;
    }
  }
}

.overlay-close {
  transition: all 1s ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.246);
  z-index: 998;
  left: 0;
  top: 0;
}

#cart-container {
  color: black;
  position: fixed;
  overflow-y: auto;
  max-height: 100vh;
  width: 600px;
  top: 0;
  z-index: 1000;
  background-color: white;
  height: max-content;
  border: 1px solid #98979757;
  padding-top: 38px;
  right: -100vw;
  transition: all 0.8s ease-in-out;

  #length-var {
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin: 0;
  }

  .empty {
    text-align: center;
    font: normal normal normal 28px/32px "Utile Display Book";
    margin-bottom: 20px;
  }

  .cart-main-info {
    position: relative;

    #arrow-hide {
      position: absolute;
      top: 50%;
      left: 36px;
      transform: rotate(180deg) translateY(50%) scaleY(1.1);
      cursor: pointer;
      width: 46px;
      height: auto;
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  .cart-rbtd {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 127px;
    height: 22px;
    color: #fff;
    font: normal normal normal 13px/14px "Utile Regular";
    background-color: #9f1353;
    margin: 0 auto 30px;
  }

  .cart-title {
    text-align: center;
    font: normal normal normal 28px/32px "Utile Display Book";
    margin-bottom: 18px;
  }

  .pieces-left {
    font: normal normal normal 18px/20px "Utile Display Light";
    text-align: center;
    margin-bottom: 27px;

    span {
      color: #9f1353;
    }
  }

  .cart-items {
    display: flex;
    flex-direction: column;
    background-color: rgba(126, 123, 124, 0.123);
    margin-bottom: 27px;
    padding: 35px 37px 0;

    a,
    p,
    h1,
    h2,
    h3,
    h4 {
      color: black;
    }

    a:hover {
      color: #ffc000;
    }

    .cart-product {
      display: flex;
      margin-bottom: 25px;
      .cart-image {
        background-color: white;
        margin-right: 31px;
        max-height: 163px;
        a img {
          width: 163px;
        }
      }
      .cart-product-details {
        flex: 1;
        position: relative;
        padding-right: 25px;
        .product-price-other {
          display: flex;
          justify-content: space-between;
        }

        .delete-link {
          display: flex;
          height: 15px;
          width: 15px;
          position: absolute;
          top: 0;
          right: 0;
          .delete-svg {
            width: 100%;
            stroke-width: 4px;
            height: auto;
          }
        }

        .brand,
        .title,
        .other {
          font: normal normal normal 14px/16px "Utile Book";
        }

        .brand {
          margin-bottom: 0;
        }

        .title {
          margin-bottom: 18px;
        }

        .price {
          font: normal normal normal 14px/25px "Utile Book";
          min-width: max-content;
          word-spacing: -2px;
        }

        .other {
          padding-right: 10px;
        }
      }
    }

    .empty-cart-product {
      margin-bottom: 25px;
      display: flex;
      .empty-image {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 163px;
        height: 163px;
        margin-right: 31px;
        background-color: white;

        p {
          font: normal normal normal 19px/21px "Utile Display Book";
          margin: 0;
        }

        h1 {
          font: normal normal normal 62px/71px "Utile Display Book";
        }
      }
      .empty-cart-link {
        display: flex;
        align-items: center;
        a {
          font: normal normal normal 14px/16px "Utile Book";
          border-bottom: 1px solid #050505;
        }
      }
    }
  }

  .cart-order-footer {
    margin: 0 37px;

    p {
      font: normal normal normal 16px/28px "Utile Regular";
      margin: 0;

      &:last-of-type {
        margin-bottom: 18px;
      }
    }

    .tooltip-hover {
      position: relative;
      display: inline-block;
      border-bottom: 1px solid #050505;
      font: normal normal normal 16px/25px "Utile Book";
      margin-bottom: 33px;
      cursor: pointer;

      .tooltip-text {
        visibility: hidden;
        width: 330px;
        background-color: #f0f0f0;
        color: black;
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        position: absolute;
        z-index: 1;
        font: normal normal normal 16px/20px "Utile Regular";
        left: 50%;
        bottom: 30px;
        transform: translateX(-50%);
        border: 1px solid #050505;
      }

      &:hover .tooltip-text {
        visibility: visible;
      }
    }

    hr {
      border-top: 1px solid #050505;
      margin: 0;
      margin-bottom: 23px;
    }

    .prices {
      margin-bottom: 25px;
      .prices-line {
        display: flex;
        justify-content: space-between;

        p {
          font: normal normal normal 17px/19px "Utile Regular";
        }

        .amounts {
          font-size: 16px;
        }
      }
    }
  }

  #coupon-app {
    margin: 0 37px 45px;

    .form-inline {
      border: 1px solid #707070;
    }

    .promo-btn {
      border: 1px solid #9f9d9d;
      background-color: #9f9d9d;
      font: normal normal normal 15px/16px "Utile Regular";
      width: 75px;
      color: white;
      &:hover {
        color: #ffc000;
      }
    }

    input::placeholder {
      color: black;
      font: normal normal normal 14px/25px "Utile Regular";
    }
  }

  .continue-container {
    margin: 0 37px 47px;

    .continue-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 71px;
      font: normal normal normal 23px/26px "Utile Regular";
      background-color: #343333;
      color: white;

      &:hover {
        color: #ffc000;
      }
    }
  }

  @media (max-width: 700px) {
    .cart-order-footer .tooltip-hover .tooltip-text {
      left: 0px;
      transform: none;
    }
  }

  @media (max-width: 620px) {
    width: 100vw;
    padding-top: 29px;

    .cart-main-info {
      #arrow-hide {
        left: 15px;
        width: 34px;
      }
    }

    #coupon-app {
      width: 71%;
      margin: 0 auto 34px;
    }

    .cart-rbtd {
      width: 134px;
      font-size: 14px;
      font-size: 13.5px;
      margin-bottom: 24px;
    }

    .cart-title {
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .pieces-left {
      font: normal normal normal 16px/18px "Utile Book";
      margin-bottom: 21px;
    }

    .cart-items {
      padding: 22px 6% 0;
      margin-bottom: 17px;
      padding-bottom: 6px;

      .cart-product {
        margin-bottom: 14px;
        .cart-image {
          max-height: 27vw;
          margin-right: 5vw;
          a img {
            width: 27vw;
          }
        }

        .cart-product-details {
          padding-right: 22px;

          .brand,
          .title,
          .other {
            font: normal normal normal 14px/16px "Utile Regular";
          }

          .price {
            margin-right: -23px;
            font-size: 13px;
          }
        }
      }

      .empty-cart-product {
        margin-bottom: 14px;
        .empty-image {
          margin-right: 5vw;
          width: 27vw;
          height: 27vw;
          padding-top: 8px;

          h1 {
            margin-bottom: 0px;
            line-height: 60px;
          }
        }

        .empty-cart-link a {
          font-size: 13px;
        }
      }
    }

    .cart-order-footer {
      margin: 0 6%;

      p {
        font-size: 14px;
        line-height: 24px;
      }

      p:last-of-type {
        margin-bottom: 10px;
      }

      .tooltip-hover {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 26px;
      }

      .prices {
        margin-bottom: 38px;
        .prices-line {
          margin-bottom: 6px;
          p,
          .amounts {
            font-size: 15px;
          }
        }
      }
    }

    .continue-container {
      margin: 0 0 36px;

      .continue-btn {
        width: 71%;
        background-color: #9f1353;
        font: normal normal normal 15px/20px Avenir;
        height: 42px;
        margin: 0 auto;
      }
    }
  }

  @media (max-width: 350px) {
    .cart-main-info {
      #arrow-hide {
        left: 2px;
      }
    }
  }
}

.account-wrapper {
  position: relative;
  text-align: center;
  margin-right: 2em;
  #acc-name {
    width: 100%;
  }
  .acc-menu {
    display: block;
    opacity: 0;
    position: absolute;
    z-index: 1;
    bottom: -346px;
    background-color: white;
    border-radius: 3px;
    width: 170%;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    left: 50%;
    transform: translateX(-50%);
    .user-menu {
      position: absolute;
      bottom: 150px;
      background: white;
      width: 100%;
    }
    a {
      display: block;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 1em;
      width: 100%;
    }
  }

  .acc-user {
    bottom: -194px;
  }

  &:hover {
    .acc-menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

.acc-menu-mobile {
  a {
    display: block;
    text-align: left;
    text-transform: uppercase;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 1em;
  }
}

.jewelry {
  position: relative;
  text-align: center;
  a {
    margin-bottom: 10px;
  }

  #hiw-link {
    padding-bottom: 15px;
  }

  .jewelry-menu {
    display: block;
    opacity: 0;
    position: absolute;
    z-index: 1;
    bottom: -156px;
    background-color: white;
    border-radius: 3px;
    min-width: 160px;
    left: -20px;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    a {
      display: block;
      text-align: center;
      width: 100%;
    }
    a:hover {
      color: #7e7d7d;
    }
  }

  &:hover {
    .jewelry-menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

.collections {
  position: relative;
  text-align: center;
  a {
    margin-bottom: 10px;
  }

  #hiw-link {
    padding-bottom: 15px;
  }

  .collections-menu {
    display: block;
    opacity: 0;
    position: absolute;
    z-index: 1;
    bottom: -156px;
    background-color: white;
    border-radius: 3px;
    min-width: 160px;
    left: -40px;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    a {
      display: block;
      text-align: center;
      width: 100%;
    }
    a:hover {
      color: #7e7d7d;
    }
  }

  &:hover {
    .collections-menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

.occasion {
  position: relative;
  text-align: center;
  a {
    margin-bottom: 10px;
  }

  .occasion-menu {
    display: block;
    opacity: 0;
    position: absolute;
    z-index: 1;
    bottom: -156px;
    background-color: white;
    border-radius: 3px;
    min-width: 160px;
    left: -13px;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    a {
      display: block;
      text-align: center;
      width: 100%;
    }
    a:hover {
      color: #7e7d7d;
    }
  }

  &:hover {
    .occasion-menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

#mobile-menu .mobile-links .mobile-link {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  a {
    display: block;
    margin-bottom: 0;
  }
}

.navbar-wrapper #mobile-menu .mobile-menu {
  overflow: hidden;
  transition: all 1s ease-in-out;
  height: 0;
  position: relative;
  bottom: 9px;
  a {
    display: block;
    text-align: left;
    margin-left: 1em;
    font: normal normal normal 13px/13px "Utile Display Light";
    text-transform: capitalize;
    margin-bottom: 15px;
  }
}

#mobile-menu .mobile-links .acc-link {
  text-transform: uppercase;
  margin-bottom: 30px;
}

.navbar-wrapper #mobile-menu .acc-menu-mobile {
  a:last-of-type {
    margin-bottom: 30px;
  }
}

.arrow {
  background: url("../../../images//app/header/chevron-right.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  margin-left: 0.5em;
  cursor: pointer;
  // transform: rotate( 90deg ); //rotate if nav is open
}

.cart-items-number {
  text-align: center;
  border-radius: 50%;
  font: normal normal normal 12px/16px Gotham;
  height: 15px;
  width: 15px;
  color: white;
  margin-top: 0.9em;
  margin-left: 4px;
  position: absolute;
}

.cart-items-number-rbtd {
  background: #9f1353;
}
.cart-items-number-subscription {
  background: #c36300;
}

//header animations and hovers

#my-acc,
.account-wrapper {
  a:hover {
    color: black;
  }
}
.acc-menu {
  a:hover {
    color: #7e7d7d;
  }
}

.navbar-wrapper {
  .hiw,
  .testimonials-link,
  .collections,
  .jewelry,
  .occasion {
    a:hover {
      color: #7e7d7d;
    }
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 75%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #000;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }
    &:hover::before {
      transform: scaleX(1);
    }
  }
  .hiw {
    &::before {
      left: 2px;
    }
  }
  .testimonials-link {
    &::before {
      left: -5px;
    }
  }

  .jewelry,
  .occasion {
    &::before {
      left: 4px;
    }
  }
}
