.banner {
  width: 100%;
  height: 363px;
  background-image: url("~images/app/products/rbtd-banner.webp");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  margin-bottom: 43px;
  position: relative;
  .banner-details {
    position: absolute;
    left: 0px;
    top: 88px;
    width: 509px;
    height: 139px;
    background-color: rgba(237, 248, 253, 0.95);
    padding: 0px 30px 0px 68px;

    h5 {
      color: #9f1353;
      font-family: "Utile Medium";
      font-size: 15px;
      padding-top: 16px;
      margin-bottom: 11px;
    }
    p {
      font-family: "Utile Display Book";
      color: black;
      font-size: 34px;
      margin-bottom: 5px;
      line-height: 1;
    }
  }

  @media (max-width: 775px) {
    margin-bottom: 28px;
  }

  @media (max-width: 560px) {
    height: 468px;

    .banner-details {
      position: absolute;
      left: 0px;
      top: 310px;
      width: 329px;
      height: 98px;
      padding: 0px 14px;
      padding-right: 9px;

      h5 {
        font-size: 13px;
        padding-top: 11px;
        margin-bottom: 4px;
      }

      p {
        font-size: 25px;
        margin-bottom: 0px;
        line-height: 29px;
      }
    }
  }

  @media (max-width: 330px) {
    .banner-details {
      padding: 0px 9px;
    }
  }
}

#collection-banner {
  background-image: url("~images/app/products/collection-banner.webp");
  .banner-details {
    background-color: rgba(231, 152, 96, 0.95);
    h5 {
      color: black;
    }
  }
}

#necklaces-rbtd-banner {
  background-image: url("~images/app/products/necklaces_green_background.webp");
  .banner-details {
    background-color: rgba(232, 197, 133, 0.95);
  }
}

#necklaces-subscription-banner {
  background-image: url("~images/app/products/necklaces_red_currant.webp");
  h5 {
    color: #C36300;
  }
}

#earrings-rbtd-banner {
  background-image: url("~images/app/products/earrings_chessboard.webp");
  .banner-details {
    background-color: rgba(251, 218, 163, 0.95);
  }
}

#earrings-subscription-banner {
  background-image: url("~images/app/products/Earrings_marshmallows.webp");
  .banner-details {
    background-color: rgba(243, 204, 238, 0.75);
    h5 {
      color: #C36300;
    }
  }
}


#bracelets-rbtd-banner {
  background-image: url("~images/app/products/bracelets_white_chocolate.webp");
  .banner-details {
    background-color: rgba(245, 245, 243, 0.95);
  }
}

#bracelets-subscription-banner {
  background-image: url("~images/app/products/Bracelets_candies_pieces.webp");
  .banner-details {
    background-color: rgba(255,197,192, 0.75);
    h5 {
      color: #C36300;
    }
  }
}

#rings-rbtd-banner {
  background-image: url("~images/app/products/rings_long_lollipops.webp");
  .banner-details {
    background-color: rgba(188,155,90, 0.95);
    h5 {
      color: black;
    }
  }
}

#rings-subscription-banner {
  background-image: url("~images/app/products/rings_orange_candies.webp");
  .banner-details {
    background-color: rgba(32,41,124, 0.95);
    h5 {
      color: #C36300;
    }
    p {
      color: white;
    }
  }
}

.diamonds-collection {
  width: 90%;
  max-width: 640px;
  margin: auto;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  h3 {
    font: 24px/22px Utile Display Book;
  }

  div {
    width: 400px;
    height: 1px;
    margin: 0 auto 5px;
    border-bottom: 1px solid black;
  }

  p {
    font: 16px/20px Utile Display Book;
  }

  @media(max-width: 850px) {
    margin-top: 30px;
  }
}

.products-wrapper {
  padding: 0px 4%;

  #bottom-pagination {
    margin-bottom: 86px;
  }

  .pagination-products {
    display: flex;
    justify-content: flex-end;
    list-style: none;

    #current-page {
      font-weight: 900;
      font-family: "Utile Medium";
    }

    span {
      font-size: 14px;
      font-family: "Utile Book";
    }

    .chevron {
      position: relative;
      width: 16px;
      height: 22px;
      cursor: pointer;
      a {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 22px;
        z-index: 2;
      }
    }

    .chevron:after {
      display: inline-block;
      position: absolute;
      top: 0px;
      left: 0px;
      content: "";
      background-image: url("~images/app/chevron-right");
      background-size: contain;
      background-repeat: no-repeat;
      height: 15px;
      width: 16px;
      vertical-align: middle;
      margin: 3px 3px;
    }

    .left:after {
      transform: rotate(180deg);
      left: initial;
      right: 0px;
    }
  }

  .navigation {
    margin-bottom: 78px;
    display: flex;
    justify-content: space-between;

    a,
    span {
      font-family: "Utile Book";
      font-size: 12px;
    }

    .pagination-products span {
      font-size: 14px;
    }
  }

  @media (max-width: 1130px) {
    padding: 0px 3%;
  }

  @media (max-width: 775px) {
    margin-bottom: 42px;

    #bottom-pagination {
      margin-bottom: 35px;
    }

    .navigation {
      margin-bottom: 27px;
    }
  }
}

#main-container {
  display: flex;
  margin-bottom: 40px;

  .filter-menu {
    flex-basis: 25%;
    text-align: center;
    text-align: left;
    color: black;
    overflow-y: auto;
    max-width: 260px;
    padding-right: 13px;

    #menu-heading {
      font-family: "Utile Regular";
      font-size: 17px;
      position: relative;
      margin-bottom: 20px;
      pointer-events: none;

      &:before {
        position: absolute;
        left: 0;
        bottom: -7px;
        content: "";
        border-bottom: 1px solid #000000;
        width: 100%;
        margin: 0 auto;
      }

      @media (max-width: 775px) {
        font-size: 14px;
        cursor: pointer;

        &:before {
          display: none;
        }

        &:after {
          display: inline-block;
          content: "";
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 10px solid black;
          transform: rotate(270deg) translateX(-1px);
          margin-left: 7px;
        }
      }
    }

    .rbtd,
    .sub-box {
      display: flex;
      align-items: center;

      [type="radio"]:checked,
      [type="radio"] {
        position: absolute;
        left: -9999px;
      }

      [type="radio"]:checked + label,
      [type="radio"] + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: white;
      }

      [type="radio"]:checked + label:before,
      [type="radio"] + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        border: 1px solid black;
        border-radius: 0;
        background: #fff;
        transform: translateY(25%);
      }
      [type="radio"]:checked + label:after,
      [type="radio"] + label:after {
        content: "";
        width: 11px;
        height: 11px;
        background: black;
        position: absolute;
        top: 3px;
        left: 2px;
        border-radius: 0;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        transform: translateY(25%);
      }
      [type="radio"] + label:after {
        opacity: 0;
        transform: scale(0) translateY(25%);
      }
      [type="radio"]:checked + label:after {
        opacity: 1;
        transform: scale(1) translateY(25%);
      }

      p {
        font-family: "Utile Regular";
        font-size: 15px;
        margin: 0px;
        text-align: center;
        width: 172px;
        height: 25px;
        line-height: 25px;
        background-color: #9f1353;
        color: white;
        border: none;
      }
    }

    .rbtd {
      margin-bottom: 0px;
    }

    .sub-box {
      margin-bottom: 20px;
      span label p {
        background-color: #c36300;
      }
    }

    .accordions {
      svg {
        display: none;
      }
      .ac-title {
        font-family: "Utile Regular";
        font-size: 17px;
        position: relative;
        cursor: pointer;
        margin-bottom: 15px;
        user-select: none;

        &:before {
          position: absolute;
          left: 0;
          bottom: -3px;
          content: "";
          border-bottom: 1px solid #000000;
          width: 100%;
          margin: 0 auto;
        }

        &:after {
          position: absolute;
          content: "-";
          right: 3px;
          bottom: 0px;
          font-family: "Utile Book";
          font-size: 25px;
          height: 28px;
        }
      }

      .collapsed {
        &:after {
          position: absolute;
          content: "+";
          right: 0px;
          bottom: 0px;
          font-size: 25px;
          font-family: "Utile Book";
          height: 28px;
        }
      }

      .panel-collapse {
        list-style: none;
        padding-left: 0;
        margin: 0;

        .filter-container {
          display: flex;
          flex-direction: column;
          max-height: 180px;
          flex-wrap: wrap;
          margin-bottom: 25px;
        }

        li {
          height: 19px;
          padding-left: 2px;
        }

        [type="radio"]:checked,
        [type="radio"] {
          display: none;
          line-height: 13px;
        }

        [type="radio"]:checked + label,
        [type="radio"] + label {
          display: inline-block;
          position: relative;
          padding-left: 19px;
          cursor: pointer;
          line-height: 13px;
          font-size: 12px;
          font-family: "Utile Regular";
          color: black;

          p {
            margin: 0;
          }
        }

        [type="radio"]:checked + label:before,
        [type="radio"] + label:before {
          content: "";
          position: absolute;
          left: 0;
          top: -1px;
          width: 12px;
          height: 12px;
          border: 1px solid black;
          border-radius: 0;
          background: #fff;
          transform: translateY(25%);
        }
        [type="radio"]:checked + label:after,
        [type="radio"] + label:after {
          content: "";
          width: 12px;
          height: 12px;
          background: black;
          position: absolute;
          top: -1px;
          left: 0;
          border-radius: 0;
          transition: all 0.2s ease;
          transform: translateY(25%);
        }
        [type="radio"] + label:after {
          opacity: 0;
          transform: scale(0) translateY(25%);
        }
        [type="radio"]:checked + label:after {
          opacity: 1;
          transform: scale(1) translateY(25%);
        }
      }
    }
    .spacer {
      height: 38px;
    }

    #clear-all {
      display: block;
      width: 99px;
      height: 34px;
      font-family: "Utile Regular";
      font-size: 16px;
      background-color: transparent;
      color: black;
      margin: 0 auto;
      border: 1px solid black;
      transition: all 0.2s ease;

      &:hover {
        // color: #ffc000;
        box-shadow: 0 0 1px #000;
      }

      &:active {
        transform: translateY(1px);
        box-shadow: 0 0 2px #000;
      }
    }

    @media (max-width: 1130px) {
      max-height: none;
      padding-right: 10px;

      .rbtd p,
      .sub-box p {
        width: 15vw;
        font-size: 14px;
      }
    }

    @media (max-width: 950px) {
      padding-right: 0px;

      .rbtd p,
      .sub-box p {
        width: 17vw;
        font-size: 13px;
      }
    }

    @media (max-width: 775px) {
      max-width: none;

      .sub-box {
        margin-bottom: 0px;
      }

      #menu-heading {
        pointer-events: all;
      }

      .accordions {
        display: flex;
        flex-direction: column;
        position: fixed;
        overflow-y: auto;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: white;
        border-bottom: 1px solid black;
        z-index: 2000;
        padding: 21px 81px 21px 31px;
        transition: top 1s;

        .collapsed:after {
          font-family: "Utile Display Light";
        }

        .ac-title {
          font-size: 14px;
          font-family: "Utile Medium";
          margin-bottom: 11px;
        }

        .accordion {
          padding-bottom: 18px;
        }

        .panel-collapse {
          .filter-container {
            max-height: 165px;
            margin-bottom: 5px;
          }

          [type="radio"] + label,
          [type="radio"]:checked + label {
            font-size: 13px;
            font-family: "Utile Book";
          }

          li {
            height: 23px;
          }
        }

        svg {
          cursor: pointer;
          display: block;
          height: 18px;
          width: 18px;
          min-height: 18px;
          margin-bottom: 40px;
        }

        #clear-all {
          margin: 0;
        }

        .spacer {
          height: 25px;
          min-height: 25px;
        }
      }
    }
  }

  #products-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: left;
    transform: translateY(-10px);
    padding-left: 3%;

    .product-thumb {
      margin-bottom: 10px;
      flex: 0 1;
      min-width: 30%;
      padding: 0px;

      .image-container {
        margin-bottom: 15px;
        a {
          display: flex;
          justify-content: center;

          .image {
            height: 220px;
            width: 100%;
            padding: 12px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            @media(max-width: 775px) {
              background-size: cover;
            }

            @media(max-width: 450px) {
              padding: 0;
            }
          }
        }
      }

      .details {
        position: relative;
        padding-right: 30px;
        // padding-left: 30px;
        padding-left: 4vw;
        color: black;

        .favorite-action {
          position: absolute;
          top: -23px;
          right: 4px;
          @include font-size(22px);
          line-height: 1em;
          @include transition(all 0.2s ease);

          svg {
            fill: white;
            stroke-width: 1.5;
            stroke: black;
            height: 16px;

            path {
              transition: all 0.5s;
            }

            @media (min-width: 1150px) {
              &:hover {
                path {
                  fill: #ffc000 !important;
                }
              }
            }
          }
        }
        a.title {
          font-family: "Utile Medium";
          font-size: 14px;
          margin-bottom: 5px;
          line-height: 17px;
          display: inline-block;
          color: black;

          @media(max-width: 450px) {
            font-size: 12px;
          }
        }
        .sub {
          font-size: 0;
          line-height: 12px;
          span,
          p {
            font-family: "Utile Book";
            
            @media(min-width: 450px) {
              font-size: 14px;
            }
          }
        }
      }

      @media (max-width: 1130px) {
        min-width: 260px;
      }
    }

    @media (max-width: 775px) {
      transform: none;
      padding-left: 0;
      .product-thumb .details {
        padding-right: 50px;
        text-align: center;
      }
    }

    @media (max-width: 570px) {
      margin-bottom: 30px;
      .product-thumb {
        min-width: auto;
        flex-basis: 50%;
        padding: 0px 4%;

        .details {
          padding: 4px;

          .favorite-action {
            @include font-size(12px);
            top: -17px;
            right: -10px;
          }
        }

        a.title {
          font-size: 12px;
        }
        .sub {
          span,
          p {
            font-size: 11px;
          }
        }

        .image-container a .image {
          height: 180px;
        }
      }
    }

    @media (max-width: 450px) {
      .product-thumb .image-container a .image {
        height: 140px;
      }
    }
  }

  @media (max-width: 775px) {
    flex-direction: column;
  }

  &.diamonds-section {
    margin-top: 120px;

    @media(max-width: 850px) {
      margin-top: 140px;
    }

    @media(max-width: 700px) {
      margin-top: 200px;
    }
  }
}

#ac-colors {
  img {
    border-radius: 50%;
    width: 32px;
    margin: 5px;
  }

  .img-saturated {
    filter: saturate(180%);
  }

  .img-darker {
    filter: brightness(50%);
  }

  .img-saturated-hue {
    filter: saturate(120%) hue-rotate(30deg);
  }

  .filter-container {
    position: relative;
    max-height: initial !important;
    flex-direction: row !important;

    li:first-of-type {
      position: absolute;
      width: 100px;
      right: 20px;
      top: -35px;
    }
  }

  li {
    width: 50%;
    height: auto !important;
  }

  label {
    margin-bottom: 0;
  }
}
