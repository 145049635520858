
@import '~stylesheets/app/vars';

.fields {
  margin-top: 30px;
}
.fb-signin-button {
  display: inline-block;
  padding: 10px 80px;
  color: #fff;
  cursor: pointer;
  border: 2px solid #fff;
  text-align: center;
  background: #3C5A99;
  font-weight: 300;
  font-family: $font-roboto;
  font-size: 14px;
  text-transform: uppercase;
}

.info-text {
  font-family: $font-roboto;
  font-size: 14px;
  text-transform: uppercase;
  margin: 15px 0;
}

.fields-wrapper {
  margin: 0 20%;

  @media (max-width: 901px) {
    margin: 0 10%;
  }

  @media (max-width: 700px) {
    margin: 0;
  }
}

.btn-primary {
  background: #000;
  color: #fff;
  margin-top: 10px;
  border: 0;
}

@media (max-width: 500px) {
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: .8rem;
  }
}
h4 {}
