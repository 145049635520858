
.image-wrapper {
  position: relative;

  .text-wrapper {
    position: absolute;
    top: 0;
    height: 100%;
    width: 40%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
    }

    button {
      color: #fff;
      background: #000;
      border-color: #000;
      text-transform: uppercase;
      font-size: 16px;
      padding: 0.2rem 2.5rem;
    }

    @media (max-width: 991px) {
      width: 45%;
      h1 { font-size: 24px; }
      p { font-size: 14px; }
      button { font-size: 14px; }
    }
    @media (max-width: 767px) {
      width: 50%;
      h1 { font-size: 20px; }
      p { font-size: 13px; }
      button { font-size: 13px; padding: 0.2rem 2rem; }
    }
    @media (max-width: 500px) {
      width: 50%;
      h1 { font-size: 16px; }
      p { font-size: 12px; }
      button { font-size: 12px; padding: 0.1rem 1.3rem; }
    }

    @media (max-width: 410px) {
      position: relative;
      width: 100%;
      margin-top: 10px;
    }
  }
}
