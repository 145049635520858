
.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

#hiw-player-button {
  .icon {
    margin-left: 5px;
  }
}

#hiw-hero-player-button {
  position: absolute;
  left: calc(50% - 15px);
  top: 200px;
  border: 0;
  background: transparent;
  color: white;
  transform: scale(5);
  z-index: 1;
  filter: drop-shadow(-1px 1px 1px rgba(0, 0, 0, 0.6));

  @media(min-width: 835px) {
    left: 647px;
    top: 232px;
  }

  @media(min-width: 1455px) {
    left: 44.5vw;
    top: 16vw;
  }
}
