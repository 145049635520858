
span.phone-prefix{
  position: absolute;
  color: #495057;
  font-family: Roboto,serif;
  font-weight: 300;
  font-size: .8125rem;
  height: 38px;
  line-height: 38px;
  padding-left: 15px;
  pointer-events: none;
}
input#vendor-phone{
  padding-left: 45px;
}
