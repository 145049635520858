
.remove-code {
  margin-top: -1.5em;
  margin-bottom: 21px;
  a {
    color: grey;
  }
}
@media (max-width: 900px) {
  .remove-code {
    margin-top: -1em;
  }
}
