#cart-container {
  color: black;
  position: fixed;
  overflow-y: auto;
  max-height: 100vh;
  width: 600px;
  top: 0;
  z-index: 1000;
  background-color: white;
  height: max-content;
  border: 1px solid #98979757;
  padding-top: 38px;
  right: -100vw;
  transition: all 0.8s ease-in-out;

  #length-var {
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin: 0;
  }

  .empty {
    text-align: center;
    font: normal normal normal 28px/32px "Utile Display Book";
    margin-bottom: 20px;
  }

  .cart-main-info {
    position: relative;

    #arrow-hide {
      position: absolute;
      top: 50%;
      left: 36px;
      transform: rotate(180deg) translateY(50%) scaleY(1.1);
      cursor: pointer;
      width: 46px;
      height: auto;
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  .subscription .pieces-left span {
    color: #c36300;
  }

  .cart-plan {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 127px;
    height: 22px;
    color: #fff;
    font: normal normal normal 13px/14px "Utile Regular";
    background-color: #9f1353;
    margin: 0 auto 30px;
  }

  .cart-title {
    text-align: center;
    font: normal normal normal 28px/32px "Utile Display Book";
    margin-bottom: 18px;
  }

  .pieces-left {
    font: normal normal normal 18px/20px "Utile Display Light";
    text-align: center;
    margin-bottom: 27px;

    span {
      color: #9f1353;
    }
  }

  .cart-items {
    display: flex;
    flex-direction: column;
    background-color: rgba(126, 123, 124, 0.123);
    margin-bottom: 27px;
    padding: 35px 37px 0;

    a,
    p,
    h1,
    h2,
    h3,
    h4 {
      color: black;
    }

    a:hover {
      color: #ffc000;
    }

    .cart-product {
      display: flex;
      margin-bottom: 25px;
      .cart-image {
        background-color: white;
        margin-right: 31px;
        max-height: 163px;
        a img {
          width: 163px;
        }
      }
      .cart-product-details {
        flex: 1;
        position: relative;
        padding-right: 25px;
        .product-price-other {
          display: flex;
          justify-content: space-between;
        }

        .delete-link {
          display: flex;
          height: 15px;
          width: 15px;
          position: absolute;
          top: 0;
          right: 0;
          .delete-svg {
            width: 100%;
            stroke-width: 4px;
            height: auto;
          }
        }

        .brand,
        .title,
        .other {
          font: normal normal normal 14px/16px "Utile Book";
        }

        .brand {
          margin-bottom: 0;
        }

        .title {
          margin-bottom: 18px;
        }

        .price {
          font: normal normal normal 14px/25px "Utile Book";
          min-width: max-content;
          word-spacing: -2px;
        }

        .other {
          padding-right: 10px;
        }
      }
    }

    .empty-cart-product {
      margin-bottom: 25px;
      display: flex;

      .empty-image {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 163px;
        height: 163px;
        margin-right: 31px;
        background-color: white;
        position: relative;

        p {
          font: normal normal normal 19px/21px "Utile Display Book";
          margin: 0;
        }

        h1 {
          font: normal normal normal 62px/71px "Utile Display Book";
        }
      }

      .subscription {
        padding-top: 25px;

        .empty-image-count {
          position: relative;
        }

        p {
          position: absolute;
          top: 15px;
        }

        h1 {
          position: absolute;
          left: -7px;
          top: 0;
          line-height: 30px;
        }

        .max-pieces {
          bottom: 0px;
          right: -10px;
          top: unset;
          left: unset;
        }
      }

      .empty-cart-link {
        display: flex;
        align-items: center;
        a {
          font: normal normal normal 14px/16px "Utile Book";
          border-bottom: 1px solid #050505;
        }
      }
    }
  }

  .cart-order-footer {
    margin: 0 37px;

    p {
      font: normal normal normal 16px/28px "Utile Regular";
      margin: 0;

      &:last-of-type {
        margin-bottom: 18px;
      }
    }

    .tooltip-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }

    .tooltip-hover {
      position: relative;
      display: inline-block;
      border-bottom: 1px solid #050505;
      font: normal normal normal 16px/25px "Utile Book";
      margin-bottom: 15px;
      cursor: pointer;
      width: fit-content;

      .tooltip-text {
        visibility: hidden;
        width: 330px;
        background-color: #f0f0f0;
        color: black;
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        position: absolute;
        z-index: 1;
        font: normal normal normal 16px/20px "Utile Regular";
        left: 110%;
        bottom: 30px;
        transform: translateX(-50%);
        border: 1px solid #050505;
      }

      &:hover .tooltip-text {
        visibility: visible;
      }
    }

    hr {
      border-top: 1px solid #050505;
      margin: 0;
      margin-bottom: 23px;
    }

    .prices {
      margin-bottom: 25px;
      .prices-line {
        display: flex;
        justify-content: space-between;

        p {
          font: normal normal normal 17px/19px "Utile Regular";
        }

        .amounts {
          font-size: 16px;
        }
      }
    }
  }

  #coupon-app {
    margin: 0 37px 45px;

    .form-inline {
      border: 1px solid #707070;
    }

    .promo-btn {
      border: 1px solid #9f9d9d;
      background-color: #9f9d9d;
      font: normal normal normal 15px/16px "Utile Regular";
      width: 75px;
      color: white;
      &:hover {
        color: #ffc000;
      }
    }

    input::placeholder {
      color: black;
      font: normal normal normal 14px/25px "Utile Regular";
    }
  }

  .continue-container {
    margin: 0 37px 47px;

    .continue-btn-subscription,
    .continue-btn-rbtd {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 71px;
      font: normal normal normal 23px/26px "Utile Regular";
      background-color: #000000;
      color: white;

      &:hover {
        color: white;
        background: #c36300;
        border: none;
      }
    }
    .continue-btn-rbtd:hover {
      background: #9f1353;
    }
  }

  .subscription {
    .continue-btn-subscription,
    .continue-btn-rbtd {
      color: white;
      background-color: #000000;
    }
  }

  @media (max-width: 700px) {
    .cart-order-footer .tooltip-hover .tooltip-text {
      left: 0px;
      transform: none;
    }
  }

  @media (max-width: 620px) {
    width: 100vw;
    padding-top: 29px;

    .cart-main-info {
      #arrow-hide {
        left: 15px;
        width: 34px;
      }
    }

    #coupon-app {
      width: 71%;
      margin: 0 auto 34px;
    }

    .cart-plan {
      width: 134px;
      font-size: 14px;
      font-size: 13.5px;
      margin-bottom: 24px;
    }

    .cart-title {
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .pieces-left {
      font: normal normal normal 16px/18px "Utile Book";
      margin-bottom: 21px;
    }

    .cart-items {
      padding: 22px 6% 0;
      margin-bottom: 17px;
      padding-bottom: 6px;

      .cart-product {
        margin-bottom: 14px;
        .cart-image {
          max-height: 27vw;
          margin-right: 5vw;
          a img {
            width: 27vw;
          }
        }

        .cart-product-details {
          padding-right: 22px;

          .brand,
          .title,
          .other {
            font: normal normal normal 14px/16px "Utile Regular";
          }

          .price {
            margin-right: -23px;
            font-size: 13px;
          }
        }
      }

      .empty-cart-product {
        margin-bottom: 14px;

        .empty-image {
          margin-right: 5vw;
          width: 27vw;
          height: 27vw;
          padding-top: 8px;

          h1 {
            margin-bottom: 0px;
            line-height: 60px;
          }
        }

        .subscription {
          padding-top: 25px;

          p {
            top: 5px;
          }

          h1 {
            line-height: 30px;
            font-size: 50px;
            left: -5px;
          }

          .max-pieces {
            right: 0px;
            left: unset;
          }
        }

        .empty-cart-link a {
          font-size: 13px;
        }
      }
    }

    .cart-order-footer {
      margin: 0 6%;

      p {
        font-size: 14px;
        line-height: 24px;
      }

      p:last-of-type {
        margin-bottom: 10px;
      }

      .tooltip-hover {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 26px;
      }

      .prices {
        margin-bottom: 38px;
        .prices-line {
          margin-bottom: 6px;
          p,
          .amounts {
            font-size: 15px;
          }
        }
      }
    }

    .continue-container {
      margin: 0 0 36px;

      .continue-btn-subscription,
      .continue-btn-rbtd {
        width: 71%;
        background-color: #9f1353;
        font: normal normal normal 15px/20px "Utile Regular";
        height: 42px;
        margin: 0 auto;
      }
    }

    .subscription .continue-btn-subscription,
    .continue-btn-rbtd {
      background-color: #000000;
    }
  }

  @media (max-width: 440px) {
    .cart-items .empty-cart-product .subscription {
      p {
        top: 6px;
        font: normal normal normal 16px/18px "Utile Display Book";
      }

      h1 {
        left: 4px;
        top: -3px;
        font: normal normal normal 40px/40px "Utile Display Book";
      }

      .max-pieces {
        top: unset;
        bottom: 0px;
        right: 5px;
      }

      svg {
        height: 60px;
      }
    }
  }

  @media (max-width: 350px) {
    .cart-main-info {
      #arrow-hide {
        left: 2px;
      }
    }
  }

  .not-available {
    margin-top: -17px;
    margin-bottom: 30px;
  }
}

.code-line {
  display: flex;
  justify-content: space-between;
  font: normal normal normal 17px/19px "Utile Regular";
  p {
    margin-bottom: 0;
  }
}
.remove-coupon {
  color: grey;
  font-weight: 200;
  margin-bottom: 1.5em;
}
