.order-wrapper {
  margin-bottom: 45px;

  &:hover .order-body {
    background: #FCFCF3;
  }

  .order-header {
    padding-bottom: 5px;
    margin-bottom: 10px;
    padding: 10px;
    line-height: 1em;

    border: 1px solid $color-yellow;
    border-top-width: 10px;

    font-weight: bold;
    @include font-size(16px);

    span {
      font-weight: 300;
      @include font-size(11px);
    }

  }

  .order-body {
    background: $color-white;
    padding: 10px;
    @include transition(all $ani-speed ease);

    .order-item {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dashed $color-grey-light;
      &:last-child {
        border-bottom-style: solid;
      }

      a.a-image {
        display: block;
        img {
          width: 100%;
        }
      }
    }
  }
}
