@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;900&display=swap");
.thank-you-page-container {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  .hero {
    height: 329px;
    width: 100%;
    margin-top: 1em;
    background: url("../../../images/app/thank-you-page/thankyou-hero.webp");
    background-position: center;
    background-size: cover;
    .description-container {
      max-width: 458px;
      text-align: center;
      margin-left: 8em;
      padding-top: 5em;
      .user-container {
        border-bottom: 1.5px solid rgba(255, 255, 255, 0.5);
        padding-bottom: 0.3em;
        margin-bottom: 0.5em;
      }
      h1 {
        font-family: "Lato";
        font-weight: 300;
        font-size: 22px;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        max-width: 400px;
        margin: 0 auto;
      }
      h2 {
        font-family: "Lato";
        font-weight: 300;
        font-size: 20px;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 0px;
      }
      p {
        font-family: "Lato";
        font-weight: 300;
        font-size: 13px;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 2em;
        margin-top: 0.5em;
      }
      a {
        background: #ffffff 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 0.5em 3em 0.6em;
        text-align: center;
        font: normal normal normal 15px/18px Utile;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.96;
      }
      a:hover {
        opacity: 1;
        background: #7e7d7d;
        color: white;
      }
      a:focus {
        border: solid 1px black;
        opacity: 1;
      }
    }

    @media (max-width: 1335px) {
      .description-container {
        margin-left: 2em;
      }
    }
    @media (max-width: 1200px) {
      .description-container {
        margin-left: 10px;
        max-width: 300px;
        padding-top: 2em;
      }
    }
  }
  .description-container-mobile {
    display: none;
  }

  @media (max-width: 835px) {
    .hero {
      height: 467px;
      background: url("../../../images/app/thank-you-page/thankyou-hero-mobile.webp");
      background-position: center;
      background-size: cover;
      .description-container {
        display: none;
      }
    }
    .description-container-mobile {
      display: block;
      background: #94623e;
      text-align: center;
      height: 270px;
      padding-top: 1.5em;
      .user-container {
        border-bottom: 1.5px solid rgba(255, 255, 255, 0.5);
        // padding-bottom: 0.3em;
        margin: 0 auto 0.5em;
        width: 80%;
      }
      h1 {
        font-family: "Lato";
        font-weight: 300;
        font-size: 22px;
        letter-spacing: 0px;
        color: #ffffff;
      }
      h2 {
        font-family: "Lato";
        font-weight: 300;
        font-size: 17px;
        letter-spacing: 0px;
        color: #ffffff;
      }
      p {
        font: normal normal normal 12px/25px Utile;
        letter-spacing: 0px;
        color: #ffffff;
      }
      a {
        background: #ffffff 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 0.3em 2em;
        text-align: center;
        font: normal normal normal 15px/18px Utile;
        letter-spacing: 0px;
        color: #000000;
      }
    }
  }
  @media (max-width: 375px) {
    .description-container-mobile {
      margin-top: -1px;
      padding-top: 0.5em;
    }
  }
  @media (max-width: 330px) {
    .description-container-mobile {
      padding-top: 0.5em;
    }
  }

  .order {
    width: 90%;
    margin: 4em auto 0;
    h1 {
      font: normal normal normal 19px/21px Utile Display Book;
      letter-spacing: 0px;
      color: #000000;
    }
    h2 {
      font: normal normal normal 17px/19px Utile;
      margin-top: 1.5em;
      margin-bottom: 1.5em;
    }
    h3 {
      font: normal normal normal 17px/32px Utile;
      letter-spacing: 0px;
      margin-bottom: 0px;
    }
    p {
      font: normal normal normal 15px/18px Utile;
      letter-spacing: 0px;
      margin-bottom: 4em;
    }
    @media (max-width: 835px) {
      h2 {
        margin-bottom: 0.5em;
      }
    }
  }

  .items {
    width: 90%;
    margin: 2em auto 0;
    p {
      font: normal normal normal 19px/21px Utile Display Book;
      letter-spacing: 0px;
      color: #000000;
    }
    .items-lenght {
      p {
        font: normal normal normal 19px/21px Utile Display Book;
      }
      @media (max-width: 830px) {
        p {
          font: normal normal normal 14px/16px Utile Display Book;
        }
      }
    }
    .items-container {
      display: flex;
      .product {
        margin-right: 1em;
      }
      img {
        height: 211px;
        width: auto;
        padding: 1em;
        border: 0.5px solid #b4b4b4;
      }
      .description {
        margin-top: 1em;
        text-align: left;
        font: normal normal normal 13px/15px Utile;
        width: 150px;
      }
      @media (max-width: 835px) {
        img {
          height: 106px;
        }
        .product {
          margin-right: 0.5em;
        }
        .description {
          font: normal normal normal 12px/13px Utile;
          width: 90px;
        }
      }
    }
  }

  .delivery {
    width: 90%;
    margin: 6em auto;
    h1 {
      font: normal normal normal 19px/21px Utile Display Book;
      letter-spacing: 0px;
      color: #000000;
    }
    .address {
      margin-top: 1.5em;
      font: normal normal normal 17px/21px Utile;
      letter-spacing: 0px;
      color: #000000;
      h2 {
        font: normal normal normal 17px/21px Utile;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 0;
      }
      .address-details {
        h2 {
          font: normal normal normal 17px/21px Utile;
          letter-spacing: 0px;
          color: #000000;
          margin-bottom: 0;
        }
      }
    }
  }
  @media (max-width: 835px) {
    .delivery {
      h1 {
        font: normal normal normal 14px/16px Utile Display Book;
      }
      .address {
        h2 {
          font: normal normal normal 13px/17px Utile;
        }
        .address-details {
          h2 {
            font: normal normal normal 13px/17px Utile;
          }
        }
      }
    }
  }

  .payment,
  .total {
    width: 90%;
    margin: 0 auto 1.5em;
    h1 {
      font: normal normal normal 19px/21px Utile Display Book;
      letter-spacing: 0px;
      color: #000000;
    }
    .card-details {
      margin-top: 1em;
    }
    @media (max-width: 835px) {
      h1 {
        font: normal normal normal 14px/16px Utile Display Book;
      }
      .total-container {
        h3 {
          font: normal normal medium 13px/11px Utile;
        }
      }
    }
  }

  .card-details {
    display: flex;
    .mastercard-image {
      width: 62px;
      height: 40px;
      background: url("../../../images/app/thank-you-page/master-card.webp");
      background-position: center;
      background-size: cover;
    }
    .card-description {
      margin: auto 0 auto 1em;
      font: normal normal normal 16px/18px Utile;
    }
    @media (max-width: 835px) {
      .mastercard-image {
        width: 34px;
        height: 23px;
      }
      .card-description {
        font: normal normal normal 13px/15px Utile;
      }
    }
  }
  .total-container {
    display: flex;
    margin-top: 2em;
    .details,
    .prices {
      flex: 3;
      // margin-top: 1em;
      .first-detail {
        margin-top: 0px;
      }
      h2 {
        font: normal normal normal 17px/19px Utile;
        letter-spacing: 0px;
        color: #000000;
        margin-top: 1em;
      }
      h3 {
        font: normal normal normal 17px/19px Utile;
        letter-spacing: 0px;
        color: #000000;
        margin-top: 2em;
        margin-bottom: 4em;
      }
      .product-purchase {
        display: flex;
      }
      @media (max-width: 830px) {
        p {
          font: normal normal normal 13px/11px Utile;
        }
      }
    }
    .prices {
      text-align: right;
      flex: 1;
      .total-price {
        margin-top: 2em;
      }
    }
  }

  .under-line {
    border-bottom: solid 1px black;
    margin: 0 auto;
  }

  .faq {
    width: 90%;
    margin: 2em auto 8em;
    a {
      border-bottom: 1px solid black;
    }
  }

  @media (max-width: 835px) {
    .order {
      h1 {
        font: normal normal normal 14px/16px Utile Display Book;
      }
      h2 {
        font: normal normal normal 13px/15px Utile;
      }
      h3 {
        font: normal normal normal 13px/17px Utile;
      }
      p {
        font: normal normal normal 12px/14px Utile;
      }
    }

    .delivery {
      h1 {
        font: normal normal normal 14px/16px Utile Display Book;
      }
      .address {
        font: normal normal normal 13px/17px Utile;
      }
    }

    .total-container {
      .details,
      .prices {
        h2 {
          font: normal normal normal 13px/11px Utile;
        }
        h3 {
          font: normal normal normal 13px/11px Utile;
        }
      }
    }

    .faq {
      margin: 0.5em auto 2em;
      font: normal normal normal 12px/14px Utile;
    }
  }
}
