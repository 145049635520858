.show-wrapper {
  margin: 0 2.8vw;
  color: black;
}

.no-products {
  text-align: center;
  font-family: "Utile Regular";
  font-size: 20px;
  margin-bottom: 45px;
  margin-top: 0;

  @media (max-width: 600px) {
    margin-bottom: 10px;
    font-size: 16px;
    margin-top: -35px;
  }
}

.product-container {
  display: flex;
  margin: 64px 0 100px;
  padding-bottom: 50px;

  .product-images {
    max-width: 120px;

    .slick-carousel-images-mobile {
      display: none;

      img {
        margin-bottom: 40px;
      }

      .slick-dots {
        li {
          margin: 0;
          margin-right: 17px;

          &:last-of-type {
            margin-right: 0px;
          }
        }

        button {
          height: 7px;
          width: 7px;
        }
      }
    }

    img {
      width: 120px;
      max-width: none;
      cursor: pointer;
    }

    @media (max-width: 900px) {
      max-width: none;
      width: 80%;
      max-width: 400px;
      margin-left: 0px;
      margin-bottom: 25px;

      .slick-carousel-images-mobile {
        display: block;
      }

      .slick-carousel-images {
        display: none;
      }

      img {
        width: 100%;
      }
    }
  }

  .preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 46%;
    padding: 0 40px;
    margin: 0 45px;
    max-width: 780px;

    img {
      width: 100%;
    }

    .preview-details {
      height: 106px;
      max-width: 560px;
      min-width: 450px;
      width: 91%;
      background-color: #eaeaea;
      transform: translateY(40px);
      padding: 15px 20px;

      h4 {
        font: normal normal normal 16px/16px "Utile Regular";
        margin-bottom: 5px;
      }

      hr {
        border-color: black;
        margin: 0;
        margin-bottom: 15px;
        opacity: 0.3;
        transform: scaleY(1.1);
      }
      .prices {
        display: flex;
        align-items: center;
        .per-day {
          font: normal normal normal 17px/22px "Utile Regular";
          color: #9f1353;
          border-right: 1px solid rgb(128, 128, 128);
          flex: 1 1 50%;
          padding-right: 7px;
          .currency {
            font-size: 20px;
          }
          .minimum {
            font-size: 14px;
          }
        }
        .market-price {
          font: normal normal normal 19px/22px "Utile Regular";
          flex: 1 1 50%;
          padding-left: 13px;

          .compare {
            font-size: 14px;
          }

          .market {
            font-size: 15px;
          }
        }
      }
    }

    @media (max-width: 1210px) {
      .preview-details {
        min-width: auto;
        width: 90%;
        height: 120px;
      }
    }

    @media (max-width: 1100px) {
      padding: 0;
      margin: 0 15px;
    }

    @media (max-width: 900px) {
      margin-bottom: 54px;
      img {
        display: none;
      }

      .preview-details {
        transform: none;
        min-width: 328px;
        width: 87.5vw;
        height: auto;
        min-height: 106px;
        padding: 14px 18px;
        hr {
          opacity: 1;
          margin-bottom: 11px;
        }
        .prices {
          text-align: center;

          .per-day {
            padding: 0;
            font-size: 14px;
            padding-right: 18px;
            .currency {
              font-size: 18px;
            }

            .minimum {
              font-size: 14px;
              display: block;
            }
          }

          .market-price {
            padding: 0;
            padding-left: 18px;
            font-size: 18px;
            .compare {
              display: block;
              font-size: 14px;
            }
            .market {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .details {
    flex: 1 1 25%;

    .nav {
      margin-bottom: 14px;

      a,
      span {
        font: normal normal normal 12px/13px "Utile Regular";
        margin-right: 5px;
      }
    }

    .plan-edit {
      display: flex;
      flex-direction: column;

      .rbtd-title {
        flex-basis: 50%;
        margin-right: 18px;
      }

      h2,
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 127px;
        height: 22px;
        color: #fff;
        font: normal normal normal 12.5px/22px "Utile Regular";
        margin-bottom: 16px;
      }

      h2 {
        background-color: #9f1353;
        margin-right: 35px;
      }

      a {
        background-color: #343333;
        flex: 1 1;
        max-width: 127px;
        margin-top: -10px;
        &:hover {
          color: #ffc000;
          background-color: #242424;
          box-shadow: 0 0 2px #000;
        }
      }
    }

    .title-review {
      display: flex;
      align-items: center;
      margin-bottom: 22px;

      h3 {
        font: normal normal normal 25px/25px "Utile Display Book";
        flex-basis: 50%;
        border-right: 1px solid black;
        margin-right: 18px;
        padding-right: 6px;
      }

      .reviews {
        flex: 1;
        color: #9f1353;
        min-width: 90px;
        a {
          color: #000000;
          display: table;
          font: normal normal normal 13px/25px "Utile Regular";
          position: relative;
          &:after {
            position: absolute;
            left: 0;
            bottom: 2px;
            content: "";
            border-bottom: 1px solid #050505;
            width: 100%;
            margin: 0 auto;
            opacity: 0.3;
          }
        }
        .rating {
          svg {
            width: 15px;
            margin-right: 1px;
            g {
              stroke: #9f1353;
            }
          }

          .full-star svg g {
            fill: #9f1353;
            stroke: #9f1353;
          }
        }
      }
    }

    .description {
      font: normal normal normal 14px/20px "Utile Regular";
      padding-right: 30px;
    }

    .view-specifications {
      font: normal normal normal 13px/19px "Utile Regular";
      cursor: pointer;
      padding-bottom: 7px;

      &:after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        content: "";
        border-top: 0.38em solid transparent;
        border-bottom: 0.38em solid transparent;
        border-left: 0.5em solid;
        vertical-align: baseline;
      }
    }

    .view-specifications[aria-expanded="true"] {
      &:after {
        transform: rotate(90deg);
      }
    }

    #product-specifications {
      font: normal normal normal 13px/19px "Utile Regular";

      ul {
        list-style: none;
        padding-left: 0;
        margin: 0;

        li {
          font: normal normal normal 13px/15px "Utile Regular";
          padding-bottom: 4px;

          &:last-of-type {
            padding-bottom: 9px;
          }
          &:before {
            content: "·";
            margin-right: 1px;
            font-size: 32px;
            vertical-align: bottom;
            line-height: 18px;
          }
        }
      }
    }

    hr {
      border-top: 1px solid #9f1353;
      margin: 20px 0 30px;
    }

    .shipping-title {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      svg {
        margin-right: 8px;
        transform: translateY(4px);
      }

      p {
        font: normal normal normal 15px/25px "Utile Regular";
        margin-bottom: 0px;
      }
    }

    .calendar-details {
      display: flex;
      position: relative;
      justify-content: space-between;
      .info {
        min-width: 155px;
        ul {
          list-style: none;
          padding-left: 10px;

          li {
            font: normal normal normal 13px/15px "Utile Regular";
            margin-bottom: 4px;
            padding-right: 10px;

            .burgundy {
              color: #9f1353;
            }
            &:before {
              content: "·";
              margin-right: 1px;
              font-size: 32px;
              vertical-align: bottom;
              line-height: 18px;
            }
          }
        }
      }

      .calendar {
        margin-top: 5px;
        .calendar-toggle {
          position: absolute;
          width: 215px;
          height: 52px;
          top: 0;
          right: 0;
          z-index: 2;
          cursor: pointer;
          margin-top: 5px;

          &:after {
            position: absolute;
            content: "";
            right: 4px;
            top: 0;
            height: 52px;
            width: 42px;
            background-image: url("~images/app/products/calendar.svg");
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .calendar-overlay-close {
          transition: all 0.5s ease-in-out;
          opacity: 0;
          visibility: hidden;
          position: fixed;
          height: 100vh;
          width: 100vw;
          background-color: rgba(0, 0, 0, 0.246);
          z-index: 2000;
          left: 0;
          top: 0;
        }

        .calendar-body {
          .calendar-info {
            width: 215px;
            height: 52px;
            background-color: white;
            border: 1px solid black;
            text-align: center;
            cursor: pointer;
            padding-right: 44px;
            font: normal normal normal 16px/25px "Utile Regular";
          }

          .calendar-popup {
            position: fixed;
            transition: all 0.5s ease-in-out;
            z-index: 2001;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid #0000006b;
            background-color: white;
            padding: 20px;
            display: flex;
            flex-direction: column;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;

            .cur-year {
              color: black;
            }

            #calendar-close {
              position: absolute;
              top: 0px;
              right: 10px;
              font-size: 27px;
              transform: scaleX(1.3);
              font-family: "Utile Display Light";
              cursor: pointer;
              z-index: 5;
            }

            .calendar-texts {
              text-align: center;
              width: 80%;
              margin: auto;
              border-bottom: 2px solid maroon;
              padding-bottom: 17px;

              h2 {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                width: 134px;
                height: 22px;
                background-color: #9f1353;
                color: #fff;
                font: normal normal normal 14px/15px "Utile Regular";
                margin: 4px auto 30px;
              }

              p {
                font: normal normal 700 18px/22px "Utile Display Light";
                margin-bottom: 0px;

                &:last-of-type {
                  margin-bottom: 5px;
                }
              }
            }

            .calendar-legend {
              display: flex;
              justify-content: space-evenly;
              font: normal normal normal 13px/12px "Utile Book";
              order: 3;
              margin-bottom: 22px;

              .receive:before,
              .return:before,
              .unavailable:before {
                content: "";
                height: 30px;
                width: 30px;
                border: 3px solid #9f1353;
                border-radius: 100%;
                display: inline-block;
                vertical-align: middle;
                margin-right: 4px;
              }

              .return:before {
                border-color: black;
              }

              .unavailable:before {
                border-color: #dddddd;
                background-color: #dddddd;
              }
            }

            .calendar-legend-weekdays {
              order: 4;
              font-family: 'Utile Display Book';
              font-size: 17px;
            }

            @media (max-width: 369px) {
              width: 99vw;
              padding: 20px 5px;
            }

            @media (max-height: 500px) {
              padding: 0px 20px;
              .calendar-texts {
                display: none;
              }
            }
          }

          .flatpickr-calendar {
            display: block;
            order: 2;
            border-radius: 0;
            margin-bottom: 30px;

            .flatpickr-months {
              svg {
                width: 16px;
                height: 16px;
              }

              .flatpickr-prev-month {
                left: 40px;
              }
              .flatpickr-next-month {
                right: 40px;
              }
            }

            .flatpickr-month {
              margin-bottom: 14px;
            }

            .flatpickr-day.prevMonthDay,
            .flatpickr-day.nextMonthDay {
              color: rgba(0, 0, 0, 0.15);
              cursor: pointer;
            }

            .dayContainer {
              justify-content: flex-start;
              margin-top: 2px;
            }

            .flatpickr-current-month {
              font-family: "Utile Display Book";
              font-size: 18px;
              padding-top: 12px;

              & > * {
                font-weight: 600;
              }
            }

            .flatpickr-day.inRange {
              box-shadow: none;
              background: rgba(159, 19, 82, 0.27);
            }

            .flatpickr-weekday {
              color: black;
              font-weight: 1000;
            }

            .flatpickr-weekday:nth-of-type(1),
            .flatpickr-weekday:nth-of-type(7) {
              color: gray;
              font-weight: 500;
            }

            .startRage,
            .endRange,
            .selected {
              color: #404848;
              position: relative;

              &:after {
                content: "";
                height: 30px;
                width: 30px;
                position: absolute;
                top: 50%;
                left: 50%;
                border: 3px solid #9f1353;
                border-radius: 100%;
                transform: translate(-51%, -50%);
              }
            }

            .endRange {
              &:after {
                border: 3px solid black;
              }
            }

            .flatpickr-disabled {
              position: relative;

              &:after {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-51%, -50%);
                border-radius: 100%;
                height: 30px;
                width: 30px;
                content: "";
                z-index: -1;
                background-color: #dddddd;
              }

              color: rgba(0, 0, 0, 0.75);
            }

            span.flatpickr-day {
              border: 1px solid rgba(0, 0, 0, 0.4);
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: "Utile Regular";
              font-size: 15px;
            }

            .today {
              border: 1px solid #00000024;
              border: 1px solid rgba(0, 0, 0, 0.4);
            }
          }
        }

        .calendar-footer {
          .calendar-buttons {
            position: absolute;
            bottom: -90px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            width: 100%;
            justify-content: center;
          }

          div button {
            width: 100%;
            max-width: 404px;
            height: 58px;
            font: normal normal normal 22px/25px "Utile Regular";

            &:hover {
              color: white;
              background: #9f1353;
              border: none;
            }

            &:focus {
              box-shadow: none;
            }
          }

          .btn-danger {
            flex-basis: 25%;
          }
        }
      }
    }
    @media (max-width: 1210px) {
      .description {
        padding-right: 0px;
      }

      .calendar-details {
        flex-direction: column;

        .calendar .calendar-toggle {
          top: auto;
          right: auto;
          bottom: 0;
          left: 0;
        }
      }
    }

    @media (max-width: 1100px) {
      // padding-right: 20px;
      .title-review h3 {
        flex-basis: 60%;
      }
    }

    .calendar-body {
      text-align: center;
    }

    @media (max-width: 900px) {
      // padding: 0;
      width: 87.5vw;

      .nav {
        margin-bottom: 11px;
      }

      .title-review h3 {
        flex-basis: 50%;
      }

      .plan-edit {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        h2,
        a {
          width: 127px;
          font-size: 13.5px;
        }

        a {
          margin-top: 0;
        }
      }

      .view-specifications {
        font-family: "Utile Medium";
      }

      hr {
        margin-bottom: 25px;
      }

      .shipping-title p {
        font-family: "Utile Medium";
      }

      .calendar-details .calendar {
        .calendar-toggle {
          left: 50%;
          transform: translateX(-50%);
        }
        .calendar-footer {
          .calendar-buttons {
            width: 266px;
          }

          div button {
            height: 42px;
            font-size: 15px;
            bottom: -71px;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
}

.component-heading {
  text-align: center;
  font: normal normal normal 24px/28px "Utile Display Book";
  margin: 0 40px 11px;

  @media (max-width: 600px) {
    font: normal normal normal 17px/19px "Utile Display Book";
    margin: 0 20px 3px;
  }
}

.component-heading + hr {
  border-top-color: #9f1353;
  margin-top: 0;
  margin-bottom: 45px;
}

.carousels {
  .owl-carousel {
    margin-bottom: 76px;

    .product-thumb {
      margin-bottom: 0px;
    }
    .carousel-image {
      margin-bottom: 27px;
      position: relative;

      .favorite-action {
        position: absolute;
        top: auto;
        right: 10px;
        bottom: -22px;
        line-height: 16px;

        a:hover {
          color: #9f1353;
        }

        a[data-method="delete"] {
          color: #9f1353;
        }

        svg {
          width: 18px;
          height: auto;
        }
      }
    }
    .item-details {
      padding-left: 18%;
      .title {
        font: normal normal normal 14px/16px "Utile Medium";
        display: block;
        margin-bottom: 5px;
      }
      p {
        font: normal normal normal 14px/16px "Utile Book";
        margin-bottom: 0px;
      }
    }
  }

  @media (max-width: 991px) {
    .owl-carousel {
      .carousel-image {
        .favorite-action {
          right: 15%;
        }
      }

      .item-details {
        padding-left: 0;
        margin: 0 20%;
        text-align: center;
      }
    }
  }

  @media (max-width: 600px) {
    .owl-carousel {
      margin-bottom: 35px;
      .item-details {
        margin: 0 8%;

        .title {
          font: normal normal normal 12px/16px "Utile Medium";
          margin-bottom: 0px;
        }

        p {
          font: normal normal normal 12px/15px "Utile Book";
        }
      }
      .carousel-image {
        .favorite-action {
          svg {
            width: 13px;
          }
        }
      }
    }
  }

  @media (max-width: 450px) {
    .owl-carousel {
      margin-bottom: 20px;
    }

    .carousel-image .favorite-action svg {
      width: 10px;
    }
  }
}

.reviews-container {
  color: black;
  padding: 35px 6%;
  position: relative;

  #reviews-anchor {
    visibility: hidden;
    opacity: 0;
    height: 1px;
    width: 1px;
    position: absolute;
    top: -160px;
  }

  .review-main-text {
    font: normal normal normal 25px/29px "Utile Regular";
    margin-bottom: 11px;
  }

  .rating {
    margin-bottom: 32px;
    svg {
      width: 15px;
      margin-right: 1px;
      g {
        stroke: black;
      }
    }

    a {
      font: normal normal normal 14px/17px "Utile Regular";
      color: black;
      cursor: none;
      pointer-events: none;
      position: relative;
      top: 2px;
      left: 7px;
    }

    .full-star svg g {
      fill: black;
      stroke: black;
    }
  }

  .review-main-text + hr {
    border-top-color: #707070;
    margin: 0;
    margin-bottom: 22px;
  }

  .reviews-carousel-two {
    margin-bottom: 26px;
    .review-thumb {
      .review-header {
        .username {
          text-transform: capitalize;
          font: normal normal normal 14px/14px "Utile Regular";
          margin-bottom: 4px;
          color: black;
        }
        .rating {
          position: static;
          line-height: 11px;
          margin-bottom: 12px;
          svg {
            width: 11px;
            margin-right: -1px;
          }
        }
      }
      hr {
        border-top-color: #707070;
        margin-bottom: 30px;
      }

      .review-content p {
        font: normal normal normal 13px/15px "Utile Book";
        color: black;
        margin-bottom: 30px;
      }
    }

    .slick-slide {
      div:last-of-type {
        .item .review-thumb hr {
          display: none;
        }
      }
    }
  }

  .paginate {
    display: flex;
    justify-content: center;
    align-items: center;

    .page-numbers {
      margin: 1px 2px 0px;
      letter-spacing: 1px;
      font: normal normal normal 14px/18px "Utile Book";

      &:first-letter {
        font-weight: 900;
      }
    }

    .next-arrow,
    .prev-arrow {
      width: 16px;
      height: 16px;
      background-image: url("~images/app/chevron.webp");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .prev-arrow {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 550px) {
    padding: 17px 5vw;

    .review-main-text {
      font: normal normal normal 18px/24px "Utile Display Book";
      margin-bottom: 4px;
    }

    .review-main-text + hr {
      margin-bottom: 7px;
      margin-right: 0px;
    }

    .rating {
      margin-bottom: 26px;
      svg {
        width: 12px;
        margin-right: 0px;
      }

      a {
        font: normal normal normal 12px/25px "Utile Regular";
        top: 1px;
        left: 6px;
      }
    }

    .reviews-carousel-two {
      .review-thumb {
        .review-header {
          .username {
            margin-bottom: 6px;
          }

          .rating {
            svg {
              width: 12px;
              margin-right: 0px;
            }
          }
        }

        .review-content {
          p {
            margin-bottom: 10px;
          }
        }

        hr {
          margin-top: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}

#subscription {
  .rbtd-title {
    h2 {
      background: #c36300;
    }
  }
  .per-day {
    color: #c36300;
    span {
      font: normal normal normal 20px/22px Utile;
    }
  }
  hr {
    background: #c36300;
  }
  .reviews {
    color: #c36300;
    .rating {
      svg {
        g {
          stroke: #c36300;
        }
      }

      .full-star svg g {
        fill: #c36300;
        stroke: #c36300;
      }
    }
  }
  hr {
    border-color: #c36300;
  }
  .burgundy {
    color: #c36300;
  }
  .btn-primary {
    position: absolute;
    bottom: -90px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 404px;
    height: 58px;
    font: normal normal normal 22px/25px Utile;
    padding-top: 0.5em;
    &:hover {
      background: #c36300;
      color: white;
      border: none;
    }
    &:focus {
      box-shadow: none;
    }
    @media (max-width: 900px) {
      font: normal normal normal 18px/21px Utile;
      width: 260px;
      height: 42px;
      padding-top: 0.5em;
    }
  }
  .remove-frm-cart-btn {
    background: #c36300;
    border: none;
  }
}

#item-rbtd {
  .favorite-action {
    svg {
      color: #9f1353;
    }
  }
}

#item-subscription {
  .favorite-action {
    svg {
      color: #c36300;
    }
  }
}
