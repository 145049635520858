.wrapper-header {
  width: 100%;
  .header {
    height: 375px;
    width: 100%;
    background-image: url('~images/app/trust/Safety.webp');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    position: relative;
    @media (max-width: 900px) {
      background-image: url('~images/app/trust/safetyMobile.webp');
      flex-direction: column;
      height: 375px;
      background-size: cover;
      background-position: bottom;
    }
    @media (max-width: 600px) {
      height: 454px;
      background-image: url('~images/app/trust/safetyMobile.webp');
    }

    .header-title-box {
      position: absolute;
      top: 122px;
      bottom: 122;
      left: 0px;
      width: 510px;
      height: 130px;
      opacity: 0.86;
      background: #f5f4f0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      @media (max-width: 600px) {
        background-color: #fcf8f0;
        opacity: 0.93;
        top: 308px;
        left: 0px;
        width: 329px;
        height: 98px;
        margin: 0 !important;
      }
      h1 {
        font-family: 'Utile Display Light';
        font-size: 34px;
        @media (max-width: 600px) {
          font-size: 27px;
          margin: 0 !important;
        }
      }
    }
  }
}

.container-paragraph {
  display: flex;
  flex-direction: column;
  width: 100%;
  h3 {
    font: normal normal normal 31px/43px 'Utile Display Light';
    @media (max-width: 900px) {
      font-size: 22px;
    }
  }

  p {
    font: normal normal normal 16px/27px 'Utile Book';
    @media (max-width: 900px) {
      font-size: 15px;
    }
  }

  .paragraph-one {
    background: #f5f4f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    width: 100%;
    @media (max-width: 900px) {
      flex-direction: column;
      height: 480px;
    }
    .element-one {
      transform: translateX(40px);
      width: 406px;
      height: 376px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 900px) {
        transform: translateX(0px);
      }
      @media (max-width: 700px) {
        height: 296px;
        transform: translateX(0px);
        img {
          padding-top: 40px;
        }
      }
    }
    .element-two {
      .container {
        width: 749px;
        height: 390px;
        box-shadow: 1px 3px 6px #0f080829;
        background-color: #ffffff;
        transform: translateY(40px);
        @media (max-width: 900px) {
          width: 536px;
          height: 296px;
        }
        @media (max-width: 700px) {
          width: 323px;
          height: 296px;
        }
        div {
          max-width: 495px;
          margin: 0px 101px 150px 130px;
          padding-top: 50px;
          @media (max-width: 1150px) {
            margin: 0px 50px 150px 50px;
          }
          @media (max-width: 900px) {
            padding: 0px;
          }
          @media (max-width: 700px) {
            margin: 0px;
            padding: 0px;
          }
          h3 {
            padding-bottom: 15px;
            border-bottom: 1px solid;
            @media (max-width: 900px) {
              text-align: center;
              padding-top: 15px;
            }
            @media (max-width: 700px) {
              padding: 0px;
              padding-top: 15px;
            }
          }
          p {
            padding-top: 15px;

            @media (max-width: 700px) {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .paragraph-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    width: 100%;
    @media (max-width: 900px) {
      flex-direction: column;
      height: 500px;
    }
    .element-one {
      transform: translateX(40px);
      width: 406px;
      height: 376px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 900px) {
        transform: translateX(0px) translateY(0px);
      }
      @media (max-width: 700px) {
        transform: translateX(0px) translateY(30px);
        width: 100%;
        height: 70px;
      }
    }

    .element-two {
      div {
        max-width: 495px;
        margin: 125px 101px 150px 125px;
        padding-top: 50px;
        @media (max-width: 1150px) {
          margin: 125px 50px 150px 50px;
        }
        @media (max-width: 900px) {
          margin: 0px;
          padding: 0px;
          transform: translateX(0px) translateY(-70px);
        }
        @media (max-width: 700px) {
          margin: 0px;
          padding: 0px;
          width: 298px;
          transform: translateY(-40px);
        }
        h3 {
          padding-bottom: 15px;
          border-bottom: 1px solid;
          @media (max-width: 900px) {
            text-align: center;
          }
          @media (max-width: 700px) {
            text-align: center;
            padding: 0px;
          }
        }
        p {
          padding-top: 15px;
          @media (max-width: 700px) {
            margin: 0px;
            padding: 0px;
          }
        }
      }
    }
  }

  .paragraph-three {
    background: #f5f4f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    width: 100%;
    position: relative;
    @media (max-width: 900px) {
      flex-direction: column;
      height: 600px;
    }
    @media (max-width: 700px) {
      flex-direction: column;
      height: 650px;
    }
    .element-one {
      position: relative;

      .image-container {
        width: 406px;
        height: 376px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid black;
        background: #f5f4f0;
        z-index: 3;
        position: absolute;
        bottom: -149px;
        left: 40px;

        &:before {
          position: absolute;
          left: 1;
          top: 0px;
          content: '';
          height: 26px;
          background-color: white;
          width: calc(100% - 1px);
          margin: 0 auto;
        }

        @media (max-width: 980px) {
          width: 323px;
          height: 323px;
          transform: translateY(-53px);
        }
        @media (max-width: 900px) {
          background: none;
          position: static;
          transform: translateY(90px);

          &:before {
            display: none;
          }
        }
        @media (max-width: 700px) {
          transform: translateY(-45px);
        }
      }
    }

    .element-two {
      div {
        max-width: 495px;
        margin: 125px 101px 150px 525px;
        padding-top: 50px;
        @media (max-width: 1150px) {
          margin: 125px 50px 150px 450px;
        }

        @media (max-width: 980px) {
          margin: 75px 50px 150px 450px;
        }

        @media (max-width: 900px) {
          transform: translateY(-30px);
          margin: 125px 50px 150px;
        }
        @media (max-width: 700px) {
          margin: 0px;
          width: 298px;
          transform: translateY(-30px);
        }
        h3 {
          padding-bottom: 15px;
          position: relative;

          &:after {
            position: absolute;
            left: -450px;
            bottom: -1px;
            content: '';
            border-bottom: 1px solid;
            width: calc(100% + 450px);
            margin: 0 auto;
            @media (max-width: 1150px) {
              left: -250px;
              width: calc(100% + 250px);
            }

            @media (max-width: 900px) {
              left: 0px;
              width: 100%;
            }
          }

          @media (max-width: 900px) {
            text-align: center;
          }
          @media (max-width: 700px) {
            text-align: center;
            padding: 0px;
          }
        }
        p {
          padding-top: 15px;
          @media (max-width: 700px) {
            margin: 0px;
            padding: 0px;
          }
        }
      }
    }
  }

  .paragraph-four {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    width: 100%;
    @media (max-width: 900px) {
      flex-direction: column;
      height: 500px;
    }
    @media (max-width: 700px) {
      flex-direction: column;
      height: 480px;
    }
    .element-one {
      transform: translateX(40px);
      width: 406px;
      height: 376px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 900px) {
        transform: translateY(110px);
      }
      @media (max-width: 700px) {
        transform: translateX(0px);
        transform: translateY(-30px);
      }
    }
    .element-two {
      div {
        max-width: 495px;
        margin: 125px 101px 150px 125px;
        @media (max-width: 1150px) {
          margin: 125px 50px 150px 50px;
        }
        @media (max-width: 900px) {
          transform: translateY(40px);
        }
        @media (max-width: 700px) {
          margin: 0px;
          width: 298px;
          transform: translateY(-40px);
        }
        h3 {
          padding-bottom: 15px;
          border-bottom: 1px solid;
          @media (max-width: 900px) {
            text-align: center;
          }
          @media (max-width: 700px) {
            text-align: center;
            padding: 0px;
          }
        }
        p {
          padding-top: 15px;
          @media (max-width: 700px) {
            margin: 0px;
            padding: 0px;
          }
        }
      }
    }
  }

  .paragraph-five {
    background-color: #f5f4f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    width: 100%;
    @media (max-width: 900px) {
      flex-direction: column;
      height: 500px;
    }
    .element-one {
      transform: translateX(40px);
      width: 406px;
      height: 376px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 900px) {
        transform: translateX(0px) translateY(10px);
      }
    }
    .element-two {
      .container {
        width: 749px;
        height: 390px;
        box-shadow: 1px 3px 6px #0f080829;
        background-color: #ffffff;
        transform: translateY(-40px);
        @media (max-width: 900px) {
          width: 536px;
          height: 218px;
          transform: translateY(40px);
        }
        @media (max-width: 700px) {
          width: 323px;
          height: 218px;
        }
        div {
          max-width: 495px;
          margin: 0px 101px 150px 130px;
          padding-top: 50px;
          @media (max-width: 1150px) {
            margin: 0px 50px 150px 50px;
          }
          @media (max-width: 900px) {
            padding: 0px;
          }
          @media (max-width: 700px) {
            margin: 0px;
            padding: 0px;
          }
          h3 {
            padding-top: 80px;
            padding-bottom: 15px;
            border-bottom: 1px solid;
            @media (max-width: 900px) {
              text-align: center;
              padding-top: 15px;
            }
            @media (max-width: 700px) {
              padding: 0px;
              padding-top: 15px;
            }
          }
          p {
            padding-top: 15px;
            @media (max-width: 900px) {
              margin: 0px;
            }
            @media (max-width: 700px) {
              padding: 0;
            }
          }
        }
      }
    }
  }
  .spacer {
    background-color: #ffffff;
    height: 25px;
    @media (max-width: 900px) {
      height: 100px;
      display: flex;
    }
  }
}
