.modal {
  .modal-header {
    border: 0;
    button {
      @include font-size(13px);
      &:hover {
        color: #ffc000;
      }
    }
  }

}

#modal-vip-discount-code {
  .container {
    width: 615px;
  }
  .modal-section {
    text-align: center;
    box-shadow: 0px 12px 16px 4px rgba(0, 0, 0, 0.15);
    margin: 4rem;
    background-color: #fff;
  }
  
  .color {
    background-color: #f7d4aa;
  }
  
  .logo-click {
    text-decoration: none !important;
  }
  
  .logo {
    font-family: 'Lust';
  }
  
  input#coupon-code-email {
    width: 80%;
  }

  .modal-header {
    position: absolute;
    right: 81px;
    left: auto;
    button {
      color: #000000;
      opacity: 1;
      &:hover {
        color: #000000;
      }
    }
  }
  
  span.logo-spn {
    font-family: 'Lust', cursive;
    background-color: #000;
    color: #fff;
    padding: 5px 40px;
    position: relative;
    top: 17px;
    font-size: 33px;
    font-weight: 600;
    padding-bottom: 23px;
  }
  
  .vip-card-txt.mt-5 {
    font-size: 35px;
    font-family: 'Lust';
    color: #b97932;
    font-weight: 600;
  }
  
  .content {
    font-size: 26px;
    font-family: 'Freight Neo Pro';
    color: #444;
  }
  
  .mail-details {
    font-size: 22px;
    font-weight: 600;
    font-family: 'Freight Neo Pro';
    letter-spacing: 0px;
    word-spacing: 8px;
  }
  
  .get-code-btn {
    border-radius: 0;
    font-size: 15px;
    line-height: 21px;
    color: #fff;
    background-color: #000000;
    padding: 05px 20px;
    font-weight: 600;
    font-family: "freight-neo-pro";
  }
}

@media (max-width: 575px) {
  #modal-vip-discount-code {
    
    align-items: center;
    justify-content: center;
    margin-top: 46%;
    padding: 0 !important;

    .modal-header {
      position: absolute;
      right: 15px;
      left: auto;
    }
    .vip-card-txt {
      font-size: 16px;
    }
    .mail-details{
      font-size: 14px;
    }
    .container, .modal-section {
      width: 100% !important;
      margin: 0 auto !important;
    }
    .vip-card-txt.mt-5 {
      font-size: 24px;
    }
    .content {
      font-size: 18px;
    }
    .mail-details {
      font-size: 16px;
    }
    input#coupon-code-email {
      width: 70%;
    }
    .get-code-btn {
      font-size: 14px;
    }
  }
}

@media (max-width: 410px) {
  #modal-vip-discount-code {
    span.logo-spn {
      font-size: 24px;
      top: 15px;
      padding-bottom: 20px;
    }
    .vip-card-txt {
      font-size: 22px;
    }
    .content, .mail-details {
      font-size: 14px;
    }
    .get-code-btn {
      font-size: 12px;
    }
  }
}

#modal-show-vip-discount-code {
  .container {
    width: 615px;
  }

  .modal-section {
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 12px 16px 4px rgba(0, 0, 0, 0.15);
    margin: 4rem;

    .btn-dismiss {
      margin-bottom: 10px;
      border-radius: 10px;
    }
  }
  
  .color {
    background-color: #f7d4aa;
  }
  
  .logo-click {
    text-decoration: none !important;
  }
  
  .logo {
    font-family: 'Lust';
  }
  
  .vip-card-batch {
    width: 40%;
  }
  
  span.logo-spn {
    font-family: 'Lust', cursive;
    background-color: #000;
    color: #fff;
    padding: 5px 40px;
    position: relative;
    top: 17px;
    font-size: 33px;
    font-weight: 600;
    padding-bottom: 23px !important;
  }
  
  .content {
    font-size: 28px;
    font-family: 'Freight Neo Pro';
    color: #444;
  }
  
  #coupon-code-container {
    font-size: 30px;
    font-weight: 600;
  }
  
  .use-this-code-line.mt-4.pb-5 {
    font-size: 20px;
    font-weight: 500;
  }
}
@media (max-width: 575px) {
  #modal-show-vip-discount-code {
    margin-top: 21%;
    padding: 0 !important;
    .container, .modal-section {
      width: 100% !important;
      margin: 0 auto !important;
    }
  }
}

@media (max-width: 410px) {
  #modal-show-vip-discount-code {
    span.logo-spn {
      font-size: 24px;
      top: 10px;
      padding-bottom: 18px !important;
    }
    .vip-card-batch {
      width: 80%;
    }
    .content {
      font-size: 16px;
    }
    #coupon-code-container {
      font-size: 18px;
    }
    .use-this-code-line.mt-4.pb-5 {
      font-size: 12px;
    }
  }
}
 
#vipSubscriptionModal {
  .modal-header {
    button.close {
      color: #000;
      opacity: 1;
      &:hover {
        color: #000;
      }
    }
    padding-right: 0px !important;
    position: absolute;
    z-index: 9;
    right: 10px;
  }
}
