@import './app/application';

.hidden {
  display: none;
}

.bold {
  font-weight: bold;
}

.underlined {
  text-decoration: underline;
}
