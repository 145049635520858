

.fieldset-label {
  background: #EDEDED;
  color: #000;
  font-size: 16px;
  text-align: center;
  padding: 10px;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

table {
  width: 100%;
  font-weight: bold;

  td{
    width: 50%;
    &:last-child {
      text-align: right;
    }

  }

  tr.total {
    padding-top: 10px;

    td {
      padding-top: 5px;
      border-top: 1px solid #000;
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.button-wrapper {
  margin-top: 15px;

  .btn-primary {
    background: #000;
    color: #fff;
    border-color: #000;
  }

  text-align: right;
}

.edit-btn {
  text-decoration: underline;
  margin-bottom: 10px;
}
