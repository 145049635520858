#footer {
  height: 335px;
  background-color: #f4f4f4;
  padding-top: 29px;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;

  h1,
  li,
  p,
  a {
    color: #000000;
  }

  h1 {
    font-family: 'Utile Regular';
    font-size: 13px;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: #707070;
    margin-bottom: 27px;
    letter-spacing: 0.4px;
  }

  p,
  li,
  li a {
    font-family: 'Utile Book';
    font-size: 13px;
    margin-bottom: 12px;
  }

  a:hover {
    color: #ffc000;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    letter-spacing: 0.4px;
  }

  .customers {
    flex: 0 1 31.8%;
    padding-left: 9.25%;

    display: flex;

    #connect-mobile {
      display: none;
    }
  }

  .vendors {
    flex: 0 1 30.7%;

    ul {
      width: max-content;
    }

    #logged-in {
      margin-bottom: 2px;
    }

    #acc-name {
      font-weight: 800;
    }
  }

  .contact {
    flex: 1 1;
    display: flex;
    flex-direction: column;

    .icons {
      img {
        height: auto;
        width: 40px;
        margin-right: 22px;
        margin-bottom: 24px;
      }
    }

    .news {
      p {
        margin-right: 10px;
      }
    }

    #newsletter-heading {
      margin-bottom: 21px;
    }

    .phone-mail-container {
      display: flex;
      margin-bottom: 39px;
      a {
        margin-right: 24px;
        font-family: 'Utile Book';
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
          height: auto;
          width: 20px;
        }
      }

      a:first-of-type {
        svg {
          transform: rotate(90deg);
          width: 16px;
        }
      }
    }
  }
  #newsletter {
    .newsletter-container {
      display: flex;
      width: 71.4%;
      min-width: 220px;
      height: 43px;
      position: relative;

      input {
        text-align: center;
        padding-right: 27px;
        border-radius: 0;
        width: 100%;
        border: 1px solid black;
      }
      button {
        position: absolute;
        right: 1px;
        top: 2px;
        border: none;
        outline: none;
        background-color: white;

        img {
          height: 37px;
          width: auto;
          transform: scale(0.5);
        }

        p {
          color: #ffc000;
          font-weight: 800;
          line-height: 37px;
          margin-bottom: 0px;
          padding-right: 10px;
        }
      }
    }

    @media (max-width: 1100px) {
      .newsletter-container {
        height: 32px;

        input {
          text-align: left;
          padding-left: 10px;
          font-size: 12px;
        }

        button {
          img {
            height: 27px;
          }

          p {
            line-height: 26px;
            padding-right: 0px;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    padding-left: 30px;
    padding-right: 30px;

    h1 {
      margin-bottom: 15px;
    }

    .connect .icons img {
      margin-bottom: 20px;
    }

    .connect .phone-mail-container {
      flex-direction: column;
      margin-bottom: 15px;

      a {
        margin-bottom: 5px;
      }
    }

    .customers {
      padding-left: 0px;
    }
  }

  @media (max-width: 600px) {
    height: 492px;
    flex-direction: column;
    padding: 21px 10%;
    align-content: space-between;

    h1 {
      margin-bottom: 14px;
      font-family: 'Utile Display Book';
      text-underline-offset: 6px;
    }

    li,
    li a {
      margin-bottom: 6px;
      font-family: 'Utile Display light';
    }

    li:last-of-type {
      margin-bottom: 37px;
    }

    .contact {
      #newsletter-heading {
        margin-bottom: 16px;
      }

      #newsletter {
        padding: 0px;

        .newsletter-container {
          height: 41px;
          width: 255px;
          input {
            text-align: center;
            font-size: 15px;
          }

          button {
            img {
              height: 36px;
            }

            p {
              line-height: 34px;
              padding-right: 0px;
              margin-right: 0px;
              font-size: 15px;
              transform: translateX(80px);
            }
          }
        }
      }

      .news p {
        margin-right: 70px;
        margin-bottom: 5px;
      }
    }

    .customers {
      flex: none;
      padding-left: 0px;
      justify-content: space-between;

      #connect-mobile {
        display: block;
        img {
          width: 33px;
          margin-right: 9px;
          margin-bottom: 12px;
        }

        h1 {
          margin-bottom: 19px;
        }

        .phone-mail-container {
          display: flex;
          flex-direction: column;

          a {
            margin-right: 24px;
            font-family: 'Utile Display Light';
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            svg {
              margin-right: 7px;
              height: auto;
              width: 15px;
            }
          }

          a:first-of-type {
            svg {
              transform: rotate(90deg);
              width: 14px;
            }
          }
        }
      }
    }

    .vendors {
      flex: none;
    }

    .contact {
      .connect {
        display: none;
      }
    }
  }

  @media (max-width: 450px) {
    padding: 21px 5%;
  }

  @media (max-width: 360px) {
    .contact .news #newsletter .newsletter-container {
      width: 220px;

      input {
        text-align: left;
        font-size: 13px;
      }
    }
  }
}

#rights {
  position: absolute;
  bottom: -35px;
  padding-left: 9.25vw;
  width: 100%;
  background-color: white;
  font-family: 'Utile Book';
  color: #808080;
  line-height: 27px;

  @media (max-width: 450px) {
    padding-left: 5%;
  }
}
